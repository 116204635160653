import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { MustMatch } from '../helpers/matchPassword.validator';
declare var $:any;
import { FlashMessagesService } from 'angular2-flash-messages';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { ThemeService } from 'ng2-charts';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  setReminderForm: FormGroup;
  setPassForm: FormGroup
  userId:any;
  reminderSuccess: boolean = false;
  passwordSuccess: boolean = false;
  staffPass: string;
  feedBackList = [
    { name: 'Bug' },
    { name: 'Suggestion' },
    { name: 'Other' },
  ];
  feedbackForm: FormGroup;
  errorMessage: string;
  submitted: boolean = false;
  changePassForm: FormGroup;
  changePassFormData: {};
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  type: string;
  adminType: any;
 
  // toppings = new FormControl();

  // toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  constructor(private router:Router, private fb: FormBuilder, private authService: AuthService,private flashMessage: FlashMessagesService,public snackBar: MatSnackBar) {
    this.userId = localStorage.getItem("userId");
    this.staffPass = localStorage.getItem('staffPassword');
    this.setReminderForm = this.fb.group({
      setReminder: ['', Validators.required]
    });
    this.setPassForm = this.fb.group({
      setPassword: ['', Validators.required]
    });
   
   
    this.type = localStorage.getItem('type')
      
    
   }

  ngOnInit() { 
    this.changePassForm= this.fb.group({
      oldPass: ['', Validators.required],
      newPass: ['', [Validators.required, Validators.minLength(6),Validators.pattern("^[a-zA-Z0-9_]*$")]],
      confirmPass: ['', Validators.required]
      }, {
      validator: MustMatch('newPass', 'confirmPass')
    });
    this.feedbackForm = this.fb.group({
      subject:['', Validators.required],
      feedback: ['', Validators.required],
    });
   
  }
  
  get f() { return this.changePassForm.controls; }
// when logout
  async logout(){
    if(confirm("Do you want to logout? ")) {
      await localStorage.clear();
      this.router.navigate(['']);
    }
  }

  setDefaultTime(){
    if(this.setReminderForm.invalid){
      return;
    }
    else{
      let reminderFormDetail = {
        userId: parseInt(this.userId),
        reminderTime: parseInt(this.setReminderForm.value.setReminder)
      }
      console.log("reminder", reminderFormDetail)
      this.authService.setReminder(reminderFormDetail).subscribe(
        (response:any) => {
          console.log("reminder reponse is", response)
          this.reminderSuccess = true;
          setTimeout(() => {
            this.reminderSuccess = false;
          },4000);
        },
        (error) => {
          console.log(" reminder error is",error)
        }
      )
    }
  }
  setPassword(){
    if(this.setPassForm.invalid){
      return;
    }
    else{
      let passFormDetail = {
        userId: parseInt(this.userId),
        password: parseInt(this.setPassForm.value.setPassword)
      }
      console.log("password detail", passFormDetail)
      this.authService.setPassword(passFormDetail).subscribe(
        (response:any) => {
          console.log("password reponse is", response)
          localStorage.setItem('staffPassword', response.data.staffPwd);
          this.passwordSuccess = true;
          setTimeout(() => {
            this.passwordSuccess = false;
          },4000);
        },
        (error) => {
          console.log(" password error is",error)
        }
      )
    }
  }
  // feedback submit
  submitFeedback(){
  this.submitted = true;
    console.log('this.feedbackForm.value' , this.feedbackForm.value);
    let feedbackFormDetail = {
      userId: parseInt(this.userId),
      subject: this.feedbackForm.value.subject,
      feedback:this.feedbackForm.value.feedback
    }
    this.authService.feedback(feedbackFormDetail).subscribe(
      (response:any) => {
        if(response){
          console.log(response);
          this.snackBar.open(response.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          jQuery.noConflict();
          $("#feedback").modal('hide');
        }
      
      
      else{
        this.snackBar.open(response.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
    (error) => {
      console.log('error is ',error.message);
      this.snackBar.open(this.errorMessage,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  
      
  );
  }
    /** function to change/reset password */
    changePassword(){
      this.submitted = true;
        // stop here if form is invalid
      if (this.changePassForm.invalid) {
          return;
      }
     
       // if form is valid
      this.changePassFormData= {
        userId: this.userId,
        password: this.changePassForm.value.oldPass,
        newPassword: this.changePassForm.value.newPass 
      }
      this.authService.resetPassword(this.changePassFormData).subscribe((res:any) => {
        console.log(res);
        if (res.status=='success'){
          this.changePassForm.reset();
          jQuery.noConflict();
          $('#changePassModal').modal('hide');
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
        else{
          jQuery.noConflict();
          $('#changePassModal').modal('hide');
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }); 
        }
      })
      
    }
}
