import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators } from  '@angular/forms';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import * as moment from 'moment';

declare var $:any;

@Component({
  selector: 'app-quick-task',
  templateUrl: './quick-task.component.html',
  styleUrls: ['./quick-task.component.scss']
})
export class QuickTaskComponent implements OnInit {
  @ViewChild('select') selectElRef:any;
  @ViewChild('taskData') taskData: ElementRef;
  @ViewChild('assignto') assignto : ElementRef;

  results: any;
  name: FormControl = new FormControl();
  userId:any;
  empName:any;
  empPhone:any;
  empNameList:boolean=false;
  assetName: any;
  assetsList:any;
  watchersList: any;
  selectedValues: any[] = [];
  watchersCount:number= 0;
  addQuickTaskForm: FormGroup;
  taskFormDetail: {};
  submitted = false;
  notFound:any;
  createdTaskList: any;
  selectedAssets: any;
  partNameLength: number=0;
  partsName: string;
  selectedAssetSerialNumber: any;
  partName: any;
  selectedPart:boolean= false;
  selectedIndex: number;
  assignAsset:boolean= false;
  empStaffId: number;
  errorMessage:string="Some error occured due to server or internet connection!";
  offlineMsg:string = "Sorry, You do not have internet connection...!";
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  staffMemberList : any;
  IsmodelShow: boolean = false;
  startDate:Date;

  constructor(private authService: AuthService, private fb: FormBuilder, private flashMessage:FlashMessagesService, private router:Router,public snackBar: MatSnackBar){
    this.userId = localStorage.getItem("userId");
    this.addQuickTaskForm = this.fb.group({
      quickTaskName : ['', Validators.required],
      dueDate : ['', Validators.required],
      description : ['', Validators.required]
    })
   }

  ngOnInit() {
    this.startDate= new Date();
    if(navigator.onLine){
      this.name.valueChanges.subscribe(name => this.authService.autoSearchEmployee(this.userId,name)
    .subscribe((res:any)=>{
      if(res.status == "success"){
        this.empNameList=true;
        this.results = res.data;
        console.log(this.results);
      }
      else{
        this.notFound = res.message;
        console.log(res.message);
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err)=> {
      console.log(err);
      this.snackBar.open(this.errorMessage,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
    })
    
    );
    } 
    else {
      this.snackBar.open(this.offlineMsg,'', {
        duration: 4000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      }


      this.assignto.nativeElement.addEventListener('click', ()=>{
        console.log(' i Am clicked');
        this.fetchStaffMemberList();
      });
  }
// fetch service engineer
  fetchStaffMemberList(){
    this.authService.listStaff(this.userId).subscribe((res:any) => {
      if(res.status == "success"){
        this.staffMemberList = res.data;
        console.log(this.staffMemberList);
      }else {
        console.log(res.message);
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err)=> {

    })
  }
// fetch service engineer
  staffImg(){
    this.fetchStaffMemberList();
  }
// filter by staff
  filterStaff(e){
    console.log(e.target.value);
    this.authService.autoSearchEmployee(this.userId,e.target.value).subscribe((res:any)=>{
      if(res.status == "success"){
        this.staffMemberList = res.data;
      }else{
        console.log(res.message);
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err)=> {
      console.log(err);
      this.snackBar.open(this.errorMessage,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.addQuickTaskForm.controls; }

  /** to show name in input box */
  addNameAndEmail(info:any){
    this.empName=info.name;
    this.empPhone=info.phone;
    this.empStaffId= parseInt(info.staffId);
    this.empNameList=false;
  }
  showWatchersList(){
    this.watchersCount= 0;
    jQuery.noConflict();
    $('#watchersModal').modal('show');
    this.authService.listStaff(this.userId).subscribe((res:any)=> {
      console.log(res);
      if(res.status == "success"){
        this.watchersList=res.data;
      }
    }, 
    (error)=> {
      console.log(error);
 this.snackBar.open(this.errorMessage,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
    })
  }

  ngAfterViewInit() {
    this.updateSelectList();   //for watchers
   // this.updateSelectedAssetList();  // for asset parts
  }

  /** function to update selected watchers */
  updateSelectList() {
    let options = this.selectElRef.nativeElement.options;
    console.log(options, "from options");
    for(let i=0; i < options.length; i++) {
      options[i].selected = this.selectedValues.indexOf(options[i].value) > -1;
    }
  }


  /** function to select multiple watchers */
  change(options) {
    console.log(options);
    
    this.selectedValues = Array.apply(null,options)  // convert to real Array
      .filter(option => option.selected)
      .map(option => parseInt(option.value))    //to convert string into number for staffId of watchers
      this.watchersCount= this.selectedValues.length;  //count the watchers
      console.log("watchers", this.selectedValues);
  }

  /*  function to create Quick Task     **/
  addQuickTask(){
    this.submitted = true;
    // form validations
    if(this.addQuickTaskForm.invalid){
      this.snackBar.open("Please fill all the mandatory (*) fields!",'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please fill all the mandatory (*) fields!", { cssClass: 'alert-danger', timeout: 3000 });
      return;
    }
    if(this.empPhone == null){
      this.snackBar.open("Please assign task to some employee!",'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please assign task to some employee!", { cssClass: 'alert-danger', timeout: 2000 });
      return;
    }

    // creating Object
    this.taskFormDetail = {
      userId: this.userId,
      taskName : this.addQuickTaskForm.value.quickTaskName,
      description : this.addQuickTaskForm.value.description,
      assignee : this.empPhone,
      dueDate : moment(this.addQuickTaskForm.value.dueDate).format('YYYY-MM-DD'),
      watchers:this.selectedValues
    }
    console.log("details", this.taskFormDetail);
        this.authService.createQuickTask(this.taskFormDetail).subscribe((res:any) => {
          console.log(res);
         if(res.status == "success"){
          // $("#taskModal").modal('hide');
          this.addQuickTaskForm.disable();
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          setTimeout( ()=>{
            this.router.navigate(['quickTaskList']);
            this.addQuickTaskForm.reset();
          },2500)
         }
         else {
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
         }
          
        }, (error) => {
          console.log('error is ',error.message);
          this.snackBar.open(this.errorMessage,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        })

  }

  close() {
    this.router.navigate(['quickTaskList']);
    // this.IsmodelShow=true;
    // set false while you need open your model popup
   // do your more code
}
}
