import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
// sort machine by machine name
  transform(array: Array<string>, args: string): Array<string> {
    return array.sort((a: any, b: any) => {
      if (a.machineModel < b.machineModel) {
        return -1;
      } else if (a.machineModel > b.machineModel) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
