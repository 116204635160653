import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {
  authToken:string=  localStorage.getItem("authToken");
  base_url: string = environment.api_base_url;
 
  constructor(private httpClient: HttpClient) { }


  getCurrentMonthEvent(data){
    return this.httpClient.post<any>(this.base_url+'taskListWithinDate',data, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
}
