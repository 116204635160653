import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-distributor-dashboard',
  templateUrl: './distributor-dashboard.component.html',
  styleUrls: ['./distributor-dashboard.component.scss']
})
export class DistributorDashboardComponent implements OnInit {
  token: string;
  userId: any;
  name: string;
  email: string;
  image: string;
  vendorData: {};
  phone: string;
  companyName: string;
  type: string;
  authToken: string;

  constructor(private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    // get data from query params
    this.route.queryParamMap.subscribe(queryParams => {
      this.token = queryParams.get("userToken");
      this.userId = queryParams.get("userId");
      this.name = queryParams.get("name");
      this.email = queryParams.get("email");
      this.image = queryParams.get("image");
      this.phone = queryParams.get("phone");
      this.companyName= queryParams.get("companyName");
      this.authToken = queryParams.get("authToken");
      
    });
    this.vendorData={ name:this.name,email:this.email,userId:this.userId,image:this.image,phone:this.phone,companyName:this.companyName
      
    }
    localStorage.setItem("vendorData",JSON.stringify(this.vendorData));
    localStorage.setItem("userToken",this.token);
    localStorage.setItem("userId", this.userId);
    localStorage.setItem("name",  this.name);
    localStorage.setItem("authToken", this.authToken);
    localStorage.setItem('type', 'admin')
    
   console.log('this.token' , this.token);
   console.log('this.userId' , this.userId);
  
   setTimeout(() => {this.router.navigate(['dashboard'])},2000)
  // setTimeout(()=>this.router.navigate(['/login']),3000);
   
  }

}
