import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
export interface DialogData {
  
}

@Component({
  selector: 'app-printgraph',
  templateUrl: './printgraph.component.html',
  styleUrls: ['./printgraph.component.scss']
})
export class PrintgraphComponent implements OnInit {
  accountChart:boolean= false;
  machinesChart:boolean = false;
  dashboardData: any;
  completeTask: number = 0;
  incompleteTask: number = 0;
  activeAsset: number = 0;
  blockAsset: number = 0;
  totalAsset: number = 0;
  totalStaff: number = 0;
  totalTask: number = 0;
  staffTaskCount: any;
  staffName: any;
  assetName: any;
  assetTaskCount: any;
  freeStaffMember: number = 0;
  workingStaffMember: number = 0;
  barChartData: ChartDataSets[];
  barChartLabels: Label[];
  barChartType: string;
  barChartLegend: boolean;
  barChartPlugins: [];
  barChartOptions: { responsive: boolean, legend: any };
  barChartData2: ChartDataSets[];
  barChartLabels2: Label[];
  barChartType2: ChartType;
  barChartLegend2: boolean;
  barChartPlugins2: [];
  barChartOptions2: ChartOptions;
  chartReady: boolean = false;
  chartColors: any[];
  pendingTask: string;
  authToken: string;
  barChartData1: { data: number[]; label: string; stack: string }[];
  barcolorsColor1: { backgroundColor: string; }[];
  barChartColors: { backgroundColor: string; }[];
  barChartColors1: { backgroundColor: string; }[];
  barChartLegend1: boolean;
  barChartType1: string;
  barChartLabels1: any;
  dashboardCustomerData: any;
  dashboardServiceData: any;
  Linechart: any;
  lineChartData: { data: number[]; label: string; }[];
  lineChartLabels: string[];
  lineChartOptions: { responsive: boolean,legend: any };
  lineChartLegend: boolean;
  lineChartType: string;
  lineChartPlugins: any[];
  lineChartColors: { borderColor: string; backgroundColor: string; }[];
  userId: any;
  chartData: { data: number[]; label: string; fill: boolean; }[];
  chartLabels: string[];
  chartOptions: { scales: { yAxes: { ticks: { beginAtZero: boolean; stepSize: number; }; }[]; }; annotation: { drawTime: string; annotations: { type: string; id: string; yScaleID: string; yMin: number; yMax: number; backgroundColor: string; }[]; }; };

 
  // value: Date = new Date();
  dateValue: Date = new Date;
  start: Date = new Date;
  end: Date = new Date;
  noOfDates: number;
  lineChartData1: { data: number[]; label: string; }[];
  accountName: any;
  assetId: any;
  customerAssetId: any;
  customerAccountName: any;
  filterData: {};
  serviceEngineerList: any;
  machineName: any;
  trendData: any;
  trend1: any;
  trend2: any;
  splineDate: any;
  topup: any;
  topup4: any;
  showDiv: boolean = false;
  errorMessage: string;
  assetsList: any;
  machineList: [];
  machineUniqueCode: any;
  oilChart: boolean = false;
  lineChartData2: { data: number[]; label: string; }[];
  splineChartData: any;
  from: any;
  to: any;
  filteredData: { userId: any; from: Date; to: Date; assetId: any; uniqueCode: any; type: string; };
  type: 'bydate'
  barChartReady: boolean = false;
  message: any;
  buttonShow: boolean = false;
  splineCsvData: any;
  chartShow: boolean = false;
  status: any;
  machinefirstPartList: any;
  setCsvData: { date: any; accountName: any; machine_name: any; machine_unique_Code: any; concentration_before: any; concentration_after: any; oil_top_up_quantity: any; ph_before_top_up: any; tramp_oil: any; water_top_up_quantity: any; };
  finalcsvData: any = [];
  finalcsvByData: any = [];
  csvButtonShow: boolean = false;
  barChartOptions1: { responsive: boolean, legend: any };
  barChartPlugins1: [];
  barChartLabelsName: any;

  start1: any;
  end1: any;
  lineChartphColors: { borderColor: string; backgroundColor: string; }[];
  showDate: boolean = false;
  barChartColors3: { backgroundColor: string; }[];
  
  splineOilQuantity: any;
  splineChartDate:any;
  splineChartConcentrationAfter:any;
  splineChartphValue:any;
  oilBarChartValue:any;
  oilBarQuantity:any;
  printButton: boolean = true;
  solcareAccount: any;
  startDatevalue: any;
  endDatevalue: any;
  modelName: any;
  code: any;
  constructor(public dialogRef: MatDialogRef<PrintgraphComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('data' , data);
    if(data){
     if(data.type == 'multiple'){
       this.machinesChart = true;
      this.splineChartDate= data.date;
      this.splineChartConcentrationAfter = data.concentrationAfter;
      this.splineChartphValue= data.phValue;
      this.splineOilQuantity = data.oilChartValue;
      this.solcareAccount = data.account;
      this.startDatevalue = data.startDate;
      this.endDatevalue = data.endDate;
      this.modelName=data.modelName,
      this.code= data.code

     }
     if(data.type == 'single'){
      this.accountChart = true;
      this.oilBarChartValue = data.machineNameChart;
      console.log(this.oilBarChartValue)
      this.oilBarQuantity = data.oilValue;
      console.log(this.oilBarQuantity)
      this.solcareAccount = data.account;
      this.startDatevalue = data.startDate;
      this.endDatevalue = data.endDate;
      this.modelName=data.modelName

     }
     
    }
   
   }

  ngOnInit() {
    this.splineChart();
    this.oilChartData();
  }
  splineChart() {
   
    this.lineChartData = [
      { data: this.splineChartConcentrationAfter, label: 'Concentration After (%)' },
      // { data: this.splineChartData.phValue, label: 'Ph Value' },
    ];
    this.lineChartLabels =this.splineChartDate;
    this.lineChartOptions = {
      responsive: true,
    
    
      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }
    };
    this.lineChartColors = [
      {
        borderColor: 'pink',
        backgroundColor: 'transparent',
      },
      // {
      //   borderColor: 'blue',
      //   backgroundColor: 'transparent',
      // },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [];
    //ph Value
    this.lineChartData2 = [
      // { data: this.splineChartData.constrationAfter, label: 'Concentration After (%)' },
      { data: this.splineChartphValue, label: 'pH Value' },
    ];
    this.lineChartLabels = this.splineChartDate;
    this.lineChartOptions = {
      responsive: true,
    
   
      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }

    };
    this.lineChartphColors = [

      {
        borderColor: 'blue',
        backgroundColor: 'transparent',
      },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [];
    //second chart
   
    this.lineChartData1 = [
      { data: this.splineOilQuantity, label: 'Total Oil Consumption (L)' },
      // { data: this.topup4, label: 'Water Top Up' },
    ];
    this.lineChartLabels = this.splineChartDate;
    this.lineChartOptions = {
      responsive: true,
    
     
      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }
    };
    this.lineChartColors = [
      {
        borderColor: 'pink',
        backgroundColor: 'transparent',
      },
      {
        borderColor: 'blue',
        backgroundColor: 'transparent',
      },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [];
  }

 
  oilChartData() {

    this.barChartOptions = {

      responsive: true,
     
    
      legend: {
        position: 'top',

        labels: {
         
          usePointStyle: true,

        }
      }
    };

    //  let barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    this.barChartLabels1 =this.oilBarChartValue;
    console.log('#######' , this.barChartLabels1);
    
    this.barChartType2 = 'bar';
    this.barChartLegend2 = true;
    this.barChartPlugins = [];
    this.barChartData2 = [
      { data: this.oilBarQuantity, label: 'Total Oil Consumptions (L)' },

    ];
  console.log('@@@@@@@@',this.barChartData2);
  


    this.barChartColors3 = [
      // { backgroundColor: '#8AFFB9' },
      { backgroundColor: '#178FBD' },
    ]
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  printGraph(){
    this.printButton = false;
    setTimeout(() =>{  window.print()},1000)
  
    setTimeout(() =>{this.printButton = true},1000)
  }
}
