import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AuthService }  from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MytoasterService } from '../service/mytoaster.service';
declare var $:any;
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import{ FileserviceService} from '../service/fileservice.service';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  displayedColumns: string[] = ['name', 'phone', 'organization', 'designation','email', 'rating','showTasks', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

 @ViewChild('staffData') staffData: ElementRef;
 staffDetails: any = {};
 editEmployeeForm: FormGroup;
 userID:any;
 employeeInfo: {};
 empList: any;
 staffInfo: any;
 updatedData: any;
 showList: boolean = false;
 submitted = false;
 empTaskList: any;
 uploadCsvStaff: FormGroup;
 staffUploadData: any;
 staffUploadDataLength: number = 0;
 searchDetail:any;
 errorMessage:string="Some error occured due to server or internet connection!";
 totalstars = 5;
 data: any =[{
  "Employee_mail_Id":'', "Name":'',"Designation":'', "Phone":'',"Organization":'', "Remarks":''
}];
//  for loader
spinner:boolean = false;
  csvValue: {}[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


 constructor(private fb: FormBuilder, private authService: AuthService,private fileService: FileserviceService, private toaster: MytoasterService, public snackBar: MatSnackBar,private flashMessage: FlashMessagesService, public router:Router) { 
  this.userID= localStorage.getItem('userId');

  this.editEmployeeForm=this.fb.group({
    staffId: ['', Validators.required],
    name: ['', Validators.required ],
    organization: ['',Validators.required],
    phone: ['', Validators.required],
    staffType: ['', Validators.required],
    designation: ['', Validators.required],
    email: ['' ,[Validators.required,Validators.email]],
    remarks:['']
  });

  this.uploadCsvStaff = this.fb.group({
    staffCsvData:['']
  });
  this.staffList();
}


ngOnInit() {

  this.staffDetails.staffType = '',
  // Disable form submissions if there are invalid fields
  (function() {
    'use strict';
    window.addEventListener('load', function() {
      // Get the forms we want to add validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  })();
}
// search 
applyFilter(filterValue: string) {
  console.log(filterValue);
  
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

// convenience getter for easy access to form fields
 get f() { return this.editEmployeeForm.controls; }

/** this function is used to save new staff/employee  */
saveEmployee(h:any){
  this.employeeInfo= {
    name: this.staffDetails.userName,
    organization: this.staffDetails.organization,
    phone: this.staffDetails.phone,
    designation: this.staffDetails.designation,
    staffType:this.staffDetails.staffType,
    email: this.staffDetails.email,
    remarks:this.staffDetails.remark,
    userId:parseInt(this.userID)
  }
  console.log("details ", this.employeeInfo);
  this.authService.addEmployee(this.employeeInfo).subscribe((res:any) => {
    if(res.status=="success"){
      h.resetForm();  //to reset the form
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      this.staffList();
    }
    else{
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  },
  (error) => {
    console.log('error is ',error.message);
    this.snackBar.open(this.errorMessage,'', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  })
}

/** function to show staff list */
staffList(){
  console.log(this.userID);
  this.authService.listStaff(this.userID).subscribe((res:any) => {
    console.log("employee list response is",res);
    if(res.status=="success"){
      this.showList= true;
      this.empList= res.data;
      console.log(this.empList);
      this.dataSource= new MatTableDataSource(res.data);
      console.log('this.dataSource' , this.dataSource);
      
      this.dataSource.paginator = this.paginator;   //for pagination
      this.dataSource.sort = this.sort;   //for sorting
     // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });
    }
    else if(res.status == 'failed') {   
      this.empList == undefined;
      this.empList = [];
    }
    
    else{
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  },
  (error) => {
    console.log('error is ',error.message);
    this.snackBar.open(this.errorMessage,'', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  })
}

/** function to prefill the input values in modal */
employeeDetails(list){
  this.editEmployeeForm.setValue({
    staffId: list.staffId,
    name: list.name,
    email: list.email?list.email:null,
    phone: list.phone,
    staffType:list.staffType,
    organization: list.organization?list.organization:null,
    designation: list.designation,
    remarks: list.remarks
  })
}
// validation for phone number
keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;

  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}
/** function to edit an employee details */
editEmployee(){
  this.submitted = true;
  if(this.editEmployeeForm.invalid){
    return;
  }
  this.updatedData= {
    userId: this.userID,
    staffId: this.editEmployeeForm.value.staffId,
    empId: this.editEmployeeForm.value.empId,
    name: this.editEmployeeForm.value.name,
    staffType:this.editEmployeeForm.value.staffType,
    email: this.editEmployeeForm.value.email,
    phone: this.editEmployeeForm.value.phone,
    department: this.editEmployeeForm.value.department,
    designation: this.editEmployeeForm.value.designation,
    remarks:this.editEmployeeForm.value.remarks
  }
  console.log(this.updatedData);
  this.authService.employeeDetailsEdit(this.updatedData).subscribe((res:any) => {
    console.log(res);
    this.editEmployeeForm.reset();
    if(res.status=='success'){
      jQuery.noConflict();
      $("#editEmployeeModal").modal('hide');
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      this.staffList();
    }
    else{
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  },
  (error) => {
    console.log('error is ',error.message);
    this.snackBar.open(this.errorMessage,'', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  })
}

 /** function to delete an employee */
deleteEmployee(list){
  if(confirm("Are you sure you want to delete it ? ")) {
    this.staffInfo = {
      userId: this.userID,
      staffId: list.staffId
    }
    console.log(this.staffInfo);
    this.authService.employeeDelete(this.staffInfo).subscribe((res:any) => {
      console.log(res);
      if(res.status=='success'){
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.staffList();
      }
      else{
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
    (error) => {
      console.log('error is ',error.message);
      this.snackBar.open(this.errorMessage,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }
}

/** function to show task list for particular employee */
showEmployeeTask(staffId:number){
  let empDetail={
    userId: this.userID,
    staffId: staffId
  }
  this.spinner = true;
  console.log(empDetail);
  this.authService.taskListOfEmployee(empDetail).subscribe((res:any) => {
    console.log(res);
    if(res.status=='success'){
      this.empTaskList = res.data;
      this.spinner = false;
    }
    else{
      this.empTaskList= false;
      this.spinner = false;
      this.snackBar.open(res.message,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  },
  (error) => {
    console.log('error is ',error.message);
    this.spinner = false;
    this.snackBar.open(this.errorMessage,'', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  })
}

/** function to upload the assets csv file */
onSubmit() {
  if(!this.staffData.nativeElement.files[0]){
    this.toaster.showToast('Failed!', "Please choose the excel file first!", 'danger');
    return;
  }
  const formData = new FormData();  
  let userId= this.userID;

  console.log("userid is ", userId);
  formData.append('uploadfile', this.staffData.nativeElement.files[0], this.staffData.nativeElement.files[0].name);
  console.log("form data is ", this.staffData.nativeElement.files[0]);
  this.authService.uploadStaffList(formData,userId).subscribe(
    (response:any) => { 
      console.log('UPLOADING assets response ', response);
      if(response.status=="success"){
        this.staffData.nativeElement.value = "";   //to reset the file name
        this.staffUploadData= response.data;
        this.staffUploadDataLength= this.staffUploadData.length;
        console.log(this.staffUploadDataLength);
        
        this.snackBar.open(response.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.staffList();
        if(this.staffUploadDataLength > 0){
          jQuery.noConflict();
          $("#staffLogModal").modal('show');
        }
       
      }
      else{
        this.snackBar.open(response.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
    (error) => {
      console.log('error is ',error.message);
      this.snackBar.open(this.errorMessage,'', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  );
}

 /** function to search the staff */
 search(term:any){
  this.searchDetail= {
    userId: this.userID,
    name: term
  }
  this.authService.searchStaff(this.searchDetail).subscribe((res:any) => {
    if(res.status=="success"){
      this.empList= res.data;
    }
  })
}

csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalseparator: '.',
  showLabels: true,
  title:'',
  useBom: true,
  noDownload: false,
  headers: ["Employee_mail_Id", "Name","Designation", "Phone","Organization", "Remarks"]
};

// download excel file
downloadCSV() {
  this.fileService.exportAsExcelFile(this.data, 'Service Engineer');
  // this.csvValue=[{ }]
  //   new AngularCsv(  this.csvValue, 'ServiceEngineer', this.csvOptions);
 
}
clickToDownload(){
  jQuery.noConflict();
  $("#staffDownloadModal").modal('show');
}
}
