import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResetpasswordComponent } from '../resetpassword/resetpassword.component';
import { environment } from 'src/environments/environment';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  terms_and_condition: boolean = false;
  forgetPassForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  data: any;
  status: any;
  vendorEmail: any;
  resetPassForm: FormGroup;
  userId: string;
  newUser: boolean = false;
  type: string;
  termsUrl: string = environment.termsConditionurl;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private fb: FormBuilder, public router: Router, public snackBar: MatSnackBar, private authService: AuthService, private flashMessage: FlashMessagesService, public dialog: MatDialog) {

  }

  ngOnInit() {
    console.log("login load")
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.forgetPassForm = this.fb.group({
      vendorEmail: ['', [Validators.required, Validators.email]]
    });

    /** jquery for form validation */
    (function () {
      'use strict';
      window.addEventListener('load', function () {
        // Get the forms we want to add validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          }, false);
        });
      }, false);
    })();

  }

  /** This function is used to submit the form */
  submit() {
    if (this.loginForm.invalid) {
      this.snackBar.open("Please fill both fields!", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
      // this.flashMessage.show("Please fill both fields!", { cssClass: 'alert-danger', timeout: 2000 });
      return;
    }
    // this.submitted= true;
    /** this block is used to get input values of form fields */
    this.data = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      deviceType: 'web'
    }
    console.log("data is ", this.data);
    // const asyncLocalStorage = {
    //   setItem: async function (key, value) {
    //       await null;
    //       return localStorage.setItem(key, value);
    //   },
    //   getItem: async function (key) {
    //       await null;
    //       return localStorage.getItem(key);
    //   }
    // }
    /** this block is used to call login function of authService */
    this.authService.login(this.data).subscribe((res: any) => {
      if (res.status == 'success' && res.data.is_new_user == true) {
        console.log('login successfully', res);
        // this.snackBar.open(res.message,'', {
        //   duration: 3000,
        //   horizontalPosition: this.horizontalPosition,
        //   verticalPosition: this.verticalPosition,
        // });
        localStorage.setItem('vendorData', JSON.stringify(res.data));
        localStorage.setItem('authToken', res.authToken);
        localStorage.setItem('userId', res.data.userId);
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('type', 'user')


        // if(res. is_new_user == 'false'){
        //   $("#resetPassModal").modal("show");
        // }

        this.router.navigate(['dashboard']);
      }
      if (res.status == 'success' && res.data.is_new_user == false) {

        // this.snackBar.open(res.message,'', {
        //   duration: 3000,
        //   horizontalPosition: this.horizontalPosition,
        //   verticalPosition: this.verticalPosition,
        // });
        localStorage.setItem('vendorData', JSON.stringify(res.data));
        localStorage.setItem('authToken', res.authToken);
        localStorage.setItem('userId', res.data.userId);
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('type', 'user')

        this.openDialog();


      }

    else if(res.status == 'failed'){
        console.log('login failed');
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or Internet Connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
        // this.flashMessage.show("Some error occured due to server or Internet Connection!", { cssClass: 'alert-danger', timeout: 2000 });
      })
  }


  // convenience getter for easy access to form fields
  get f() { return this.forgetPassForm.controls; }


  forgetPassword() {
    this.submitted = true;
    console.log(this.forgetPassForm);
    if (this.forgetPassForm.invalid) {
      this.forgetPassForm.reset();
      this.submitted = false;
      return;
    }
    this.vendorEmail = this.forgetPassForm.value.vendorEmail;
    console.log("email is ", this.vendorEmail);
    this.authService.forgetPassword(this.vendorEmail).subscribe((res: any) => {
      console.log(res);

      if (res.status == 'success') {
        jQuery.noConflict();
        $("#forgetPassModal").modal("hide");
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.router.navigate(['login']);
        // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 3000 });

      }
      else {
        jQuery.noConflict();
        $("#forgetPassModal").modal("hide");
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 3000 });

      }
    },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or Internet Connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or Internet Connection!", { cssClass: 'alert-danger', timeout: 3000 });
      })
  }
  // open reset password modal
  openDialog(): void {
    const dialogRef = this.dialog.open(ResetpasswordComponent, {
      width: '550px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }
  // get url for terms and conditions
  getUrl() {
    window.open(this.termsUrl, "_blank");
  }
}
