import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HalfdounoghtService {
  data = []
  constructor() { }

  setData (data){
   this.data=data;
  }
  async getData (){
     return await this.data;
  }
}
