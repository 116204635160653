import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { TasksComponent } from './tasks/tasks.component';
import { VendorGuard } from './guard/vendor.guard';
import { AssetsComponent } from './assets/assets.component';
import { StaffComponent } from './staff/staff.component';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CreateTaskComponent } from './create-task/create-task.component';
//import { EditTaskComponent } from './edit-task/edit-task.component';
import { NotificationComponent } from './notification/notification.component';
import { SignupComponent } from './signup/signup.component';
import { QuickTaskComponent } from './quick-task/quick-task.component';
import { QuickTaskListComponent } from './quick-task-list/quick-task-list.component';
import { PartsComponent } from './parts/parts.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { DistributorDashboardComponent } from './distributor-dashboard/distributor-dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';

const routes: Routes = [
  { path: '', redirectTo:'login', pathMatch:'full' },
  { path: 'login', component:LoginComponent },
  { path: 'signup', component:SignupComponent },
  { path: 'dashboard', component:DashboardComponent, canActivate: [VendorGuard]},
  { path: 'tasks', component:TasksComponent, canActivate: [VendorGuard] },
  { path: 'calendar', component:CalendarComponent, canActivate: [VendorGuard] },
  { path: 'createTask', component:CreateTaskComponent, canActivate: [VendorGuard] },
  { path: 'quickTask', component : QuickTaskComponent, canActivate : [VendorGuard] },
  {path : 'quickTaskList', component : QuickTaskListComponent, canActivate : [VendorGuard]},
 // { path: 'editTask', component:EditTaskComponent, canActivate: [VendorGuard] },
  { path: 'customer', component:AssetsComponent, canActivate: [VendorGuard]},
  { path: 'Machines', component:PartsComponent, canActivate: [VendorGuard]},
  { path: 'serviceEngineer', component:StaffComponent, canActivate: [VendorGuard]},
  { path: 'vendorProfile', component:VendorProfileComponent, canActivate: [VendorGuard]},
  { path: 'notification', component:NotificationComponent, canActivate: [VendorGuard] },
  { path: 'resetPassword', component:ResetpasswordComponent, canActivate: [VendorGuard] },
  { path: 'distributorDashboard', component: DistributorDashboardComponent},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
