import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortPipe'
})
export class SortPipePipe implements PipeTransform {
// sort account name 
  transform(array: Array<string>, args: string): Array<string> {
    return array.sort((a: any, b: any) => {
      if (a.accountName < b.accountName) {
        return -1;
      } else if (a.accountName > b.accountName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
