import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  name:string;
  constructor(private router:Router) {
    this.name=localStorage.getItem("name");
  }

  ngOnInit() {
    $(document).ready(function() {
      console.log("inside");
      $("#sidebar").mCustomScrollbar({
          theme: "minimal"
      });
      
      $('#dismiss, .overlay').on('click', function () {
          // hide sidebar
          $('#sidebar').removeClass('active');
          // hide overlay
          $('.overlay').removeClass('active');
      });
  
      $('#sidebarCollapse').on('click', function () {
          // open sidebar
          $('#sidebar').addClass('active');
          // fade in the overlay
          $('.overlay').addClass('active');
          $('.collapse.in').toggleClass('in');
          $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
    });
  }
  
  async logout(){
    if(confirm("Do you want to logout?  ")) {
      await localStorage.clear();
      this.router.navigate(['login']);
    }
  }
}
