import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import{ FileserviceService} from '../service/fileservice.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MytoasterService } from '../service/mytoaster.service';
declare var $: any;
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import * as _ from 'lodash';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})


export class AssetsComponent implements OnInit {
  displayedColumns: string[] = ['accountName', 'contactAddress1', 'contactName1', 'contactEmail1', 'contactNumber1','contactName2', 'contactEmail2', 'contactNumber2', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('UploadFileInput',) uploadFileInput: ElementRef;
  fileUploadForm: FormGroup;
  fileInputLabel: string;
  @ViewChild('assetsData') assetsData: ElementRef;
  assetDetails: any = {};
  assetsForm: FormGroup;
  editAssetForm: FormGroup;
  machineParts: FormGroup;
  addPartsForm: FormGroup;
  assetData: any;
  userId: any;
  assetsList: any;
  showList: boolean = false;  // to hide assets lists in starting
  updatedData: {};
  assetInfo: { userId: any; assetId: any; };
  submitted = false;
  showForm: boolean = false;
  partDetail: {};
  partId: any;
  partsDetail: {};
  partsList: any;
  uploadCsvAssets: FormGroup;
  assetsUploadData: any;
  assetUploadDataLength: any;
  searchDetail: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  errorMessage: string = "Some error occured due to server or internet connection!";
  uploadCsvStaff: FormGroup;
  csvValue: {}[];
  showspan: boolean = false;
  showspan1: boolean = false;
  showspan2: boolean = false;
  data: any =[{
    "Account_name":'', "Address":'',
     "First_contact_mail_id":'',
      "First_contact_no":'', "First_contact_name":'',
       "Second_contact_mail_id":'',
        "Second_contact_no":'', "Second_contact_name":''
  }];

  constructor(private fb: FormBuilder,  private http: HttpClient,private fileService: FileserviceService, public router: Router, private authService: AuthService, private toaster: MytoasterService, private flashMessage: FlashMessagesService, public snackBar: MatSnackBar) {
  }
  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    console.log(this.userId);

    this.editAssetForm = this.fb.group({
      assetId: ['', Validators.required],
      accountName: ['', Validators.required],
      contactAddress1: ['', Validators.required],
      contactName1: ['', Validators.required],
      contactNumber1: ['', Validators.required],
      contactEmail1: ['', Validators.required],
      contactName2: [''],
      contactNumber2: [''],
      contactEmail2: ['']

    });



    this.uploadCsvAssets = this.fb.group({
      assetsCsvData: ['']
    });
    this.fileUploadForm = this.fb.group({
      myfile: ['']
    });
    // this.dataSource = new MatTableDataSource();
    this.assetList();

    console.log(this.editAssetForm.value)
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.editAssetForm.controls; }
  get m() { return this.addPartsForm.controls; }
  //get g() { return this.assetsForm.controls; }

  /** function to add an asset by vendor */
  addAsset(assetForm: any) {
    this.assetData = {

      // assetName: this.assetDetails.machName,
      // machineNumber: this.assetDetails.machineNumber,
      // serialNumber: this.assetDetails.serialNumber,
      // regNumber: this.assetDetails.regNumber,
      // code: this.assetDetails.code
      // accountName: ['', Validators.required],
      // contactAddress1:['', Validators.required],
      // contactName1:['', Validators.required],
      // contactNumber1:['', Validators.required],
      // contactEmail1:['', Validators.required],
      // contactName2: [''],
      // contactNumber2: [''],
      // contactEmail2:['']
      userId: this.userId,
      accountName: this.assetDetails.accountName,
      contactAddress1: this.assetDetails.contactAddress1,
      contactName1: this.assetDetails.contactName1,
      contactEmail1: this.assetDetails.contactEmail1,
      contactNumber1: this.assetDetails.contactNumber1,
      contactName2: this.assetDetails.contactName2,
      contactEmail2: this.assetDetails.contactEmail2,
      contactNumber2: this.assetDetails.contactNumber2,


    }
    console.log("assets data is ", this.assetData);
    this.authService.addAsset(this.assetData).subscribe((res: any) => {
      if (res.status = "success") {
        console.log("assets response is ", res);
        assetForm.resetForm(); //to reset the form
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.assetList();
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      })
  }
  /** function to show asset list */
  assetList() {
    console.log("asset list");
    this.authService.assetList(this.userId).subscribe((res: any) => {
      if (res.status == "success") {
        console.log("assets list response is ", res);
        this.showList = true;  // to show assets list
        this.assetsList = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        console.log('this.dataSource', this.dataSource);
        this.dataSource.paginator = this.paginator;   //for pagination
        this.dataSource.sort = this.sort;  //for sorting
      }
      // if(res.status=='sessionExpired'){
      //   this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 }); 
      //   this.router.navigate(['login']);
      // }
     else if(res.status == 'failed'){
       
        this.assetsList == undefined;
        this.assetsList = [];
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }
  /** function to prefill input values in edit modal */
  assetEdit(list) {
    console.log("list is ", list);
    this.editAssetForm.setValue({
      // assetId: list.assetId,
      // machName: list.assetName,
      // machineNumber: list.machineNumber,
      // serialNumber: list.serialNumber,
      // regNumber: list.regNumber,
      // code: list.code
      assetId: list.assetId,
      accountName: list.accountName,
      contactAddress1: list.contactAddress1,
      contactName1: list.contactName1,
      contactEmail1: list.contactEmail1,
      contactNumber1: list.contactNumber1,
      contactName2: list.contactName2 ? list.contactName2 : '',
      contactEmail2: list.contactEmail2 ? list.contactEmail2 : '',
      contactNumber2: list.contactNumber2 ? list.contactNumber2 : '',
    })
  }

  /** function to edit an asset */
  editAsset() {
    this.submitted = true;
    if (this.editAssetForm.invalid) {
      return;
    }
    this.updatedData = {
      userId: parseInt(this.userId),
      assetId: this.editAssetForm.value.assetId,
      accountName: this.editAssetForm.value.accountName,
      contactAddress1: this.editAssetForm.value.contactAddress1,
      contactName1: this.editAssetForm.value.contactName1,
      contactEmail1: this.editAssetForm.value.contactEmail1,
      contactNumber1: this.editAssetForm.value.contactNumber1,
      contactName2: this.editAssetForm.value.contactName2,
      contactEmail2: this.editAssetForm.value.contactEmail2,
      contactNumber2: this.editAssetForm.value.contactNumber2,
    }
    console.log(this.updatedData);
    this.authService.assetEdit(this.updatedData).subscribe((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        jQuery.noConflict();
        $("#editAssetModal").modal('hide');
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.assetList();
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });;
      })
  }

  /** function to delete an asset */
  deleteAsset(list) {
    if (confirm("Are you sure you want to delete it ? ")) {
      this.assetInfo = {
        userId: this.userId,
        assetId: list.assetId
      }
      this.authService.assetDelete(this.assetInfo).subscribe((res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.assetList();
        }
        else {
          // this.toaster.showToast('Failed!',res.message, 'danger');
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
        (error) => {
          console.log('error is ', error.message);
          this.snackBar.open(this.errorMessage, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        })
    }
  }

  /** function to show name of machine in modal */
  // showParts(list){
  //   this.machineParts.setValue({
  //     machineName: list.assetName
  //   });
  //   this.partId= list.assetId;  //asset assetId
  //   this.showPartsList();
  // }

  /** function to show asset parts list */
  // showPartsList(){
  //   this.partsDetail={
  //     userId: this.userId,
  //     assetId: this.partId
  //   }
  //   this.authService.viewParts(this.partsDetail).subscribe((res:any) => {
  //     console.log(res);
  //     if(res.status=='success'){
  //         //this.emptyList = false;
  //         this.partsList = res.data;
  //     }
  //     else{
  //       this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //     }
  //   },
  //   (error) => {
  //     console.log('error is ',error.message);
  //     this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //   })
  // }

  /** function to add part of machine */
  // AddMachinePart(){
  //   this.submitted= true;
  //   if(this.addPartsForm.invalid){
  //     return;
  //   }
  //   this.partDetail= {
  //     userId: this.userId,
  //     assetId: this.partId,
  //     name: this.addPartsForm.value.partName,
  //     description: this.addPartsForm.value.partDescription
  //   }
  //   console.log(this.partDetail);
  //   this.authService.addMachPart(this.partDetail).subscribe((res:any) => {
  //     console.log(res);
  //     if(res.status=='success'){
  //       this.addPartsForm.reset();
  //       this.submitted= false;
  //       //this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });
  //       //jQuery.noConflict();
  //       this.toaster.showToast('Success!', 'New Asset part added!!', 'success');
  //       this.showPartsList();
  //     }
  //     else{
  //       this.submitted= false
  //       this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //     }
  //   },
  //   (error) => {
  //     this.submitted= false
  //     console.log('error is ',error.message);
  //     this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //   })
  // }

  // deleteAssetPart(list:any){
  //   if(confirm("Are you sure you want to delete this part ? ")) {
  //     console.log("part list ",list);
  //     let partDetail = {
  //       userId: this.userId,
  //       assetId: this.partId,
  //       partId: list.partId
  //     }
  //     console.log(partDetail);
  //     this.authService.deleteAssetsPart(partDetail).subscribe((res:any) => {
  //       console.log(res);
  //       if(res.status=='success'){
  //         this.showPartsList();
  //         this.toaster.showToast('Success!',res.message, 'success');
  //        // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });
  //       }
  //       else{
  //         this.toaster.showToast('Failed!',res.message, 'danger');
  //       }
  //     },
  //     (error) => {
  //      // console.log('error is ',error.message);
  //       this.toaster.showToast('Failed!',this.errorMessage, 'danger');
  //       this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //     })
  //   }  
  // }

  /** function to upload the assets csv file */
  onSubmit() {
    if(!this.assetsData.nativeElement.files[0]){
      this.toaster.showToast('Failed!', "Please choose the excel file first!", 'danger');
      return;
    }
    const formData = new FormData();
    let userId = this.userId;

    console.log("userid is ", userId);
    formData.append('uploadfile', this.assetsData.nativeElement.files[0], this.assetsData.nativeElement.files[0].name);
    console.log("form data is ", this.assetsData.nativeElement.files[0]);
    this.authService.uploadAssetList(formData, userId).subscribe(
      (response: any) => {
        console.log('UPLOADING assets response ', response);
        if (response.status == "success") {
          this.assetsData.nativeElement.value = "";   //to reset the file name
          this.assetsUploadData = response.data;
          this.assetUploadDataLength = this.assetsUploadData.length;
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.assetList();
          if( this.assetUploadDataLength > 0){
            jQuery.noConflict();
            $("#assetLogModal").modal('show');
          }
         
        }
        else {
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        console.log('error is ', error);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }

  /** function to search the asset */
  search(term: any) {
    this.searchDetail = {
      userId: this.userId,
      accountName: this.assetDetails.accountName
    }
    console.log('search', this.searchDetail)
    this.authService.searchAsset(this.searchDetail).subscribe((res: any) => {
      if (res.status == "success") {
        this.assetsList = res.data;
      }
    })
  }
// restrict to add alphabet
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: ["Account_name", "Address", "First_contact_mail_id", "First_contact_no", "First_contact_name", "Second_contact_mail_id", "Second_contact_no", "Second_contact_name"]
  };


// download excel file
  downloadCSV() {
    this.fileService.exportAsExcelFile(this.data, 'Accounts');
    // this.csvValue = [{}]
    // //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    // // console.log('csvvvv' ,this.createdTaskList)
    // new AngularCsv(this.csvValue, 'Accounts', this.csvOptions);

  }
  clickToDownloadCSV() {
    jQuery.noConflict();
    $("#assetDownloadModal").modal('show');
  }
  toggleValue(event) {
    console.log(event);
    this.showspan = !this.showspan;
    console.log(this.showspan);

  }
  toggleValue1(event) {
    this.showspan1 = !this.showspan1;
    console.log(this.showspan1);
  }

  toggleValue2(event) {
    this.showspan2 = !this.showspan2;
   console.log(this.showspan2)

  }


  // onFileSelect(event) {
  //   let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     // console.log(file);

  //     if (!_.includes(af, file.type)) {
  //       alert('Only EXCEL Docs Allowed!');
  //     } else {
  //       this.fileInputLabel = file.name;
  //       this.fileUploadForm.get('myfile').setValue(file);
  //     }
  //   }
  // }
  // onFormSubmit() {

  //   if (!this.fileUploadForm.get('myfile').value) {
  //     alert('Please fill valid details!');
  //     return false;
  //   }

  //   const formData = new FormData();
  //   formData.append('formFile', this.fileUploadForm.get('myfile').value);
  //   formData.append('agentId', '007');


  //   this.http.post<any>('http://www.example.com/api/upload', formData).subscribe(response => {
  //       console.log(response);
  //       if (response.statusCode === 200) {
  //         // Reset the file input
  //         this.uploadFileInput.nativeElement.value = "";
  //         this.fileInputLabel = undefined;
  //       }
  //     }, error => {
  //       console.log(error);
  //     });
  // }
}
