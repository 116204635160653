import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MytoasterService } from '../service/mytoaster.service';
import { Router } from '@angular/router';
declare var $: any;
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { FileserviceService } from '../service/fileservice.service';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import * as moment from 'moment';
//var $  = require( 'jquery' );
//var dt = require( 'datatables.net' )();

@Component({
  selector: 'app-quick-task-list',
  templateUrl: './quick-task-list.component.html',
  styleUrls: ['./quick-task-list.component.scss']
})
export class QuickTaskListComponent implements OnInit {
  @ViewChild('select') selectElRef: any;
  @ViewChild('taskData') taskData: ElementRef;
  @ViewChild('myList') myList: ElementRef;

  // for angular material
  displayedColumns: string[] = ['taskName', 'assignName', 'dueDate', 'taskStatus', 'comment'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  results: any;
  name: FormControl = new FormControl();
  userId: any;
  empName: any;
  empPhone: any;
  empNameList: boolean = false;
  assetName: any;
  assetsList: any;
  watchersList: any;
  selectedWatchers: any[] = [];
  watchersCount: number = 0;
  addTaskForm: FormGroup;
  taskFormDetail: {};
  submitted = false;
  notFound: any;
  createdTaskList: any;
  searchDetail: {};
  selectedAssets: any;
  partNameLength: number = 0;
  partsName: string;
  selectedAssetSerialNumber: any;
  partName: any;
  selectedPart: boolean = false;
  selectedIndex: number;
  assignAsset: boolean = false;
  uploadForm: FormGroup;
  addCommentForm: FormGroup;
  commentForm: FormGroup;
  particularTaskDetail: any;
  assetCount: number = 0;
  countOfWatchers: number = 0;
  taskId: any;
  commentDetail: any;
  previousComments: any;
  previousCommentsLength: number = 0;
  taskUploadData: any;
  editTaskForm: FormGroup;
  taskUploadDataLength: any;
  editTask: boolean = false;
  editPartNameLength: number = 0;
  editWatchersCount: any;
  editSelectedAsset: any;
  editSelectedPart: any;
  editSelectedWatchers: any;
  editTaskId: any;
  assigneePhone: any;
  assignName: any;
  editSelectedAssetName: any;
  editSelectedWatchersStaffId = [];
  commentImageUrl: any;
  detail: {};
  showImageBtn: boolean = true;
  taskStatus: any;
  taskResult: any;
  filterDate: boolean = false;
  fliterTaskStatus: boolean = false;
  filterForDate: any = {};
  errorMessage: string = "Some error occured due to server or internet connection!";
  dateValue: any;
  statusList = [
    { title: 'All', name: 'all' },
    { title: 'Complete', name: 'complete' },
    { title: 'Incomplete', name: 'incomplete' },
    { title: 'Pending', name: 'pending' }
  ];
  selectedTaskStatus: any;
  eventtype;
  detectDropdown = ''
  // quick task fields properties
  quickTaskList: any;
  quickTaskModalData: any;
  searchquickTaskList: boolean = false;

  // comment props
  onlyFinish: boolean = false;
  finishWithText: boolean = false;
  finishWithImage: boolean = false;
  finishWithTextandImage: boolean = false;
  csvValue: {}[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  dateFilter: any;
  // name:any;
  taskname: any;
  title: any;
  data: any = [{
    "Service_engineer_number": '', "Task_name": '', "Description": '', "Watchers": '', "Due_date": ''
  }];

  constructor(private authService: AuthService, private fb: FormBuilder, private fileService: FileserviceService, private toaster: MytoasterService, private flashMessage: FlashMessagesService, private router: Router, public snackBar: MatSnackBar) {
    this.userId = localStorage.getItem("userId");
    this.uploadForm = this.fb.group({
      taskCsvData: ['']
    });

    this.addCommentForm = this.fb.group({
      taskName: ['', Validators.required],
      assignedTo: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.editTaskForm = this.fb.group({
      taskName: ['', Validators.required],
      assignName: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    });

    this.showQuickTaskList();
  }

  ngOnInit() {
    $("#iconToggler").click(function () {
      $(this).find('img').toggle();
      $(this).find('span').toggle();
    });

  }

  // function to fetch data of Quick Task list and show in UI
  showQuickTaskList() {
    this.taskStatus = this.statusList[0].name;
    console.log(this.taskStatus);

    this.searchDetail = {
      userId: this.userId,
      taskStatus: this.taskStatus
    }
    this.authService.quickTaskList(this.searchDetail).subscribe((res: any) => {
      console.log(res, "from quick task");
      if (res.status == "success") {
        //for sorting
        this.quickTaskList = res.data;
        for (let data of this.quickTaskList) {
          data.dueDate = moment(data.dueDate).format('YYYY-MM-DD');
          console.log('due', data.dueDate);
        }
        console.log(this.quickTaskList);
        this.dataSource = new MatTableDataSource(this.quickTaskList);
        this.dataSource.paginator = this.paginator;   //for pagination
        this.dataSource.sort = this.sort;

        console.log(this.dataSource, "from data source");
      }
      if (res.status == 'failed') {

        this.quickTaskList == undefined;
        this.quickTaskList = [];
      }
      if (res.status == 'sessionExpired') {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.router.navigate(['login']);
      }
      else {
        // this.snackBar.open(res.message,'', {
        //   duration: 3000,
        //   horizontalPosition: this.horizontalPosition,
        //   verticalPosition: this.verticalPosition,
        // });
      }
    }, (err) => {
      this.snackBar.open(this.errorMessage, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }

  setQuickTaskModalData(task) {
    this.quickTaskModalData = task;
    console.log(this.quickTaskModalData, "modal data");
  }

  applyFilter(filterValue: string) {
    this.dateFilter = '';
    this.title = '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** for filter through date */
  calendarFilter() {
    this.fliterTaskStatus = false;  //for closing task status filter if already open
    if (this.filterDate) {
      this.filterDate = false;
    }
    else {
      this.filterDate = true;  //for opening calendar filter
    }
  }

  /** for getting date value in filter */
  parseDate(dateString: string): Date {
    this.title = '';
    this.taskname = '';
    if (dateString) {
      // return new Date(dateString);
      console.log("date ", dateString);
      let filterDateDetails = {
        userId: this.userId,
        date: dateString
      }
      this.authService.dateFilterForQuickTask(filterDateDetails).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 'success') {
            this.dataSource = new MatTableDataSource(res.data);
            // this.dataSource.paginator = this.paginator;   //for pagination
            // this.dataSource.sort = this.sort;   //for sorting
            // this.quickTaskList = res.data;
          }
          else {
            this.snackBar.open(res.message, '', {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        },
        (error: any) => {
          this.snackBar.open(this.errorMessage, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      )
    }
    return null;
  }

  /** for filter through task status */
  taskStatusFilter() {
    this.filterDate = false; //for closing calendar filter if already open
    if (this.fliterTaskStatus) {
      this.fliterTaskStatus = false;
    }
    else {
      this.fliterTaskStatus = true;  //for opening task status filter
    }
  }

  /**function to get task status based on selection from dropdown */
  changeFilterStatus(e: any) {
    this.eventtype = e
    this.detectDropdown = 'dateDropdown'
    console.log(e);

    this.dateFilter = '';
    this.taskname = '';
    this.statusList.filter((element: any) => {
      if (element.name == e.value) {
        this.selectedTaskStatus = element.name;
        console.log("status", this.selectedTaskStatus)
      }
    });
    let filterStatus = {
      userId: this.userId,
      taskStatus: this.selectedTaskStatus
    }
    this.authService.quickTaskList(filterStatus).subscribe(
      (res: any) => {
        console.log("quicktask filter", res);
        if (res.status == "success") {
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.sort = this.sort; 
        }
        else if (res.status == "failed") {
          res.data = [];
          this.dataSource = new MatTableDataSource(res.data);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      }, (err) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )

  }


  /** function to upload the csv file */
  onSubmit() {
    if (!this.taskData.nativeElement.files[0]) {
      this.toaster.showToast('Failed!', "Please choose the excel file first!", 'danger');
      return;
    }
    const formData = new FormData();
    let userId = this.userId;

    console.log("userid is ", userId);
    formData.append('uploadfile', this.taskData.nativeElement.files[0], this.taskData.nativeElement.files[0].name);
    console.log("form data is ", this.taskData.nativeElement.files[0]);
    this.authService.uploadQuickTaskCsv(formData, userId).subscribe(
      (response: any) => {
        console.log('UPLOADING response ', response);
        if (response.status == "success") {
          this.taskData.nativeElement.value = "";   //to reset the file name
          this.taskUploadData = response.data;
          console.log(this.taskUploadData);

          this.taskUploadDataLength = this.taskUploadData.length;
          console.log(this.taskUploadDataLength);

          this.snackBar.open(response.message, '', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.showQuickTaskList();
          if (this.taskUploadDataLength > 0) {
            jQuery.noConflict();
            $("#taskLogModal").modal('show');
          }

        }
        else {
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }

  /** function to know new task is selected */
  newTaskOpen() {
    this.editTask = false;
    console.log("here");
    //this.router.navigate['/createTask'];
  }



  /** function to get task details while click on show comment button and to show it on modal */
  employeeDetails(list: any) {
    this.taskId = list.taskId;
    let taskDetail = {
      taskId: this.taskId,
      userId: this.userId
    }
    console.log(taskDetail, "from Data kamm");
    this.authService.particularTaskComments(taskDetail).subscribe(
      (res: any) => {
        console.log("response ", res);
        if (res.status == "success") {
          this.particularTaskDetail = res.data[0];
          console.log(this.particularTaskDetail, "particular");
          let wholeDate = this.particularTaskDetail.dueDate;
          let a = wholeDate.split("T");
          let date = a[0];
          // console.log("date is ",date); 
          this.addCommentForm.setValue({
            taskName: this.particularTaskDetail.taskName,
            dueDate: date,
            assignedTo: this.particularTaskDetail.assignName,
            description: this.particularTaskDetail.description
          });
          this.assetCount = 1;
          this.countOfWatchers = this.particularTaskDetail.watchers.length;
          this.previousComments = this.particularTaskDetail.comments;
          this.previousCommentsLength = this.previousComments.length;
          this.taskResult = this.particularTaskDetail.taskResult;
          this.taskStatus = this.particularTaskDetail.taskStatus;
          console.log("task-result ->", this.taskResult);
          console.log('chat data ->', this.previousComments);
          this.filtercomments();
        }
        else {
          console.log(res.message);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        // console.log('error is ',error);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });;
      }
    )
  }
  // coment filter
  filtercomments() {
    this.previousComments.forEach(element => {
      if (element.type == "Finish" && element.staffId && this.taskStatus == 'pending') {
        console.log(element);
        if (element.comment == "" && element.image == "null" && element.commentStatus == "null") {
          this.onlyFinish = true;
          console.log(this.onlyFinish);
        }
        else if (element.comment != "" && element.image == "null" && element.commentStatus == "null") {
          this.finishWithText = true;
        }
        else if (element.comment == "" && element.image != "null" && element.commentStatus == "null") {
          this.finishWithImage = true;
        }
        else if (element.comment != "" && element.image != "null" && element.commentStatus == "null") {
          this.finishWithTextandImage = true;
        }
      }
    });
  }

  // function to remind to staff by tap to remind
  remindToStaff(taskId) {
    console.log('Task ID is ', taskId);
    this.authService.remindStaff(taskId).subscribe((res: any) => {
      console.log(res);
      if (res.status == "success") {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.errorMessage, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }

  /** function to save the comment */
  submitComment() {
    let commentDetail = {
      userId: this.userId,
      taskId: this.taskId,
      comment: this.commentForm.value.comment
    }
    this.authService.addComment(commentDetail).subscribe(
      (res: any) => {
        console.log("res is ", res);
        if (res.status == "success") {
          this.commentDetail = res.data;
          console.log("comment is ", this.commentDetail);
          this.previousCommentsLength = this.previousCommentsLength + 1;
          this.previousComments.push({ "userId": this.userId, "comment": this.commentDetail.comment, "createdAt": this.commentDetail.createdAt });
          this.filtercomments();
          this.commentForm.reset();
        }
        if (res.status == "failed") {
          console.log("error", res.message);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        console.log("error is", error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });;

      }
    )
  }

  /** function to bring scrollbar at bottom in comment modal */
  scrollToBottom() {
    this.myList.nativeElement.scrollTop = this.myList.nativeElement.scrollHeight;
  }

  /**this function is called when rejected button is clicked on image send by staff on comment section */
  taskRejected(commentId) {
    console.log("task rejected clicked", commentId);
    this.detail = {
      userId: this.userId,
      taskId: this.taskId,
      taskResult: "reject",
      commentId: commentId
    }
    this.authService.approvedOrRejectTask(this.detail).subscribe(
      (res: any) => {
        console.log("rej res is", res);
        if (res.status == 'success') {
          this.taskResult = res.data.taskResult;
          if(this.detectDropdown == 'dateDropdown'){
            this.handleDateChange(this.eventtype)
          }
          if(this.detectDropdown == 'taskStatus_select'){
            this.changeFilterStatus(this.eventtype)
          }
          if(this.detectDropdown == ''){
            this.showQuickTaskList();
          }
        }
      },
      (error: any) => {
        console.log("error is ", error);
      }
    )
  }
  resetFilter(){
    this.dateFilter = '';
    this.taskname = '';
    this.title = '';
    this.showQuickTaskList();
  }
  /**this function is called when approved button is clicked on image send by staff on comment section */
  taskApproved(commentId) {
    console.log("task approved clicked");
    this.detail = {
      userId: this.userId,
      taskId: this.taskId,
      taskResult: "approve",
      commentId: commentId
    }
    this.authService.approvedOrRejectTask(this.detail).subscribe(
      (res: any) => {
        console.log("approve res is", res);
        if (res.status == 'success') {
          this.taskResult = res.data.taskResult;
          if(this.detectDropdown == 'dateDropdown'){
            this.handleDateChange(this.eventtype)
          }
          if(this.detectDropdown == 'taskStatus_select'){
            this.changeFilterStatus(this.eventtype)
          }
          if(this.detectDropdown == ''){
            this.showQuickTaskList();
          }
        }
      },
      (error: any) => {
        console.log("error is ", error);
      }
    )
  }


  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: ["Service_engineer_number", "Task_name", "Description", , "Watchers", "Due_date"]
  };

  // download excel file
  downloadCSV() {
    this.fileService.exportAsExcelFile(this.data, 'Quick Task');
    // this.csvValue=[{ }]
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    // console.log('csvvvv' ,this.createdTaskList)
    // new AngularCsv(  this.csvValue, 'QuickTask', this.csvOptions);

  }
  clickToDownload() {
    jQuery.noConflict();
    $("#taskDownloadModal").modal('show');
  }
  // filter by date
  handleDateChange(event) {
    this.eventtype = event
    this.detectDropdown = 'dateDropdown'
    this.title = '',
    console.log('title',  this.title )
    const m = moment(event.value).format('YYYY-MM-DD');
    console.log('m', m);

    let filterDateDetails = {
      userId: this.userId,
      date: m,
      type: 'quicktask'
    }
    this.authService.dateFilterForQuickTask(filterDateDetails).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.quickTaskList = res.data;
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort; 
        }
        // if(res.status == 'failed'){
        //     alert('hi');
        //   this.createdTaskList == undefined;
        //   this.createdTaskList = [];
        // }

        else {
          res.data = [];
          this.quickTaskList = res.data;
          console.log('thissssssssssssssss', this.quickTaskList)
          this.dataSource = new MatTableDataSource(this.quickTaskList);
          console.log('thisssss', this.dataSource)
          this.dataSource.paginator = this.paginator;
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      }
    )
  }

}
