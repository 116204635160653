import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-labels';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, Color } from 'ng2-charts';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { FusionChartsModule } from 'angular-fusioncharts';

import { SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { Chart } from 'chart.js';
// Import FusionCharts library and chart modules
;
import * as zoomPlugin from 'chartjs-plugin-zoom';
import { PrintgraphComponent } from '../printgraph/printgraph.component';
import { HalfdounoghtService } from '../service/halfdounoght.service';
// Pass the fusioncharts library and chart modules
export interface DialogData {

}
declare var $: any;
declare var Chart2;
declare var require: any
declare var jsPDF: any;
const FileSaver = require('file-saver');



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {



  @ViewChild('printDiv') printDiv: ElementRef;
  selectedName: any;
  totalOilConsumption: number = 0;
  totalOilConsumptionSecond: number = 0;
  dashboardData: any;
  completeTask: number = 0;
  incompleteTask: number = 0;
  overdueTask: number = 0;
  activeAsset: number = 0;
  blockAsset: number = 0;
  totalAsset: number = 0;
  totalStaff: number = 0;
  totalTask: number = 0;
  staffTaskCount: any;
  staffName: any;
  assetName: any;
  assetTaskCount: any;
  freeStaffMember: number = 0;
  workingStaffMember: number = 0;
  barChartData: ChartDataSets[];
  barChartLabels: Label[];
  barChartType: ChartType;
  barChartLegend: boolean;
  barChartPlugins: [zoomPlugin];
  barChartOptions: ChartOptions = { responsive: true };
  barChartData2: ChartDataSets[];
  barChartLabels2: Label[];
  barChartType2: ChartType;
  barChartLegend2: boolean;
  barChartPlugins2: (typeof pluginDataLabels)[];
  barChartOptions2: ChartOptions;
  chartReady: boolean = false;
  chartColors: any[];
  pendingTask: string;
  authToken: string;
  barChartData1: { data: number[]; label: string; stack: string }[];
  barcolorsColor1: { backgroundColor: string; }[];
  barChartColors: { backgroundColor: string; }[];
  barChartColors1: { backgroundColor: string; }[];
  barChartLegend1: boolean;
  barChartType1: string;
  barChartLabels1: any;
  dashboardCustomerData: any;
  dashboardServiceData: any;
  Linechart: any;
  lineChartData: { data: number[]; label: string; }[];
  lineChartLabels: string[];
  lineChartOptions: ChartOptions;
  lineChartLegend: boolean;
  lineChartType: string;
  lineChartPlugins: any[];
  lineChartColors: { borderColor: string; backgroundColor: string; }[];
  userId: any;
  chartData: { data: number[]; label: string; fill: boolean; }[];
  chartLabels: string[];
  chartOptions: { scales: { yAxes: { ticks: { beginAtZero: boolean; stepSize: number; }; }[]; }; annotation: { drawTime: string; annotations: { type: string; id: string; yScaleID: string; yMin: number; yMax: number; backgroundColor: string; }[]; }; };

  addDateFilterForm: FormGroup;
  // value: Date = new Date();
  dateValue: Date = new Date;
  start: Date = null;
  end: Date = null;
  noOfDates: number;
  lineChartData1: { data: number[]; label: string; }[];
  accountName: any;
  assetId: any;
  customerAssetId: any;
  customerAccountName: any;
  filterData: {};
  serviceEngineerList: any;
  machineName: any;
  trendData: any;
  trend1: any;
  trend2: any;
  splineDate: any;
  topup: any;
  topup4: any;
  showDiv: boolean = false;
  errorMessage: string;
  assetsList: any;
  machineList: [];
  machineUniqueCode: any;
  oilChart: boolean = false;
  lineChartData2: { data: number[]; label: string; }[];
  splineChartData: any;
  from: any;
  to: any;
  filteredData: { userId: any; from: Date; to: Date; assetId: any; uniqueCode: any; type: string; };
  type: 'bydate'
  barChartReady: boolean = false;
  message: any;
  buttonShow: boolean = false;
  splineCsvData: any;
  chartShow: boolean = false;
  status: any;
  machinefirstPartList: any;
  setCsvData: { date: any; accountName: any; machine_name: any; machine_unique_Code: any; concentration_before: any; concentration_after: any; oil_top_up_quantity: any; ph_before_top_up: any; tramp_oil: any; water_top_up_quantity: any; };
  finalcsvData: any = [];
  finalcsvByData: any = [];
  csvButtonShow: boolean = false;
  barChartOptions1: ChartOptions;
  barChartPlugins1: [];
  barChartLabelsName: any;
  @ViewChild('chart') chart: ElementRef;
  start1: any;
  end1: any;
  lineChartphColors: { borderColor: string; backgroundColor: string; }[];
  showDate: boolean = false;
  barChartColors3: { backgroundColor: string; }[];
  pointStyle: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  showText: boolean = false;
  machineMakeModel: any;
  machineCode: any;
  customerLength: any;
  increaseWidthChart: boolean = false;
  pieChartOptions: ChartOptions;
  pieChartLabels: any;
  pieChartData: any;
  pieChartType: any;
  pieChartLegend: boolean;
  pieChartPlugins: any[];
  dataName: any;
  graphCount: any;

  constructor(private authService: AuthService,
    private halfservice: HalfdounoghtService,
    private flashMessage: FlashMessagesService, public dialog: MatDialog, private fb: FormBuilder, public router: Router, public snackBar: MatSnackBar) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }



  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'DashBoard Data:',
    useBom: true,
    noDownload: false,
    headers: ["Date", "Account Name", "Machine Name", "Machine Unique Code", "Concentration Before", "Concentration After", "Oil Top Up Quantity", "ph Value", "Tramp Oil", "Water Top Up Quantity"]
  };
  chart1: any;
  data1: any = []
  // ==============================================================================================
  chartData1() {
    this.chart1 = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: [
         this.incompleteTask + " Incomplete", this.completeTask + " Complete", this.pendingTask + " Pending", this.overdueTask + " Overdue"
        ],
        datasets: [
          {
            data: this.data1,
            backgroundColor: [
              '#A5A5A5',
              '#6192D7',
              '#FEC900',],
            fill: false
          },
        ],
        // labels:[]
      },
      options: {
        plugins: {
          labels: false,
        },
        circumference: Math.PI,
        rotation: 1.0 * Math.PI,
        responsive: true,
        legend: {
          position: 'bottom', labels: {
            usePointStyle: true,

          }
        },
        tooltips: {
          enabled: true
        }
      }
    });
  }

  ngOnInit() {
    this.chartData1()
    this.getData = 'All Machines'
    this.getCustomerData();
    this.getServiceData();
    // this.dashBoardSplineChartDropdownData();
    // this.dashboardCustomersplineChartData();
    this.assetList();
    this.userId = localStorage.getItem("userId");
    this.authService.getDashboardData(this.userId, null, null).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status == 'success') {
          this.dashboardData = res.data;

          this.completeTask = this.dashboardData.completeTask;
          this.overdueTask = this.dashboardData.overdueTask;
          this.incompleteTask = this.dashboardData.incompleteTask;
          this.pendingTask = this.dashboardData.pendingTask;
          this.activeAsset = this.dashboardData.activeAsset;
          this.blockAsset = this.dashboardData.blockAsset;
          this.totalAsset = this.dashboardData.totalAssets;
          this.totalStaff = this.dashboardData.totalStaff;
          this.totalTask = this.dashboardData.totalTask;
          this.staffName = this.dashboardData.staffName;
          this.staffTaskCount = this.dashboardData.staffTask;
          //  / // this.assetName = this.dashboardData.assetName;
          this.assetTaskCount = this.dashboardData.assetTaskCount;
          this.freeStaffMember = this.dashboardData.freeStaffMember;
          this.workingStaffMember = this.dashboardData.workingStaffMember;
          this.chart1.destroy()
          this.data1 = [this.incompleteTask, this.completeTask, this.pendingTask, this.overdueTask]
          this.chartData1()
          this.donoughtCharts();
          console.log("compl", this.staffName, this.staffTaskCount);
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });



        }
        // if(res && res.status == 'failed'){
        //   this.chartShow = true;
        // }
        // if(res.status=='sessionExpired'){
        //   this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 }); 
        //   this.router.navigate(['login']);
        // }
        else {
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
    this.addDateFilterForm = this.fb.group({
      CurrentDate: [moment(), Validators.required]

    })
    this.getCount();

  }
  reset() {

    this.start = null;
    this.end = null;
  }
  // get account graph data
  getCustomerData() {
    this.userId = localStorage.getItem("userId");
    this.authService.getDashboardCustomerData(this.userId, null, null).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {
          this.dashboardCustomerData = res.data;
          // 
          // this.status = res.status;

          this.barChart();
          console.log('this.dashboard', this.dashboardCustomerData.pendingTask)
          // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );

  }
  //get service engineer graph data
  getServiceData() {
    this.userId = localStorage.getItem("userId");
    this.authService.getDashboardServiceData(this.userId).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status == 'success') {
          this.dashboardServiceData = res.data;
          // this.status = res.status;

          console.log('updated data', this.dashboardServiceData)
          console.log('this.dashboardServiceData.serviceEngName', this.dashboardServiceData.serviceEngName);

          // this.barChart();
          this.stackChart();
          // console.log('this.dashboard dataaaaa', this.dashboardServiceData.serviceEngName)
          // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }
  // filterDate() {

  //   this.userId = localStorage.getItem("userId");
  //   this.noOfDates = 1;
  //   this.filterData = {
  //     userId: this.userId,
  //     noOfDates: this.noOfDates,
  //     filteredDate: this.dateValue
  //   }

  //   this.authService.dashboardServiceFilterDate(this.filterData).subscribe(
  //     (res: any) => {
  //       console.log(res);
  //       if (res && res.status == 'success') {
  //         this.dashboardServiceData = res.data;
  //         // this.status = res.status;

  //         // this.barChart();
  //         this.stackChart();
  //         console.log('updated data', this.dashboardServiceData)
  //         this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

  //       }
  //       if(res && res.status == 'failed'){
  //         this.chartShow = true;
  //       }
  //       else {
  //         this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //       }
  //     },
  //     (error: any) => {
  //       this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
  //     }
  //   );



  // }
  //filter date range
  filterRangeDate() {
    this.userId = localStorage.getItem("userId");
    this.noOfDates = 2;
    this.filterData = {
      userId: this.userId,
      from: this.from,
      to: this.to,
      type: 'byDate'
    }


    this.authService.dashboardServiceFilterDate(this.filterData).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status == 'success') {
          this.dashboardServiceData = res.data;
          console.log('this.dashboardServiceData.serviceEngName', this.dashboardServiceData.serviceEngName);
          this.stackChart();
          // this.barChart();
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });

        }
        else {
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );



  }

  // account grapg
  barChart() {
    this.barChartReady = true;
    console.log('#333', this.dashboardCustomerData.assetName.length);
    this.customerLength = this.dashboardCustomerData.assetName.length;

    this.barChartOptions = {
      responsive: true,
      plugins: {
        labels: false,
      },
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,

        }
      }
    };
    this.barChartLabels = this.dashboardCustomerData.assetName;
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartPlugins = [zoomPlugin];

    this.barChartData = [
      { data: this.dashboardCustomerData.pendingTask, label: 'Pending', stack: 'a' },
      { data: this.dashboardCustomerData.completTask, label: 'Complete', stack: 'a' },
      { data: this.dashboardCustomerData.incompleteTask, label: 'Incomplete', stack: 'a' },
      { data: this.dashboardCustomerData.overdue, label: 'Overdue', stack: 'a' },

    ];

    this.barChartColors = [

      // #12CBC4  #1289A7  #9980FA  #5758BB  #006266
      // { backgroundColor: '#99CCFC' },
      // { backgroundColor: '#DADF6D' },
      // { backgroundColor: '#CCCCCC' },
      // { backgroundColor:  '#FCE3E3' }
      // green color #97C266 yellow #E7D336 orange #F45E27 red #CA361D
      // 0f3755 dark blue
      // c90005 red
      // fe5b00 orange
      // e7d32a yellow
      // 97c266 green

      // '#A5A5A5',
      // '#6192D7',
      // '#FEC900',
      // F2791E
      { backgroundColor: '#FEC900' },
      { backgroundColor: '#6192D7' },
      { backgroundColor: '#A5A5A5' },
      { backgroundColor: '#F2791E' }
      //  { backgroundColor: '#e7d32a' },
      // { backgroundColor: '#97c266' },
      // { backgroundColor: '#c90005' },
      // { backgroundColor:  '#fe5b00' }
      // "#E95B54","#4AAF85","#FBCE4A"
    ]
  }
  // service engineer graph
  stackChart() {
    //Machine
    this.barChartReady = true;
    this.barChartOptions1 = {
      responsive: true,
      plugins: {
        labels: false,
      },

      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,

        }
      }
    };
    //  let barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    this.barChartLabelsName = this.dashboardServiceData.serviceEngName;
    this.barChartType1 = 'bar';
    this.barChartLegend1 = true;
    this.barChartPlugins1 = [];
    this.barChartData1 = [
      { data: this.dashboardServiceData.serviceEngIncomplete, label: 'Incomplete', stack: 'a' },
      { data: this.dashboardServiceData.serviceEngPending, label: 'Pending', stack: 'a' },
      { data: this.dashboardServiceData.serviceEngComplete, label: 'Complete', stack: 'a' },
      { data: this.dashboardServiceData.staffOverdue, label: 'Overdue', stack: 'a' },

    ];



    this.barChartColors1 = [
      // #12CBC4  #1289A7  #9980FA  #5758BB  #006266
      // { backgroundColor: '#CCCCCC' },
      // { backgroundColor: '#99CCFC' },
      // { backgroundColor: '#DADF6D' },
      // { backgroundColor: '#FCE3E3' }
      // green color #97C266 yellow #E7D336 orange #F45E27 red #CA361D
      //       008fbd blue
      // 0f3755 dark blue
      // c90005 red
      // fe5b00 orange
      // e7d32a yellow
      // 97c266 green
      // A5A5A5
      // FEC900
      // 545EC6
      { backgroundColor: '#A5A5A5' },
      { backgroundColor: '#FEC900' },
      { backgroundColor: '#6192D7' },
      { backgroundColor: '#F2791E' }
      // #12CBC4  #1289A7  #9980FA  #5758BB  #006266
      // { backgroundColor: '#c90005' },
      // { backgroundColor: '#e7d32a' },
      // { backgroundColor: '#97c266' },
      // { backgroundColor: '#fe5b00' }
    ]
  }


  // get donought chart data
  donoughtCharts() {
    this.barChartReady = true;
    Chart.defaults.doughnutLabels = Chart.helpers.clone(Chart.defaults.doughnut);
    var helpers = Chart.helpers;
    var defaults = Chart.defaults;

    Chart.controllers.doughnutLabels = Chart.controllers.doughnut.extend({
      updateElement: function (arc, index, reset) {
        var _this = this;
        var chart = _this.chart,
          chartArea = chart.chartArea,
          opts = chart.options,
          animationOpts = opts.animation,
          arcOpts = opts.elements.arc,
          centerX = (chartArea.left + chartArea.right) / 2,
          centerY = (chartArea.top + chartArea.bottom) / 2,
          startAngle = opts.rotation, // non reset case handled later
          endAngle = opts.rotation, // non reset case handled later
          dataset = _this.getDataset(),
          circumference = reset && animationOpts.animateRotate ? 0 : arc.hidden ? 0 : _this.calculateCircumference(dataset.data[index]) * (opts.circumference / (2.0 * Math.PI)),
          innerRadius = reset && animationOpts.animateScale ? 0 : _this.innerRadius,
          outerRadius = reset && animationOpts.animateScale ? 0 : _this.outerRadius,
          custom = arc.custom || {},
          valueAtIndexOrDefault = helpers.getValueAtIndexOrDefault;

        helpers.extend(arc, {
          // Utility
          _datasetIndex: _this.index,
          _index: index,

          // Desired view properties
          _model: {
            x: centerX + chart.offsetX,
            y: centerY + chart.offsetY,
            startAngle: startAngle,
            endAngle: endAngle,
            circumference: circumference,
            outerRadius: outerRadius,
            innerRadius: innerRadius,
            label: valueAtIndexOrDefault(dataset.label, index, chart.data.labels[index])
          },

          draw: function () {
            var ctx = this._chart.ctx,
              vm = this._view,
              sA = vm.startAngle,
              eA = vm.endAngle,
              opts = this._chart.config.options;

            var labelPos = this.tooltipPosition();
            var segmentLabel = vm.circumference / opts.circumference * 100;

            ctx.beginPath();

            ctx.arc(vm.x, vm.y, vm.outerRadius, sA, eA);
            ctx.arc(vm.x, vm.y, vm.innerRadius, eA, sA, true);

            ctx.closePath();
            ctx.strokeStyle = vm.borderColor;
            ctx.lineWidth = vm.borderWidth;

            ctx.fillStyle = vm.backgroundColor;

            ctx.fill();
            ctx.lineJoin = 'bevel';

            if (vm.borderWidth) {
              ctx.stroke();
            }

            if (vm.circumference > 0.0015) { // Trying to hide label when it doesn't fit in segment
              ctx.beginPath();
              ctx.font = helpers.fontString(opts.defaultFontSize, opts.defaultFontStyle, opts.defaultFontFamily);
              ctx.fillStyle = "#190707";
              ctx.textBaseline = "top";
              ctx.textAlign = "center";

              // Round percentage in a way that it always adds up to 100%
              // ctx.fillText(segmentLabel.toFixed(2) + "%", labelPos.x, labelPos.y);


            }
            // //display in the center the total sum of all segments
            var total = dataset.data.reduce((sum, val) => sum + val, 0);
            ctx.fillText('Total = ' + total, vm.x, vm.y - 10, 200);
          }
        });

        var model = arc._model;
        model.backgroundColor = custom.backgroundColor ? custom.backgroundColor : valueAtIndexOrDefault(dataset.backgroundColor, index, arcOpts.backgroundColor);
        model.hoverBackgroundColor = custom.hoverBackgroundColor ? custom.hoverBackgroundColor : valueAtIndexOrDefault(dataset.hoverBackgroundColor, index, arcOpts.hoverBackgroundColor);
        model.borderWidth = custom.borderWidth ? custom.borderWidth : valueAtIndexOrDefault(dataset.borderWidth, index, arcOpts.borderWidth);
        model.borderColor = custom.borderColor ? custom.borderColor : valueAtIndexOrDefault(dataset.borderColor, index, arcOpts.borderColor);

        // Set correct angles if not resetting
        if (!reset || !animationOpts.animateRotate) {
          if (index === 0) {
            model.startAngle = opts.rotation;
          } else {
            model.startAngle = _this.getMeta().data[index - 1]._model.endAngle;
          }

          model.endAngle = model.startAngle + model.circumference;
        }

        arc.pivot();
      }
    });

    /** task Status % donut */
    var config2 = {

      type: 'doughnutLabels',
      data: {
        datasets: [{
          data: [
            this.incompleteTask, this.completeTask, this.pendingTask, this.overdueTask
          ],
          backgroundColor: [
            // "#077fdb","green","red",

            // "#E8003E", "#57B854", "#FFC112"
            // "#E8003E" , "#8ABB28" , "#F6BB34"
            // green, blue,pink
            // #12CBC4  #1289A7  #9980FA  #5758BB  #006266
            // green color #97C266 yellow #E7D336 orange #F45E27 red #CA361D
            //       008fbd blue
            // 0f3755 dark blue
            // c90005 red
            // fe5b00 orange
            // e7d32a yellow
            // 97c266 green
            // '#12CBC4',  '#1289A7','#5758BB'
            //       [12:25 PM, 9/23/2020] Parikshit: 6192D7
            // [12:25 PM, 9/23/2020] Parikshit: Blu
            // [12:26 PM, 9/23/2020] Parikshit: F2791E
            // [12:26 PM, 9/23/2020] Parikshit: Orange
            // [12:26 PM, 9/23/2020] Parikshit: A5A5A5
            // [12:26 PM, 9/23/2020] Parikshit: Grey
            // [12:26 PM, 9/23/2020] Parikshit: FEC900
            // [12:27 PM, 9/23/2020] Parikshit: Light orange
            // [12:27 PM, 9/23/2020] Parikshit: 545EC6

            '#A5A5A5',
            '#6192D7',
            '#FEC900',

            // "#c90005" , "#97c266" , "#e7d32a"
            // "#CCCCCC", "#DADF6C", "#99CCFC"
          ],
          label: 'Dataset 2'
        }],

        labels: [
          this.incompleteTask + " Incomplete", this.completeTask + " Complete", this.pendingTask + " Pending", this.overdueTask + " Overdue"
        ]
      },
      options: {
        circumference: Math.PI,
        rotation: 1.0 * Math.PI,
        responsive: true,
        // plugins: {
        //   labels: false,
        // },
        legend: {
          position: 'bottom', labels: {
            usePointStyle: true,

          }
        },
        title: { display: true, text: '' },
        animation: { animateScale: true, animateRotate: true }
      }
    };

    var ctx = document.getElementById("myChart2");
    new Chart2(ctx, config2);

    /** Asset status % donut */
    // var config3 = {
    //   type: 'doughnutLabels',
    //   data: {
    //     datasets: [{
    //       data: [
    //         this.activeAsset, this.blockAsset
    //       ],
    //       backgroundColor: [
    //         "#077fdb","red"
    //       ],
    //       label: 'Dataset 3'
    //     }],
    //     labels: [
    //       this.activeAsset+" Active Asset", this.blockAsset+" Block Asset"
    //     ]
    //   },
    //   options: {
    //       circumference: Math.PI,
    //       rotation: 1.0 * Math.PI,
    //       responsive: true,
    //       legend: { position: 'bottom',},
    //     //  title: { display: true, text: 'Status ( % )' },
    //       animation: { animateScale: true, animateRotate: true }
    //     }
    // };

    // var ctx = document.getElementById("myChart3");
    // new Chart(ctx, config3);

    /** staff % donut */
    // var config4 = {
    //   type: 'doughnutLabels',
    //   data: {
    //     datasets: [{
    //       data: [
    //         this.workingStaffMember, this.freeStaffMember
    //       ],
    //       backgroundColor: [
    //         "green","#077fdb"
    //       ],
    //       label: 'Dataset 4'
    //     }],
    //     labels: [
    //       this.workingStaffMember +" Assigned Staff", this.freeStaffMember+" Unassigned Staff"
    //     ]
    //   },
    //   options: {
    //       circumference: Math.PI,
    //       rotation: 1.0 * Math.PI,
    //       responsive: true,
    //       legend: { position: 'bottom',},
    //       //title: { display: true, text: 'Staff ( % )' },
    //       animation: { animateScale: true, animateRotate: true }
    //     }
    // };
    // var ctx = document.getElementById("myChart4");
    // new Chart(ctx, config4);
  }
  // concentration after line chart 
  splineChart() {
    this.chartReady = true;
    this.oilChart = false;
    this.lineChartData = [
      { data: this.splineChartData.constrationAfter, label: 'Concentration After (%)' },
      // { data: this.splineChartData.phValue, label: 'Ph Value' },
    ];
    this.lineChartLabels = this.splineChartData.date;
    this.lineChartOptions = {
      responsive: true,
      plugins: {
        labels: false,
      },

      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }
    };
    this.lineChartColors = [
      {
        borderColor: 'pink',
        backgroundColor: 'transparent',
      },
      // {
      //   borderColor: 'blue',
      //   backgroundColor: 'transparent',
      // },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [zoomPlugin];
    //ph Value
    this.lineChartData2 = [
      // { data: this.splineChartData.constrationAfter, label: 'Concentration After (%)' },
      { data: this.splineChartData.phValue, label: 'pH Value' },
    ];
    this.lineChartLabels = this.splineChartData.date;
    this.lineChartOptions = {
      responsive: true,


      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }

    };
    this.lineChartphColors = [

      {
        borderColor: 'blue',
        backgroundColor: 'transparent',
      },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [zoomPlugin];
    //second chart

    this.lineChartData1 = [
      { data: this.splineChartData.oilQuantity, label: 'Total Oil Consumption (L)' },
      // { data: this.topup4, label: 'Water Top Up' },
    ];
    this.lineChartLabels = this.splineChartData.date;
    this.lineChartOptions = {
      responsive: true,
      legend: {
        position: 'top',

        labels: {
          boxWidth: 7,
          usePointStyle: true,

        }
      }
    };
    this.lineChartColors = [
      {
        borderColor: 'pink',
        backgroundColor: 'transparent',
      },
      {
        borderColor: 'blue',
        backgroundColor: 'transparent',
      },
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [zoomPlugin];
  }

  // oil consumption chart
  oilChartData() {
    this.oilChart = true;
    this.chartReady = false;

    this.barChartOptions = {
      responsive: true,
      plugins: {
        labels: false,
      },
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: false,



            // maximum value
          },
        }],
        yAxes: [{

          display: true,
          stacked: true,
          ticks: {
            autoSkip: false,
            // maximum value
          },
        }]
      },

      legend: {
        position: 'top',

        labels: {

          usePointStyle: true,

        }
      }
    };

    //  let barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    console.log('length ', this.splineChartData.machineName.length)
    if (this.splineChartData.machineName.length > 30) {
      this.increaseWidthChart = true;
    }
    this.barChartLabels1 = this.splineChartData.machineName;
    this.barChartType2 = 'bar';
    this.barChartLegend2 = true;
    this.barChartPlugins = [zoomPlugin];
    this.barChartData2 = [
      { data: this.splineChartData.oilQuantity, label: 'Total Oil Consumptions (L) ' },

    ];


    this.barChartColors3 = [
      // { backgroundColor: '#8AFFB9' },
      { backgroundColor: '#178FBD' },
    ]
  }


  // onDate(event): void {
  //   this.dateValue = event.value;
  //   console.log('dateselected', this.dateValue)
  //   this.filterDate();
  // }
  // get data by date range
  onServiceDateChange(event): void {
    // this.value = event.value;
    console.log('dateRangeselected', event.value)
    this.start1 = event.value[0];
    this.end1 = event.value[1];
    this.filterRangeDate();

  }
  // data by date range
  onDateRangeChange(event): void {
    console.log('dateRange', event.value)

    if (event.value !== null) {
      this.from = event.value[0];
      this.to = event.value[1];
    } else {
      this.from = null,
        this.to = null
    }
    console.log('============>')
    console.log(this.from);
    console.log(this.to)
    if (this.customerAssetId && !this.machineUniqueCode) {
      this.accountDataByRange();
    }

    if (this.customerAssetId && this.machineUniqueCode) {
      this.machineDataByRange();
      this.dashboardTrendCsvBYDate();
    }


  }
  // data of machines by date
  machineDataByRange() {
    this.userId = localStorage.getItem("userId");
    this.filterData = {
      userId: this.userId,
      from: this.from,
      to: this.to,
      assetId: this.customerAssetId,
      uniqueCode: this.machineUniqueCode,
      type: 'machineDate'
    }
    this.authService.dashboardSplineChartData(null, null, this.filterData, 'machineDate', null).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          if (res.data.oilQuantity.length != 0) {
            let allAditionData = res.data.oilQuantity.reduce(reducer)
            this.totalOilConsumptionSecond = allAditionData.toFixed(2)
          } else {
            this.totalOilConsumptionSecond = 0;
          }
          console.log('response', res.data);
          this.splineChartData = res.data;
          this.chartShow = false;
          // this.status = res.status;

          this.splineChart();


          // this.oilChartData();

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );

  }
  // data of account by date
  accountDataByRange() {

    this.filterData = {
      userId: this.userId,
      from: this.from,
      to: this.to,
      assetId: this.customerAssetId,
      type: 'accountDate'
    }


    this.authService.dashboardSplineChartData(this.customerAssetId, null, this.filterData, 'accountDate', null).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {
          console.log('response', res.data);
          this.splineChartData = res.data;
          this.chartShow = false;
          // this.status = res.status;

          this.oilChartData();

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }

  // dashBoardSplineChartDropdownData() {
  //   this.authService.dashboardDropDownData().subscribe(
  //     (res: any) => {
  //       console.log(res);

  //       if (res && res.status == 'success') {

  //         this.customerAccountName = res.data;
  //         // console.log('this accountName', this.customerAccountName)
  //         // this.customerAssetId = res.data[0].uniqueCode;
  //         // this.machineName = res.data[0].machineModel
  //         console.log('idddd', this.customerAssetId)
  //         this.dashboardCustomersplineChartData();

  //         // this.dashboardCustomersplineChartData();
  //         console.log('this.customerAssetIddd', this.customerAssetId)
  //         // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

  //       }
  //       else {
  //         this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //       }
  //     },
  //     (error: any) => {
  //       this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
  //     }
  //   );
  // }
  // dropdown of machines
  changeAsset(event) {
    this.buttonShow = false;
    console.log('event', event);
    console.log('event', event.target.value)
    this.customerAssetId = event.target.value;
    this.dashboardCustomersplineChartData();

    for (let data of this.assetsList) {
      if (this.customerAssetId == data.assetId) {
        this.accountName = data.accountName;
        this.machineList = data.parts;
        console.log('this.machineListttt', this.machineList)
      }
      // let name= this.assetsList.filter((data) => data.id ==event.target.value)[0].accountName;
      // console.log(name);

      // if(data[0].assetId == data.assetId){
      //   this.machinefirstPartList = data[0].parts;
      // }
    }
    for (let data of this.machineList) {
      console.log('data', data['partId']);
      if (this.machineUniqueCode == data['uniqueCode']) {
        this.machineMakeModel = data['machineModel'];
        this.machineCode = data['uniqueCode']

      }
    }
  }
  // set value in drop down for first time
  newchangeAsset(assetId) {
    this.buttonShow = false;

    this.customerAssetId = assetId;
    this.dashboardCustomersplineChartData();

    for (let data of this.assetsList) {
      if (this.customerAssetId == data.assetId) {
        this.accountName = data.accountName;
        this.machineList = data.parts;
        console.log('this.machineListttt', this.machineList)
      }

    }

  }
  // dropdown of machine value
  changeMachine(event) {
    console.log('event', event.target.value)

    this.machineUniqueCode = event.target.value;

    if (this.machineUniqueCode == 'All Machines') {
      this.dashboardCustomersplineChartData();
      this.buttonShow = false;

    }

    else {
      this.dashboardTrendChartByAccount();
    }
    for (let data of this.machineList) {
      console.log('data', data['partId']);
      if (this.machineUniqueCode == data['uniqueCode']) {
        this.machineMakeModel = data['machineModel'];
        this.machineCode = data['uniqueCode']

      }

    }


  }
  getData;
  // get data by account
  dashboardCustomersplineChartData() {
    let type;
    let data = {
      from: this.from,
      to: this.to,
    }
    if (this.from == null && this.to == null) {
      type = 'byAccount'
    } else {
      type = 'accountDate'
    }
    this.authService.dashboardSplineChartData(this.customerAssetId, null, data, type, null).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {


          this.chartShow = false;
          this.getData = 'All Machines'
          this.csvButtonShow = false;
          console.log('response', res.data);
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          let oilcumsptiontofixed = res.data.oilQuantity.reduce(reducer)
          this.totalOilConsumption = oilcumsptiontofixed.toFixed(2)
          console.log('response oil', this.totalOilConsumption);
          this.splineChartData = res.data;
          console.log('thissss', this.splineChartData.machineName)
          // this.status = res.status;

          this.oilChartData();
          // this.splineChart();
          // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }
  // selected by machine
  dashboardTrendChartByAccount() {
    let type;
    let data = {
      from: this.from,
      to: this.to
    }
    if (this.from == null && this.to == null) {
      type = 'byMachine'
    } else {
      type = 'csvByDate'
    }
    this.authService.dashboardSplineChartData(this.customerAssetId, this.machineUniqueCode, data, type, this.userId).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {
          console.log('response', res.data);
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          if (res.data.oilQuantity.length != 0) {
            let allAditionData = res.data.oilQuantity.reduce(reducer)
            this.totalOilConsumptionSecond = allAditionData.toFixed(2)
          } else {
            this.totalOilConsumptionSecond = 0;
          }
          this.splineChartData = res.data;
          console.log('fff', this.splineChartData.machineName)
          this.chartShow = false;
          this.buttonShow = true;
          console.log(this.buttonShow);

          this.csvButtonShow = false;
          console.log('this.trendData.date')
          this.splineChart();

          this.dashboardTrendCsvBYMachine();
          if (this.from != null && this.to != null) {
            this.machineDataByRange()
          }
          // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
          this.buttonShow = false;
          this.csvButtonShow = false;
        }

        else {
          this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );

  }
  // get data of csv by date
  dashboardTrendCsvBYMachine() {
    console.log('============this methid is called =================')
    let type = 'csvNoDate';

    if (type === 'csvNoDate') {
      this.authService.dashboardSplineChartData(this.customerAssetId, this.machineUniqueCode, null, type, null).subscribe(
        (res: any) => {
          console.log(res);

          if (res && res.status == 'success') {
            this.buttonShow = true;
            this.csvButtonShow = false;
            console.log('response', res.data);
            this.splineCsvData = res.data;
            for (let item of this.splineCsvData.csvData) {
              delete item.partId;
              delete item.syncStatus;
              delete item.taskId;
              delete item.uniqu_key;
              delete item.assetId;
              delete item.other_observations;
              this.setCsvData = {
                date: item.date,
                accountName: item.accountName,
                machine_name: item.machine_name,
                machine_unique_Code: item.machine_unique_Code,
                concentration_before: item.concentration_before,
                concentration_after: item.concentration_after,
                oil_top_up_quantity: item.oil_top_up_quantity,
                ph_before_top_up: item.ph_before_top_up,
                tramp_oil: item.tramp_oil,
                water_top_up_quantity: item.water_top_up_quantity

              }
              this.finalcsvData.push(this.setCsvData);

            }
            //   // this.splineCsvData.filter(value=> value.machineName && value.concentration_after);

            console.log('this/spline', this.finalcsvData)
            // this.snackBar.open(res.message, '', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });
          }
          if (res && res.status == 'failed') {
            this.buttonShow = false;
          }
          else {
            // this.snackBar.open(res.message, '', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });
          }
        },
        (error: any) => {
          this.snackBar.open("Some error occured due to server or internet connection!", '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
        }
      );
    }
  }
  // get trends of csv by date 
  dashboardTrendCsvBYDate() {
    this.userId = localStorage.getItem("userId");
    let data = {
      from: this.from,
      to: this.to,
    }
    let type = 'csvByDate'
    if (type === 'csvByDate') {
      this.authService.dashboardSplineChartData(this.customerAssetId, this.machineUniqueCode, data, type, this.userId).subscribe(
        (res: any) => {
          console.log(res);

          if (res && res.status == 'success') {
            this.buttonShow = false;
            this.csvButtonShow = true;
            console.log('response', res.data);
            this.splineCsvData = res.data;
            for (let item of this.splineCsvData.csvData) {
              delete item.partId;
              delete item.syncStatus;
              delete item.taskId;
              delete item.uniqu_key;
              delete item.assetId;
              delete item.other_observations;
              this.setCsvData = {
                date: item.date,
                accountName: item.accountName,
                machine_name: item.machine_name,
                machine_unique_Code: item.machine_unique_Code,
                concentration_before: item.concentration_before,
                concentration_after: item.concentration_after,
                oil_top_up_quantity: item.oil_top_up_quantity,
                ph_before_top_up: item.ph_before_top_up,
                tramp_oil: item.tramp_oil,
                water_top_up_quantity: item.water_top_up_quantity

              }
              this.finalcsvByData.push(this.setCsvData);

            }
            // this.snackBar.open(res.message,'', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });
          }
          if (res && res.status == 'failed') {

            this.buttonShow = false;
            this.csvButtonShow = false;
          }
          else {
            // this.snackBar.open(res.message,'', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });
          }
        },
        (error: any) => {
          this.snackBar.open("Some error occured due to server or internet connection!", '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
        }
      );
    }
  }




  // get asset list
  assetList() {
    console.log("asset list");
    this.authService.assetList(this.userId).subscribe((res: any) => {
      if (res.status == "success") {
        console.log("assets list response is ", res); // to show assets list
        this.assetsList = res.data;
        this.customerAssetId = res.data[0].assetId;
        this.newchangeAsset(this.customerAssetId);
        this.dashboardCustomersplineChartData();
        console.log('this.assests', this.assetsList)
      }
      else {
        // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      })
  }
  // dowload csv 
  downloadCSV() {
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    console.log('csvvvv', this.finalcsvData)
    new AngularCsv(this.finalcsvData, 'Dashboard Data', this.csvOptions);

  }
  // download csv by date
  downloadCSVDate() {
    console.log('csdd', this.finalcsvByData)
    new AngularCsv(this.finalcsvByData, 'Dashboard Data', this.csvOptions);
  }
  // get task by date range
  taskByDateRange(event) {

    console.log('dateRangeselected', event.value)
    this.from = event.value[0];
    this.to = event.value[1];
    console.log('start', this.start);
    console.log('end', this.end);
    this.getStatusByDate();
  }
  // get customer by date range
  customerDateChange(event) {

    console.log('dateRangeselected', event.value)
    this.from = event.value[0];
    this.to = event.value[1];
    console.log('start', this.start);
    console.log('end', this.end);
    this.getCustomerRecordByDate()
  }

  getStatusByDate() {
    let rangeBody = {
      userId: this.userId,
      from: this.from,
      to: this.to,
      type: 'byDate'
    }
    this.authService.getDashboardData(null, 'byDate', rangeBody).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status == 'success') {
          this.dashboardData = res.data;
          this.completeTask = this.dashboardData.completeTask;
          this.incompleteTask = this.dashboardData.incompleteTask;

          this.pendingTask = this.dashboardData.pendingTask;
          this.activeAsset = this.dashboardData.activeAsset;
          this.blockAsset = this.dashboardData.blockAsset;
          this.totalAsset = this.dashboardData.totalAssets;
          this.totalStaff = this.dashboardData.totalStaff;
          this.totalTask = this.dashboardData.totalTask;
          this.staffName = this.dashboardData.staffName;
          this.staffTaskCount = this.dashboardData.staffTask;
          //  / // this.assetName = this.dashboardData.assetName;
          this.assetTaskCount = this.dashboardData.assetTaskCount;
          this.freeStaffMember = this.dashboardData.freeStaffMember;
          this.workingStaffMember = this.dashboardData.workingStaffMember;
          console.log("compl", this.staffName, this.staffTaskCount);
          // this.snackBar.open(res.message, '', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });;
          this.chart1.destroy()
          this.data1 = [this.incompleteTask, this.completeTask, this.pendingTask, this.overdueTask]
          this.chartData1()
          this.donoughtCharts();
        }

        else {
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }
  getCustomerRecordByDate() {
    let rangeBody = {
      userId: this.userId,
      from: this.from,
      to: this.to,
      type: 'byDate'
    }
    this.authService.getDashboardCustomerData(null, 'byDate', rangeBody).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status == 'success') {
          this.dashboardCustomerData = res.data;
          // 
          // this.status = res.status;

          this.barChart();

          console.log('this.dashboard', this.dashboardCustomerData.pendingTask)
          // this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });

        }
        if (res && res.status == 'failed') {
          this.chartShow = true;
        }
        else {
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }

  openDateRange() {

    this.showDiv = true;
    jQuery.noConflict();
    $('#rangeModal').modal('show');
    console.log(this.showDiv);

  }
  closeDateRange() {
    this.showDiv = false;
    console.log(this.showDiv);
  }

  allDates(event) {
    // console.log(event.text.length)
    if (event.value == null) {
      this.reset()
    }
    if (event.value !== null) {
      this.from = event.value[0];
      this.to = event.value[1];
    } else {
      this.from = null,
        this.to = null
    }

    if (this.from == null && this.to == null) {
      console.log('condition section');
      this.getCustomerData()
      this.getServiceData();

      this.assetList();
      this.userId = localStorage.getItem("userId");
      this.authService.getDashboardData(this.userId, null, null).subscribe(
        (res: any) => {
          console.log(res);
          if (res && res.status == 'success') {
            this.dashboardData = res.data;

            this.completeTask = this.dashboardData.completeTask;
            this.incompleteTask = this.dashboardData.incompleteTask;
            this.pendingTask = this.dashboardData.pendingTask;
            this.activeAsset = this.dashboardData.activeAsset;
            this.blockAsset = this.dashboardData.blockAsset;
            this.totalAsset = this.dashboardData.totalAssets;
            this.totalStaff = this.dashboardData.totalStaff;
            this.totalTask = this.dashboardData.totalTask;
            this.staffName = this.dashboardData.staffName;
            this.staffTaskCount = this.dashboardData.staffTask;
            //  / // this.assetName = this.dashboardData.assetName;
            this.assetTaskCount = this.dashboardData.assetTaskCount;
            this.freeStaffMember = this.dashboardData.freeStaffMember;
            this.workingStaffMember = this.dashboardData.workingStaffMember;
            this.chart1.destroy()
            this.data1 = [this.incompleteTask, this.completeTask, this.pendingTask, this.overdueTask]
            this.chartData1()
            this.donoughtCharts();
            console.log("compl", this.staffName, this.staffTaskCount);
            // this.snackBar.open(res.message,'', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });



          }
          // if(res && res.status == 'failed'){
          //   this.chartShow = true;
          // }
          // if(res.status=='sessionExpired'){
          //   this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 }); 
          //   this.router.navigate(['login']);
          // }
          else {
            // this.snackBar.open(res.message,'', {
            //   duration: 3000,
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            // });
          }
        },
        (error: any) => {
          this.snackBar.open("Some error occured due to server or internet connection!", '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
        }
      );
    } else {
      this.getCustomerRecordByDate();
      this.getStatusByDate();
      this.filterRangeDate();
      this.onDateRangeChange(event);
      this.dashboardCustomersplineChartData();
    }
    $('#rangeModal').modal('hide');
    this.showDiv = false;

    // if(this.machineUniqueCode == 'All Machines'){
    //   this.chartShow = false
    // }else{
    //   this.chartShow = true
    // }
  }
  // print(){
  //   var dataUrl = <HTMLCanvasElement>document.getElementById('#printchart').toDataURL(); //attempt to save base64 string to server using this var  
  //   var windowContent = '<!DOCTYPE html>';
  //   windowContent += '<html>'
  //   windowContent += '<head><title>Print canvas</title></head>';
  //   windowContent += '<body>'
  //   windowContent += '<img src="' + dataUrl + '">';
  //   windowContent += '</body>';
  //   windowContent += '</html>';
  //   var printWin = window.open('','','width=340,height=260');
  //   printWin.document.open();
  //   printWin.document.write(windowContent);
  //   printWin.document.close();
  //   printWin.focus();
  //   printWin.print();
  //   printWin.close();
  // }
  // open print graph modal
  openDialog(type: string) {

    this.dialog.open(PrintgraphComponent, {
      width: '900px',

      data: {
        type: type,
        date: this.splineChartData.date,
        concentrationAfter: this.splineChartData.constrationAfter,
        phValue: this.splineChartData.phValue,
        oilChartValue: this.splineChartData.oilQuantity,
        machineNameChart: this.splineChartData.machineName,
        oilValue: this.splineChartData.oilQuantity,
        account: this.accountName,
        startDate: this.from,
        endDate: this.to,
        modelName: this.machineMakeModel,
        code: this.machineCode


      }
    });
  }
  // get pie chart data
  getPieChart() {
    this.pieChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: [
          {
            render: 'label',
            position: 'outside'
          },
          {
            render: 'value',
            fontSize: 14,
            fontStyle: 'bold',
            fontColor: '#000',
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        ]
      },
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
        }
      }
    };
    this.pieChartLabels = this.dataName;
    this.pieChartData = this.graphCount;
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
    this.pieChartPlugins = [];
  }
  // get count of account, machines and task
  getCount() {
    this.authService.getCount(this.userId).subscribe((res: any) => {
      console.log(res);
      if (res && res.status == 'success') {
        console.log(res);
        this.dataName = res.data;
        this.graphCount = res.graph;
        this.getPieChart();
      }
      else {
        // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
      }
    },
      (error: any) => {
        this.snackBar.open("Some error occured due to server or internet connection!", '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 2000 });
      }
    );
  }

}









