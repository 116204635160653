import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-halfdonought',
  templateUrl: './halfdonought.component.html',
  styleUrls: ['./halfdonought.component.scss']
})
export class HalfdonoughtComponent implements OnInit {

  chart: any;
  data = [50, 60, 70]

  constructor(){
    
  }
  ngOnInit() {
    this.chartData()
  }

  chartData() {
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: ['Data 1', 'Data 2', 'Data 3'],
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              '#A5A5A5',
              '#6192D7',
              '#FEC900',],
            fill: false
          },
        ],
        // labels:[]
      },
      options: {
        circumference: Math.PI,
        rotation: 1.0 * Math.PI,
        responsive: true,
        legend: {
          position: 'bottom', labels: {
            usePointStyle: true,

          }
        },
        tooltips: {
          enabled: true
        }
      }
    });
  }
  setData() {
    this.chart.destroy()
    this.data = [40, 60, 90, 30]
    this.chartData()
  }
}