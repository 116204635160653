import { Component, OnInit } from '@angular/core';
import { CalenderService } from '../service/calender.service';
import { ChangeDetectionStrategy, ViewChild, TemplateRef, } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, } from 'date-fns';
import { Subject } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { AuthService } from '../service/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSort, MatTableDataSource } from '@angular/material';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  userId: number;
  data = { from: '', to: '', userId: null }
  eventData: any = []
  eventArray: any = []
  isLoad: boolean = true
  closeResult = '';
  particularTaskDetail: any;
  addCommentForm: FormGroup;
  commentForm: FormGroup;
  assetCount: number = 0;
  countOfWatchers: number = 0;
  previousComments: any;
  previousCommentsLength: number = 0;
  taskResult: any;
  value: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  errorMessage: string = "Some error occured due to server or internet connection!";
  showDiv: boolean = false;
  taskData: any = []

  @ViewChild('content') modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = []

  displayedColumns: string[] = ['taskName', 'accountName', 'assignName', 'dueDate', 'taskStatus', 'comment'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  constructor(private service: CalenderService, public snackBar: MatSnackBar, private fb: FormBuilder, private modal_nb: NgbModal, private authService: AuthService,) {
    this.addCommentForm = this.fb.group({
      taskName: ['', Validators.required],
      assignedTo: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getCalander()
  }
  
  getCalander(){
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.data.userId = +localStorage.getItem("userId");
    this.data.from = this.formatDate(firstDay)
    this.data.to = this.formatDate(lastDay)
    console.log(this.data)
    this.service.getCurrentMonthEvent(this.data).subscribe(
      res => {
        if (res.status == "success") {
          this.eventData = res.data
          this.eventData.forEach(element => {
            this.eventArray.push({
              'start': subDays((new Date(element.dueDate)), 0),
              'title': element.accountName,
              'userId': element.userId,
              'taskId': element.taskId,
              'taskData': element,
              color: colors.yellow,
              actions: this.actions,
            })
          });
          console.log(this.eventData)
          this.isLoad = true
          this.events = this.eventArray
        }
      },
      err => console.log(err)
    )
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  actions: CalendarEventAction[] = [

  ];

  deleteTaskCom(taskId, userid) {
    var result = confirm("Want to delete?");
    if (result) {
      this.events = []
      this.eventArray = []
      this.authService.deletetaskCom(taskId, userid).subscribe(
        res => {
          // this.showTaskList()
          this.getCalander()
          this.dataSource = new MatTableDataSource([]);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(res)
        },
        err => console.log(err)
      )//Logic to delete the item
    }

  }

  activeDayIsOpen: boolean = false;

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    this.taskData = []
    console.log(events)
    events.forEach(element => {
      this.taskData.push(element.taskData)
    });
    this.dataSource = new MatTableDataSource(this.taskData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.taskData)

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }


  open(event) {
    let taskDetail = {
      taskId: event.taskId,
      userId: event.userId,
    }
    this.authService.particularTaskComments(taskDetail).subscribe(
      (res: any) => {
        console.log("response ", res);
        if (res.status == "success") {
          this.particularTaskDetail = res.data[0];
          // console.log('parts',this.particularTaskDetail.accountName);

          let wholeDate = this.particularTaskDetail.dueDate;
          let a = wholeDate.split("T");
          let date = a[0];
          // console.log("date is ",date); 
          this.addCommentForm.setValue({
            taskName: this.particularTaskDetail.taskName,
            dueDate: date,
            assignedTo: this.particularTaskDetail.assignName,
            description: this.particularTaskDetail.description
          });
          this.assetCount = 1;
          this.countOfWatchers = this.particularTaskDetail.watchers.length;
          this.previousComments = this.particularTaskDetail.comments;
          console.log('previous comment', this.previousComments)
          this.previousCommentsLength = this.previousComments.length;
          this.taskResult = this.particularTaskDetail.taskResult;
          console.log("task ", this.taskResult);
        }
        else {
          console.log(res.message);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        // console.log('error is ',error);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )

    this.modal_nb.open(this.modalContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  closeOpenMonthViewDay() {
    this.events = []
    this.isLoad = false
    this.eventArray = []
    this.activeDayIsOpen = false;
    let y = this.viewDate.getFullYear();
    let m = this.viewDate.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.data.from = this.formatDate(firstDay)
    this.data.to = this.formatDate(lastDay)
    this.service.getCurrentMonthEvent(this.data).subscribe(
      res => {
        if (res.status == "success") {
          console.log(res);
          this.eventData = res.data
          this.eventData.forEach(element => {
            this.eventArray.push({
              'start': subDays((new Date(element.dueDate)), 0),
              'title': element.accountName,
              'userId': element.userId,
              'taskId': element.taskId,
              'taskData': element,
              color: colors.yellow,
              actions: this.actions,
            })
          });
          this.isLoad = true
          this.events = this.eventArray
        }
      },
      err => console.log(err)
    )
  }
  assetList() {
    jQuery.noConflict();
    $('#customerModal').modal('show');
    this.showDiv = true;

    console.log(' this.particularTaskDetail', this.particularTaskDetail)
    this.value = this.particularTaskDetail;
  }
  displayWatchersList() {
    jQuery.noConflict();
    $('#WatcherListModal').modal('show');
    this.showDiv = true;
    this.value = this.particularTaskDetail;
  }
  showValue: boolean = false;
  taskId: any;
  commentsData: any;
  commentId: any;
  employeeDetails(list: any) {
    this.addCommentForm.reset()
    this.showValue = false;
    this.particularTaskDetail = list
    // console.log('parts',this.particularTaskDetail.accountName);

    let wholeDate = this.particularTaskDetail.dueDate;
    let a = wholeDate.split("T");
    let date = a[0];
    // console.log("date is ",date); 
    this.addCommentForm.setValue({
      taskName: this.particularTaskDetail.taskName,
      dueDate: this.formatDate(date),
      assignedTo: this.particularTaskDetail.assignName,
      description: this.particularTaskDetail.description
    });
    this.assetCount = 1;
    this.countOfWatchers = this.particularTaskDetail.watchers.length;
    // this.previousComments = this.particularTaskDetail.comments;
    // console.log('previous comment', this.previousComments)
    // this.previousCommentsLength = this.previousComments.length;
    this.taskResult = this.particularTaskDetail.taskResult;
    console.log("task ", this.taskResult);

  }
}
