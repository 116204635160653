import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router} from '@angular/router';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  accessMark: boolean = false;
  appAccess = false;
  registerForm: FormGroup;
  termsMark: boolean = false;
  registerData: any;
  submitted = false;
  constructor(private fb: FormBuilder, public router:Router, private authService: AuthService, private flashMessage: FlashMessagesService) { 
   
  }

  ngOnInit() {
    this.registerForm= this.fb.group({
      name:['',Validators.required],
      companyName:['', Validators.required],
      email:['', [Validators.required, Validators.email]],
      phone:['', [ Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)]],
      accessApp:['', Validators.required],
      termsAndConditions:['', Validators.required]
    });
     /** jquery for form validation */
     (function() {
      'use strict';
      window.addEventListener('load', function() {
        // Get the forms we want to add validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
          form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          }, false);
        });
      }, false);
    })();
  }
  get f() { return this.registerForm.controls; }

  toggleAccess(e){
    this.accessMark= e.target.checked;
  }

  toggleTerms(e){
    this.termsMark = e.target.checked;
  }
  /** This function is used to submit the form */
  submit(){
     this.submitted= true;
    if(this.registerForm.invalid){
      // this.flashMessage.show("Please fill all the fields correctly and tick the checkbox!", { cssClass: 'alert-danger', timeout: 3000 });
      return;
    }
    
    /** this block is used to get input values of form fields */
    this.registerData={
      name: this.registerForm.value.name,
      companyName: this.registerForm.value.companyName,
      email: this.registerForm.value.email,
      phone: this.registerForm.value.phone
    }
    console.log("data is ", this.registerData);
    /** this block is used to call register function of authService */
    this.authService.register(this.registerData).subscribe((res:any) => {
      console.log("response is", res);
      if(res.status=='success'){
        this.registerForm.reset();
        this.submitted= false;
        this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 4000 });
        this.router.navigate(['login']);
      }
      else{
        this.submitted= false;
        console.log('login failed');
        this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 3000 });
      }
    },
    (error:any) => {
      this.flashMessage.show("Some error occured due to server or internet connection!", { cssClass: 'alert-danger', timeout: 3000 });
    })
  }
}
