import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { MustMatch } from '../helpers/matchPassword.validator';
import { FlashMessagesService } from 'angular2-flash-messages';
import { async } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
declare var $:any;
@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss']
})
export class VendorProfileComponent implements OnInit {
  vendorData: any;
  vendorProfileForm: FormGroup;
  editForm: FormGroup;
  changePassForm: FormGroup;
  changePassFormData: {};
  userId: any;
  mSubmitted = false;
  submitted = false;
  updatedData:any;
  vendor: any = {};
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  // image
  image:any;
  file : File;
   myReader:FileReader = new FileReader();
   avatarUrl:any;
   baseUrl: string = environment.base_url;
  @ViewChild('img') img: ElementRef;
  profileImage: any;

  constructor(private fb:FormBuilder, private authService: AuthService, private flashMessage: FlashMessagesService,public snackBar: MatSnackBar ) {
   

   
   }

  ngOnInit() {
    this.vendorData = JSON.parse(localStorage.getItem("vendorData"));
    this.vendorData.image= this.baseUrl+this.vendorData.image;
    console.log('this.vendor' , this.vendorData.image)
    //this.name= this.vendorData.name;
    console.log("data is ",this.vendorData)
    this.userId= localStorage.getItem('userId');
    this.vendor = {
      name: this.vendorData.name,
      phone: this.vendorData.phone,
      email: this.vendorData.email,
      companyName: this.vendorData.companyName,
      image : this.vendorData.image
    }
  
  
    // code used to set image from localStorage when app loaded
    if(this.vendorData.image){
      if(this.vendorData.image != ""){
        this.img.nativeElement.src =this.vendorData.image;
        console.log('imagee@@', this.img.nativeElement.src)
      }
      console.log('imageeee!!', this.img.nativeElement.src)
    }


     /** jquery for form validation */
     (function() {
        'use strict';
        window.addEventListener('load', function() {
          // Get the forms we want to add validation styles to
          var forms = document.getElementsByClassName('needs-validation');
          // Loop over them and prevent submission
          var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add('was-validated');
            }, false);
          });
        }, false);
      })();

      $('#upload-icon').click(function(){
        $('#myfile').click()
      })

      if(!this.vendor.image == undefined){
        console.log(this.vendor.image)
        this.img.nativeElement.src = this.vendor.image;
        console.log('imageeee', this.img.nativeElement.src)
      }
  }

   // convenience getter for easy access to form fields
   get f() { return this.changePassForm.controls; }

   onSubmit() {
     console.log("data ", this.vendor)
   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.vendor))
    this.updatedData = {
      userId: localStorage.getItem('userId'),
      name: this.vendor.name,
      phone: this.vendor.phone,
      companyName: this.vendor.companyName,
      image : this.profileImage[1]
    }
    console.log("updated data ", this.updatedData)
    this.authService.updateVendorProfile(this.updatedData).subscribe(
      (res:any) => {
        console.log(res);
        if(res.status=='success'){
          //$('input:text').attr("disabled", true).css("background-color", "");
          localStorage.setItem('vendorData', JSON.stringify(res.data));
        //  $("#editProfileModal").modal("hide");
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
       
 

           //to update the values of profile in screen view
        }
        else{
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        this.snackBar.open(error,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
      }
    )
  }
   /**function to prefill the edit form values */
   showData(){
    let vendorData = JSON.parse(localStorage.getItem("vendorData"));
     this.editForm.setValue({
       editName: vendorData.name,
       editPhone: vendorData.phone,
       editDepartment: vendorData.department,
       editRole: vendorData.role
     });
   }
   /** function to edit the profile data */
  profileEdit(){
    this.mSubmitted=true;
    if(this.editForm.invalid){
      return;
    }
    this.updatedData = {
          userId: localStorage.getItem('userId'),
          name: this.editForm.value.editName,
          phone: this.editForm.value.editPhone,
          department: this.editForm.value.editDepartment,
          role: this.editForm.value.editRole
    }
    console.log("data is " ,this.updatedData);
    this.authService.updateVendorProfile(this.updatedData).subscribe(
      (res:any) => {
        console.log(res);
        if(res.status=='success'){
          //$('input:text').attr("disabled", true).css("background-color", "");
          localStorage.setItem('vendorData', JSON.stringify(res.data));
          $("#editProfileModal").modal("hide");
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.constructor();  //to update the values of profile in screen view
        }
        else{
          this.snackBar.open(res.message,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
    this.snackBar.open(error,'', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
      }
    )
  }

  /** function to change/reset password */
  changePassword(){
    this.submitted = true;
      // stop here if form is invalid
    if (this.changePassForm.invalid) {
        return;
    }
   
     // if form is valid
    this.changePassFormData= {
      userId: this.userId,
      password: this.changePassForm.value.oldPass,
      newPassword: this.changePassForm.value.newPass 
    }
    this.authService.resetPassword(this.changePassFormData).subscribe((res:any) => {
      console.log(res);
      if (res.status=='success'){
        this.changePassForm.reset();
        jQuery.noConflict();
        $('#changePassModal').modal('hide');
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
      else{
        jQuery.noConflict();
        $('#changePassModal').modal('hide');
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        }); 
      }
    })
    
  }

  // Vendor Avatar image file select and convert to Base64
  fileUpload(e:any){
    console.log(e);

    
    this.file = e.target.files[0];
    console.log(' this.file' ,  this.file);
    var FR= new FileReader();
    FR.addEventListener("load", (e:any)=> {
      // document.getElementById("img").src       = e.target.result;
      this.image = e.target.result;
      this.profileImage = this.image.split("base64,")      
      console.log('profileimage',this.profileImage);
      this.img.nativeElement.src = e.target.result;
    });
    FR.readAsDataURL(this.file); 
  }

  
}
