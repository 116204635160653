import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
//import { debounceTime , distinctUntilChanged, switchMap} from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { MytoasterService } from '../service/mytoaster.service';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { daysOfMonth } from './daysOfMonth.enum';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit, AfterViewInit {
  @ViewChild('select') selectElRef: any;
  @ViewChild('taskData') taskData: ElementRef;
  @ViewChild('assignto') assignto: ElementRef;
  @ViewChild('closebutton') closebutton: ElementRef; uploadCsvTask: FormGroup;
  //@ViewChild('selectParts') selectParts:any;  
  results: any;
  name: FormControl = new FormControl();
  userId: any;
  empName: any;
  empPhone: any;
  empNameList: boolean = false;
  assetName: any;
  assetsList: any;
  watchersList: any;
  selectedValues: any[] = [];
  watchersCount: number = 0;
  addTaskForm: FormGroup;
  taskFormDetail: {};
  submitted = false;
  notFound: any;
  createdTaskList: any;
  selectedAssets: any;
  partNameLength: number = 0;
  customerCount: number = 0;
  partsName: any;
  selectedAssetSerialNumber: any;
  partName: any;
  selectedPart: boolean = false;
  selectedIndex: number;
  assignAsset: boolean = false;
  empStaffId: number;
  errorMessage: string = "Some error occured due to server or internet connection!";
  repeatMsg: string = "Select the tab to set a recurrence interval for this task";
  weeknos: boolean = false;
  monthnos: boolean = false;
  weeksnoSelected: any;
  weekDaySelected = [];
  weekdaydiv: boolean = false;
  isDateShow: boolean = false;
  enddateNameString:string;
  staffMemberList: any;

  monthDaySelected;
  monthNoSelected;

  selectScheduleType: String;
  WeekDaysString: String;
  daysOfMonthArray = daysOfMonth;
  // repeated task properties added
  repeatedTask: Number;
  scheduleType: any;
  repeatedOn: Number;
  weekDay: String;
  monthlyDate: Number;
  customerData: any;
  count: any;
  customerId: any;
  customerName: any;
  taskUploadData: any;
  taskUploadDataLength: any;
  startDate: Date;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  customerAccountName: any;
  csvValue: {}[];
  isMasterSel: boolean;
  checkedCategoryList: any;

  // checked: boolean = true;
  constructor(private authService: AuthService, private fb: FormBuilder, private toaster: MytoasterService, private flashMessage: FlashMessagesService, private router: Router, public snackBar: MatSnackBar) {
    this.userId = localStorage.getItem("userId");
    this.addTaskForm = this.fb.group({
      taskName: ['', Validators.required],
      taskType: ['', Validators.required],
      // assignedTo: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.uploadCsvTask = this.fb.group({
      taskCsvData: ['']
    });

    this.isMasterSel = true;
  }

  ngOnInit() {
    this.enddateNameString = '2021-09-30'
    this.qEndDate = '2021-09-30'
    this.weeksnoSelected = 1
    this.monthNoSelected = 1
    this.startDate = new Date();
    this.name.valueChanges.subscribe(name => this.authService.autoSearchEmployee(this.userId, name)
      .subscribe((res: any) => {
        if (res.status == "success") {
          this.empNameList = true;
          this.results = res.data;
          console.log(this.results);
        }

        else {
          this.notFound = res.message;
          console.log(res.message);
          setTimeout(function () {
            $(".notFound").remove();
          }, 2500);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      })
    );

    this.assignto.nativeElement.addEventListener('click', () => {
      console.log(' i Am clicked');
      this.fetchStaffMemberList();
    });
  }

  fetchStaffMemberList() {
    this.authService.listStaff(this.userId).subscribe((res: any) => {
      if (res.status == "success") {
        this.staffMemberList = res.data;
        console.log(this.staffMemberList);
      } else {
        console.log(res.message);
        this.snackBar.open(res.response, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {

    })
  }

  staffImg() {
    this.fetchStaffMemberList();
  }

  filterStaff(e) {
    console.log(e.target.value);
    this.authService.autoSearchEmployee(this.userId, e.target.value).subscribe((res: any) => {
      if (res.status == "success") {
        this.staffMemberList = res.data;
      } else {
        console.log(res.message);
        this.snackBar.open(res.response, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });;
      }
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.errorMessage, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.addTaskForm.controls; }
  //  get g() { return this.editTaskForm.controls; }


  /** to show name in input box */
  addNameAndEmail(info: any) {
    this.empName = info.name;
    this.empPhone = info.phone;
    this.empStaffId = parseInt(info.staffId);
    this.empNameList = false;
  }


  /** to show asset list */
  // showAssetList(){
  //   jQuery.noConflict();
  //   $('#assetModal').modal('show');
  //   this.authService.assetList(this.userId).subscribe((res:any) => {
  //     console.log(res);
  //       if(res.status=='success'){
  //         this.assetsList=res.data;
  //       }
  //   },
  //   (error) => {
  //     console.log('error is ',error.message);
  //     this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //   })
  // }
  assetList() {
    jQuery.noConflict();
    $('#assetModal').modal('show');
    console.log("asset list");
    this.authService.assetList(this.userId).subscribe((res: any) => {
      if (res.status == "success") {
        console.log("assets list response is ", res.data);
        this.customerData = res.data;
        console.log('customer', this.customerData)
        for (let data of this.customerData) {
          this.customerId = data.assetId;
          this.customerName = data.accountName;
        }
      }
      else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }
  /** add asset name  */
  addAsset(name: any) {
    this.assetName = name;
    this.assignAsset = false;
  }
  /** to show watchers/staff list */
  showWatchersList() {
    this.watchersCount = 0;
    jQuery.noConflict();
    $('#watchersModal').modal('show');
    this.authService.listStaff(this.userId).subscribe((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.watchersList = res.data;
        this.watchersList = this.watchersList.filter((data) => data.name != this.empName);
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }

  ngAfterViewInit() {
    this.updateSelectList();   //for watchers
    // this.updateSelectedAssetList();  // for asset parts


    console.log(this.assignto.nativeElement);
  }

  /** function to update selected watchers */
  updateSelectList() {
    let options = this.selectElRef.nativeElement.options;
    for (let i = 0; i < options.length; i++) {
      options[i].selected = this.selectedValues.indexOf(options[i].value) > -1;
    }
  }

  /** function to select multiple watchers */
  change(options) {
    console.log(options);

    this.selectedValues = Array.apply(null, options)  // convert to real Array
      .filter(option => option.selected)
      .map(option => parseInt(option.value))    //to convert string into number for staffId of watchers
    this.watchersCount = this.selectedValues.length;  //count the watchers
    console.log("watchers", this.selectedValues);
  }

  /** function to create task */
  addTask() {
    this.submitted = true;
    console.log('this.value', this.addTaskForm.value);
    /** form validations */
    if (this.addTaskForm.invalid) {
      this.snackBar.open("Please fill all the mandatory (*) fields!", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please fill all the mandatory (*) fields!", { cssClass: 'alert-danger', timeout: 3000 });
      return;
    }

    if (this.count == null) {
      console.log("partname");
      // this.assignAsset = true;
      this.snackBar.open("Please assign accounts", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please assign assets", { cssClass: 'alert-danger', timeout: 2000 });
      return;
    }
    // if(this.selectedValues.length == 0){
    //   this.flashMessage.show("Please assign watchers!", { cssClass: 'alert-danger', timeout: 2000 });
    //   return;  
    // }
    // if(this.empPhone == null){
    //   this.flashMessage.show("Please assign task to some employee!", { cssClass: 'alert-danger', timeout: 2000 });
    //   return;
    // }

    this.taskFormDetail = {
      userId: this.userId,
      accountName: this.customerAccountName,
      taskName: this.addTaskForm.value.taskName,
      assignee: this.empPhone,
      assignName: this.empName,
      taskType:this.addTaskForm.value.taskType,
      description: this.addTaskForm.value.description,
      watchers: this.selectedValues,
      assetId: this.selectedAssetSerialNumber,
      parts: this.count,
      dueDate: moment(this.addTaskForm.value.dueDate).format('YYYY-MM-DD'),
      staffId: this.empStaffId,
    }
    if (this.selectScheduleType == 'weekly') {
        this.taskFormDetail['repeatedTask'] = 1,
        this.taskFormDetail['weekDay'] = this.weekDay,
        this.taskFormDetail['repeatedOn'] = this.repeatedOn,
        this.taskFormDetail['scheduleType'] = "weekly"
        this.taskFormDetail['end_date'] = this.qEndDate
    }
    if (this.selectScheduleType == 'daily') {
        this.taskFormDetail['repeatedTask'] = 1,
        this.taskFormDetail['scheduleType'] = "daily"
        this.taskFormDetail['end_date'] = this.qEndDate
    }
    if (this.selectScheduleType == 'monthly') {
        this.taskFormDetail['repeatedTask'] = 1,
        this.taskFormDetail['monthlyDate'] = this.monthlyDate,
        this.taskFormDetail['repeatedOn'] = this.repeatedOn,
        this.taskFormDetail['scheduleType'] = "monthly"
        this.taskFormDetail['end_date'] = this.qEndDate
    }

    console.log(" details ", this.taskFormDetail);
    this.authService.createTask(this.taskFormDetail).subscribe((res: any) => {
      console.log(res);
      if (res.status == "success") {
        $("#taskModal").modal('hide');
        this.addTaskForm.reset();
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.router.navigate(['tasks']); //for sending to task page
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });;
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }

  /**function to show asset parts based on selected assets from dropdown */
  changeAsset(e: any) {
    this.count = 0;
    this.selectedAssetSerialNumber = e.target.value;
    this.customerData.filter((element: any) => {
      if (element.assetId == e.target.value) {
        this.customerAccountName = element.accountName;
        this.partsName = element.parts;
        for (let data of this.partsName) {
          data['isSelected'] = true;

          //  if(data['checked']== true){
          //    this.customerCount=this.customerCount++;
          //  }
        }

        console.log("parts name ", this.partsName.length);
      }

    });
  }
  /** function to select the part of machine */
  partDetail(item: any, index: number) {
    console.log(item);
    this.partName = item.name;
    this.partNameLength = 1;
    this.selectedIndex = index;
    //console.log("selected part ", this.partName);
  }
  // to show messages in the repeat task modal
  repeatMessages = {
    None: "Select the tab to set a recurrence interval for this task",
    Daily: "Task set to repeat Every Day",
    Weekly: "",
    Monthly: ""
  }
  weeksorMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  daysOfMonth = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th',
    '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th',
    '25th', '26th', '27th', '28th', '29th', '30th', '31st'];

  //  Methods for repeat messages
  dailyRepeat() {
    this.isDateShow = true
    this.repeatMsg = this.repeatMessages['Daily'];
    this.weeknos = false;
    this.monthnos = false;
    this.selectScheduleType = "daily";
  }
  noRepeat() {
    this.isDateShow = true
    this.repeatMsg = this.repeatMessages['None'];
    this.weeknos = false;
    this.monthnos = false;
    this.selectScheduleType = "none";
  }
  weeklyRepeat() {
    this.isDateShow = true
    this.repeatMsg = this.repeatMessages['Weekly'];
    this.weeknos = true;
    this.monthnos = false;
    this.selectScheduleType = "weekly";
  }
  monthlyRepeat() {
    this.isDateShow = true
    this.repeatMsg = this.repeatMessages['Monthly'];
    this.weeknos = false;
    this.monthnos = true;
    this.selectScheduleType = "monthly";
  }
  setRepeatedData(type: String) {
    
    console.log(type, "type of Schedule is");
    if (type == "daily") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      this.repeatedOn = null;
      this.weekDay = null;
      this.monthlyDate = null
    }
    if (type == "weekly") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      console.log('scg', this.scheduleType);
      this.repeatedOn = this.weeksnoSelected;
      console.log('repeatedOn', this.repeatedOn);
      this.weekDay = this.WeekDaysString;
      console.log('weekDay', this.weekDay);
      this.monthlyDate = null;
    }
    if (type == "monthly") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      this.repeatedOn = this.monthNoSelected;
      console.log('m', this.repeatedOn);
      this.monthlyDate = this.monthDaySelected;
      console.log('monthDay', this.monthlyDate);
      this.weekDay = null
    }

  }

  selectWeekNo(e) {
    this.weeksnoSelected = Number(e.target.value);
    console.log('week', this.weeksnoSelected);
  }

  selectMonthDay(e) {
    this.monthDaySelected = Number(e.target.value);
    console.log(this.monthDaySelected);
  }
  selectMonthNo(e) {
    this.monthNoSelected = Number(e.target.value);
  }
  selectWeekDays(e) {
    if (e.target.checked) {
      let search = this.weekDaySelected.find((elem) => {
        return elem === e.target.value;
      })
      console.log(search, "from search");
      if (typeof (search) !== 'string') {
        this.weekDaySelected.push(e.target.value);
      }
      console.log('weekend', this.weekDaySelected);
    }
    // for unchecking
    if (!e.target.checked) {
      console.log(e);
      let pos = this.weekDaySelected.indexOf(e.target.value);
      console.log(pos, "ind value");
      this.weekDaySelected.splice(pos, 1);
      console.log(this.weekDaySelected);
    }
    this.WeekDaysString = this.weekDaySelected.toString();
    console.log(this.WeekDaysString);
  }

  showWeekDaysDiv() {
    this.weekdaydiv = true;
  }
  uncheckMachineMode(item) {
    item.isSelected = !item.isSelected;
  }
  countCustomers() {
    this.count = 0;
    console.log('parts name', this.partsName)
    this.count = this.partsName.filter(tick => tick.isSelected == true);

    this.customerCount = this.count.length;
    console.log('count', this.count.length);
    //  this.snackBar.open('Customers Added Successfully','', {
    //   duration: 1000,
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
    jQuery.noConflict();
    $('#assetModal').modal('hide');

  }
  onSubmit() {
    if (!this.taskData.nativeElement.files[0]) {
      this.snackBar.open("'Failed!', Please choose the csv file first!", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.toaster.showToast('Failed!', "Please choose the csv file first!", 'danger');
      return;
    }
    const formData = new FormData();
    let userId = this.userId;

    console.log("userid is ", userId);
    formData.append('uploadfile', this.taskData.nativeElement.files[0], this.taskData.nativeElement.files[0].name);
    console.log("form data is ", this.taskData.nativeElement.files[0]);
    this.authService.uploadTaskList(formData, userId).subscribe(
      (response: any) => {
        console.log('UPLOADING assets response ', response);
        if (response.status == "success") {
          this.taskData.nativeElement.value = "";   //to reset the file name
          this.taskUploadData = response.data;
          this.taskUploadDataLength = this.taskUploadData.length;
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          jQuery.noConflict();
          $("#taskLogModal").modal('show');
        }
        else {
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          // this.flashMessage.show(response.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      }
    );
  }
  // showTaskList() {
  //   let userID = parseInt(this.userId);
  //   this.authService.taskList(userID).subscribe(
  //     (res: any) => {
  //       console.log("task list is ", res);
  //       if (res.status == "success") {
  //         this.createdTaskList = res.data;
  //         console.log('this.createdTaskList', this.createdTaskList)
  //         // for(let data of this.createdTaskList){
  //         //   this.Pname=data.parts;
  //         // }
  //         // console.log(this.Pname);
  //         // this.dataSource = new MatTableDataSource(res.data);
  //         // this.dataSource.paginator = this.paginator;   //for pagination
  //         // this.dataSource.sort = this.sort;   //for sorting
  //         // this.taskResult= this.createdTaskList.taskResult;
  //       }
  //       else {
  //         this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //       }
  //     },
  //     (error: any) => {
  //       this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //     }
  //   )
  // }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: ["service_engineer_number", "task_name", "description", "account_name", "machine_unique_code", "watchers", "due_date"]
  };

// on download csv file
  downloadCSV() {
    this.csvValue = [{}]
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    // console.log('csvvvv' ,this.createdTaskList)
    new AngularCsv(this.csvValue, 'Task', this.csvOptions);

  }
  close() {
    this.router.navigate(['tasks']);
    // this.IsmodelShow=true;
    // set false while you need open your model popup
    // do your more code
  }
  // uncheck watchers list
  uncheckwatchers(item, i) {
    // console.log( item.isSelected);

    item.isSelected = !item.isSelected;
    console.log(item.isSelected);
    if (item.isSelected == true) {
      this.selectedValues.push(item.staffId);
      console.log(this.selectedValues);
    }
    if (item.isSelected == false) {
      this.selectedValues.splice(i, 1);
      console.log(this.selectedValues);
      if (this.selectedValues.includes(item.staffId)) {
        const index = this.selectedValues.indexOf(item.staffId);
        console.log(index);
        if (index > -1) {
          this.selectedValues.splice(index, 1)
          console.log(this.selectedValues);
        }
      }

    }
    this.watchersCount = this.selectedValues.length;  //count the watchers
    this.watchersCount = this.watchersCount;


  }
  // on select all or deselect all
  checkUncheckAll() {
    for (var i = 0; i < this.partsName.length; i++) {
      this.partsName[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
    console.log('checked list1', this.checkedCategoryList);
  }
// function if all selected
  isAllSelected() {
    this.isMasterSel = this.partsName.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
    console.log('checked list1', this.checkedCategoryList);
  }
// get checked item list
  getCheckedItemList() {
    this.checkedCategoryList = [];
    for (var i = 0; i < this.partsName.length; i++) {
      if (this.partsName[i].isSelected)
        this.checkedCategoryList.push(this.partsName[i]);
    }
    this.checkedCategoryList = JSON.stringify(this.checkedCategoryList);
    console.log('checked list', this.checkedCategoryList);
  }

  getyear = new Date().getFullYear();
  mMarch = 2
  mJune = 5
  mSep = 8
  mDec = 11
  qEndDate;

   lastdayDec = new Date(this.getyear, this.mDec +1, 0).getDate();
   lastdaySep = new Date(this.getyear, this.mSep +1, 0).getDate();
   lastdayJune = new Date(this.getyear, this.mJune +1, 0).getDate();
   lastdayMarch = new Date(this.getyear, this.mMarch +1, 0).getDate();

   
   selectEndDate(e){
     this.qEndDate = e.target.value
     console.log(e.target.value)
     console.log(new Date(e.target.value));
     
   }

}


