import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { MytoasterService } from '../service/mytoaster.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs/Subject';
import { daysOfMonth } from '../create-task/daysOfMonth.enum';
import * as _ from 'lodash';
import { PushNotificationOptions, PushNotificationService } from 'ngx-push-notifications';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { FileserviceService } from '../service/fileservice.service';
import { HttpClient } from '@angular/common/http';

declare var require: any
const FileSaver = require('file-saver');

//import { DataTableModule } from 'angular7-data-table';
declare var $: any;

//var $  = require( 'jquery' );
//var dt = require( 'datatables.net' )();
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  [x: string]: any;
  @ViewChild('select') selectElRef: any;
  @ViewChild('taskData') taskData: ElementRef;
  @ViewChild('myList') myList: ElementRef;

  displayedColumns: string[] = ['taskName', 'accountName', 'assignName', 'dueDate', 'taskStatus', 'comment', 'edit', 'action', 'delete'];
  dataSource: MatTableDataSource<any>;
  //dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('assignto') assignto: ElementRef;
  @ViewChild('fileDownload') fileDownload: ElementRef;
  //@ViewChild('selectParts') selectParts:any;

  monthDaySelected;
  monthNoSelected;

  selectScheduleType: String;
  WeekDaysString: String;
  daysOfMonthArray = daysOfMonth;
  weeknos: boolean = false;
  monthnos: boolean = false;
  weeksnoSelected: Number;
  weekDaySelected = [];
  weekdaydiv: boolean = false;
  // repeated task properties added
  repeatedTask: Number;
  scheduleType: any;
  repeatedOn: Number;
  weekDay: String;
  monthlyDate: Number;
  results: any;
  name: FormControl = new FormControl();
  userId: any;
  detectDropdown: string;
  empName: any;
  empPhone: any;
  empNameList: boolean = false;
  assetName: any;
  assetsList: any;
  watchersList: any;
  selectedWatchers: any[] = [];
  watchersCount: number = 0;
  addTaskForm: FormGroup;
  taskFormDetail: {};
  submitted = false;
  notFound: any;
  createdTaskList: any;
  searchDetail: {};
  selectedAssets: any;
  partNameLength: number = 0;
  partsName: any;
  selectedAssetSerialNumber: any;
  partName: any;
  selectedPart: boolean = false;
  selectedIndex: number;
  assignAsset: boolean = false;
  uploadForm: FormGroup;
  addCommentForm: FormGroup;
  commentForm: FormGroup;
  particularTaskDetail: any;
  assetCount: number = 0;
  countOfWatchers: number = 0;
  taskId: any;
  commentDetail: any;
  previousComments: any;
  previousCommentsLength: number = 0;
  taskUploadData: any;
  editTaskForm: FormGroup;
  taskUploadDataLength: any;
  editTask: boolean = false;
  editPartNameLength: number = 0;
  editWatchersCount: any;
  editSelectedAsset: any;
  editSelectedPart: any;
  editSelectedWatchers: any;
  editTaskId: any;
  assigneePhone: any;
  assignName: any;
  editSelectedAssetName: any;
  editSelectedWatchersStaffId = [];
  commentImageUrl: any;
  detail: {};
  showImageBtn: boolean = true;
  taskStatus: any;
  taskResult: any;
  filterDate: boolean = false;
  fliterTaskStatus: boolean = false;
  filterForDate: any = {};
  errorMessage: string = "Some error occured due to server or internet connection!";
  dateValue: any;
  statusList = [
    { title: 'All', name: 'all' },
    { title: 'Complete', name: 'complete' },
    { title: 'Incomplete', name: 'incomplete' },
    { title: 'Pending', name: 'pending' }
  ];
  selectedTaskStatus: any;
  baseUrl: string = environment.base_url;
  accountName: any;
  title: any;
  staffname: any;
  taskName: any;
  dateFilter: any;
  accountNameR: any;
  FrequencyTypeR: any;
  datesR = [];
  // quick task fields properties
  quickTaskList: any;
  quickTaskModalData: any;
  searchquickTaskList: boolean = false;
  count: any;
  eventtype: any;
  customerCount: any;
  customerId: any;
  staffMemberList: any;
  Pname: any;
  value: any;
  showDiv: boolean = false;
  showModal: boolean = false;
  filteredData: any;
  showValue: boolean = false;
  serviceEngineer: any;
  taskupdated: any;
  commentId: any;
  commentsData: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  fileUploadForm: FormGroup;
  fileInputLabel: string;
  csvValue: any;
  reportPdfUrl: string = environment.pdf_url;
  pdfUrl: string;
  pdfname: string;
  blob: Blob;
  now: any = moment();
  resetvalue: any;
  selectedElement: string;
  toggleValue: boolean = false;
  editassetId: any;
  dateForm: FormGroup;
  spinnerEnabled = false;
  keys: string[];
  dataSheet = new Subject();
  // @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;
  startDate: Date;
  data: any = [{
    "Service_engineer_number": '', "Task_name": '', "Description": '', "Account_name": '', "Machine_unique_code": '', "Watchers": '', "Due_date": ''
  }];
  isMasterSel: boolean;
  checkedCategoryList: any;
  ediAssetIdvalue: any;
  accountId: any;
  editTaskFormDetails: {};
  constructor(private authService: AuthService, private http: HttpClient, private fb: FormBuilder, private fileService: FileserviceService, private toaster: MytoasterService, private flashMessage: FlashMessagesService, private router: Router, private _pushNotificationService: PushNotificationService, public snackBar: MatSnackBar) {
    this.userId = localStorage.getItem("userId");
    this.addTaskForm = this.fb.group({
      taskName: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.uploadForm = this.fb.group({
      taskCsvData: ['']
    });
    this.fileUploadForm = this.fb.group({
      myfile: ['']
    });
    // this.dateForm = this.fb.group({
    //   filterDate:['']
    // })

    this.addCommentForm = this.fb.group({
      taskName: ['', Validators.required],
      taskType: ['', Validators.required],
      assignedTo: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.editTaskForm = this.fb.group({
      taskName: ['', Validators.required],
      taskType: ['', Validators.required],
      assignName: ['', Validators.required],
      dueDate: ['', Validators.required],
      //task: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    });

    this.showTaskList();
    this.showAssetsList();
    this.staffList();
    this.isMasterSel = true;
  }
  ngOnInit() {

    this.startDate = new Date();
    this._pushNotificationService.requestPermission();
    const isGranted = this._pushNotificationService.isPermissionGranted;
    console.log('is grant', isGranted)
    // $('#mytaskdiv').show();

    $("#iconToggler").click(function () {
      $(this).find('img').toggle();
      $(this).find('span').toggle();
    });

    //to find employee name list on auto search
    this.name.valueChanges.subscribe(name => this.authService.autoSearchEmployee(this.userId, name)
      .subscribe((res: any) => {
        if (res.status == "success") {
          this.empNameList = true;
          this.results = res.data;
          console.log('Results', this.results);
        }
        else {
          this.notFound = res.message;
          setTimeout(function () {
            $(".notFound").remove();
          }, 2500);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      })
    );
    this.assignto.nativeElement.addEventListener('click', () => {
      console.log(' i Am clicked');
      this.fetchStaffMemberList();
    });
    $("input").on("keypress", function (e) {
      if (e.which === 32 && !this.value.length)
        e.preventDefault();

    });
  }


  // handleMyTsk(e){
  //   this.searchquickTaskList = false;
  //   $('#mytaskdiv').show();
  //   $('#collapseExample').hide();
  //   console.log('click by addtaskbutton', this.searchquickTaskList);
  // }
  // handleQuickTask(e){
  //   $('#mytaskdiv').hide();
  //   this.searchquickTaskList = true;
  //   $('#collapseExample').show();
  //   console.log('click by quicktskbutton', this.searchquickTaskList);
  // }


  /** for filter through date */
  calendarFilter() {
    this.fliterTaskStatus = false;  //for closing task status filter if already open
    if (this.filterDate) {
      this.filterDate = false;
    }
    else {
      this.filterDate = true;  //for opening calendar filter
    }
  }

  /** for getting date value in filter */
  parseDate(dateString: string): Date {
    this.title = '';
    this.staffname = '';
    this.accountName = '';
    this.taskName = '';
    if (dateString) {
      // return new Date(dateString);
      console.log("date ", dateString);
      let filterDateDetails = {
        userId: this.userId,
        date: dateString
      }
      this.authService.dateFilterForTask(filterDateDetails).subscribe(
        (res: any) => {
          console.log(res);
          console.log('===============')
          if (res.status == 'success') {
            this.createdTaskList = res.data;
            this.dataSource = new MatTableDataSource(res.data);
            this.dataSource.paginator = this.paginator;
          }
          else if (res.status == 'failed') {

            this.createdTaskList == undefined;
            this.createdTaskList = [];
          }

          else {

            res.data = [];
            this.createdTaskList = res.data;
            console.log('thissssssssssssssss', this.createdTaskList)
            this.dataSource = new MatTableDataSource(this.createdTaskList);
            console.log('thisssss', this.dataSource)
            console.log('===============')
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(res.message, '', {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        },
        (error: any) => {

          this.snackBar.open(this.errorMessage, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
          // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
        }
      )
    }
    return null;
  }

  /** for filter through task status */
  taskStatusFilter() {
    this.filterDate = false; //for closing calendar filter if already open
    if (this.fliterTaskStatus) {
      this.fliterTaskStatus = false;
    }
    else {
      this.fliterTaskStatus = true;  //for opening task status filter
    }
  }
  // apply filter by Account name
  applyFilterByAccount(event) {
    this.eventtype = event
    this.detectDropdown = 'accountFilter'
    this.title = '';
    this.staffname = '';
    this.taskName = '';
    this.dateFilter = '';
    console.log('event', event.value)
    let id = event.value;
    let type = 'account';
    let taskType = 'general'
    this.authService.filterbyAccount(id, type, taskType).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          console.log('response', res)
          console.log('===============')
          this.createdTaskList = res.data;
          this.dataSource = new MatTableDataSource(this.createdTaskList);
          this.dataSource.paginator = this.paginator;
          console.log('dataSource pagination', this.dataSource.paginator);
          //for pagination
          this.dataSource.sort = this.sort;
          console.log('this.createdList', this.createdTaskList);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
      }
    )

  }
  // get staff list
  staffList() {
    console.log(this.userId);
    this.authService.listStaff(this.userId).subscribe((res: any) => {
      console.log("employee list response is", res);
      if (res.status == "success") {
        this.serviceEngineer = res.data;
        console.log(this.serviceEngineer);
      }
      // if(res.status=='sessionExpired'){
      //   this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 }); 
      //   this.router.navigate(['login']);
      // }
      else {
        // this.snackBar.open(res.message, '', {
        //   duration: 3000,
        //   horizontalPosition: this.horizontalPosition,
        //   verticalPosition: this.verticalPosition,
        // })
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
      })
  }

  /**function to get task status based on selection from dropdown */
  changeFilterStatus(e: any) {
    this.eventtype = e;
    this.detectDropdown = 'taskStatus_select'
    this.staffname = '';
    this.accountName = '';
    this.taskName = '';
    this.dateFilter = '';
    console.log(e);

    // this.statusList.filter((element:any) => {
    //   if(element.name == e.target.value){
    //     this.selectedTaskStatus = element.name;
    //     console.log("status", this.selectedTaskStatus)
    //   }
    // });
    let optionText = e.value;
    console.log("selec", optionText)
    // this.selectedTaskStatus = e.target.value;
    // console.log("selected", this.selectedTaskStatus)
    let filterStatus = {
      userId: this.userId,
      taskStatus: optionText
    }

    this.authService.filterTaskStatus(filterStatus).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.createdTaskList = res.data;
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

  // convenience getter for easy access to form fields
  get f() { return this.addTaskForm.controls; }
  get g() { return this.editTaskForm.controls; }

  /** to show name in input box */
  addNameAndEmail(info: any) {
    if (info.name) {
      this.editTaskForm.patchValue({
        assignName: info.name
      })
    }

    this.empName = info.name;
    this.empPhone = info.phone;
    this.empNameList = false;
  }

  /** function to show task list */
  showTaskList() {
    this.dataSource = new MatTableDataSource([]);
    let userID = parseInt(this.userId);
    this.authService.taskList(userID).subscribe(
      (res: any) => {
        console.log("task list is ", res);
        if (res.status == "success") {
          this.createdTaskList = res.data;
          for (let data of this.createdTaskList) {
            data.dueDate = moment(data.dueDate).format('YYYY-MM-DD');
            console.log('due', data.dueDate);

          }
          console.log('TaskList', this.createdTaskList)

          // for(let data of this.createdTaskList){
          //   this.Pname=data.parts;
          // }
          // console.log(this.Pname);
          this.dataSource = new MatTableDataSource(this.createdTaskList);
          this.dataSource.paginator = this.paginator;   //for pagination
          console.log(' this.dataSource.paginator', this.dataSource.paginator);

          this.dataSource.sort = this.sort;   //for sorting
          // this.taskResult= this.createdTaskList.taskResult;
        }
        if (res.status == 'failed') {

          this.createdTaskList == undefined;
          this.createdTaskList = [];
        }
        else {
          // this.snackBar.open(res.message, '', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

  /** function to search the task */
  search(term: any) {
    console.log(term);
    this.searchDetail = {
      userId: this.userId,
      taskName: term
    }
    this.authService.searchTask(this.searchDetail).subscribe((res: any) => {
      if (res.status == "success") {
        this.createdTaskList = res.data;
        // console.log('this.' ,this.createdTaskList);

      }
      // else{
      //   this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
      // }
    })
  }

  // for filter in data table
  // applyFilter(event: Event) {
  //   console.log(event);

  //   this.title='';
  //   this.name ='';
  //   this.accountName='';
  //   this.dateFilter ='';
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }
  applyFilter(filterValue: string) {
    this.title = '';
    this.staffname = '';
    this.accountName = '';
    this.dateFilter = '';
    console.log(filterValue);

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /** to show asset list */
  showAssetList() {
    jQuery.noConflict();
    $('#assetModal').modal('show');
    this.showAssetsList();
  }

  showAssetsList() {
    this.authService.assetList(this.userId).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.assetsList = res.data;
          console.log('this asset', this.assetList)
          for (let data of this.assetsList) {
            this.customerId = data.assetId;
          }
          console.log('this.assetsList', this.assetsList)
        }
        else {
          // this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
        }
      },
      (error: any) => {
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      }
    )
  }
  /** add asset name  */
  addAsset(name: any) {
    this.assetName = name;
    this.assignAsset = false;
  }
  /** to show watchers/staff list */
  showWatchersList() {
    this.watchersCount = 0;
    jQuery.noConflict();
    $('#watchersModal').modal('show');
    this.authService.listStaff(this.userId).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.watchersList = res.data;
          // for(let data of this.watchersList){
          //   data['isSelected'] = true;
          // }


          console.log('eeeee', this.editSelectedWatchers);
          this.watchersList = this.watchersList.filter((data) => data.name != this.assignName);
          console.log('wwwww', this.watchersList);
          let result = this.watchersList.filter(o1 => this.editSelectedWatchers.some(o2 => o1.staffId === o2.staffId));
          console.log("ids", result);
          for (let data of result) {
            data['isSelected'] = true;
          }
          // result['isSelected'] = true;
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

  /** for updating watchers list */
  ngAfterViewInit() {
    this.updateSelectList();   //for watchers
    // this.updateSelectedAssetList();  // for asset parts
    console.log(this.assignto.nativeElement);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /** for showing first expanded row by default in table */
  ngAfterViewChecked() {
    $("#collapse-0").addClass("show");
    $("tbody[data-target='#collapse-0']").attr("aria-expanded", "true"); //for changing icon
  }

  /** function to update selected watchers */
  updateSelectList() {

    let options = this.selectElRef.nativeElement.options;
    for (let i = 0; i < options.length; i++) {
      options[i].selected = this.selectedWatchers.indexOf(options[i].value) > -1;
    }
  }

  /** function to select multiple watchers */
  change(options, option) {
    console.log('options', option)


    this.selectedWatchers = Array.apply(null, options)  // convert to real Array
      .filter(option => option.selected)
      .map(option => option.value)
    this.watchersCount = this.selectedWatchers.length;  //count the watchers
    this.editWatchersCount = this.watchersCount;
  }

  /** function to create task */
  addTask() {
    this.submitted = true;
    /** form validations */
    if (this.addTaskForm.invalid) {
      // this.flashMessage.show("Please fill all the fields!", { cssClass: 'alert-danger', timeout: 2000 });
      this.snackBar.open("Please fill all the fields!", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      return;
    }
    if (this.partName == null) {
      console.log("partname");
      // this.assignAsset = true;
      this.snackBar.open("Please assign accounts", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please assign assets", { cssClass: 'alert-danger', timeout: 2000 });
      return;
    }
    if (this.empPhone == null) {
      this.snackBar.open("Please assign task to some employee!", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // this.flashMessage.show("Please assign task to some employee!", { cssClass: 'alert-danger', timeout: 2000 });
      return;
    }

    this.taskFormDetail = {
      userId: this.userId,
      taskName: this.addTaskForm.value.taskName,
      assignee: this.empPhone,
      assignName: this.empName,
      description: this.addTaskForm.value.description,
      watchers: this.selectedWatchers,
      serialNumber: this.selectedAssetSerialNumber,
      parts: this.partName,
      dueDate: this.addTaskForm.value.dueDate
      // task: this.addTaskForm.value.task
    }
    console.log(" details ", this.taskFormDetail);
    this.authService.createTask(this.taskFormDetail).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == "success") {
          $("#taskModal").modal('hide');
          this.addTaskForm.reset();
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.showTaskList();
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

  /**function to show asset parts based on selected assets from dropdown */
  changeAsset(e: any) {
    this.selectedAssetSerialNumber = e.target.value;
    console.log('@@##', this.selectedAssetSerialNumber);
    this.ediAssetIdvalue = this.selectedAssetSerialNumber;
    this.assetsList.filter((element: any) => {
      if (element.assetId == e.target.value) {
        this.partsName = element.parts;
        console.log('this.part', this.partsName)
        for (let data of this.partsName) {
          data['isSelected'] = true;

          //  if(data['checked']== true){
          //    this.customerCount=this.customerCount++;
          //  }
        }
        console.log("parts name ", this.partsName);
      }
    });
  }
  /** function to select the part of machine */
  partDetail(item: any, index: number) {
    this.isMasterSel = true;
    this.partName = item.name;
    this.partNameLength = 1;
    this.selectedIndex = index;
    console.log("selected part ", this.partName);
  }
  formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  /** function to get task details while click on show comment button and to show it on modal */
  imageArray = []
  tasktype: number
  uniqueMacine = []
  employeeDetails(list: any) {
    this.imageArray = list.taskImages
    var a = [];
    if (this.imageArray) {
      this.imageArray.forEach(function (obj) {
        a.push(obj.machine_name);
      })
    }
    console.log(a);
    this.uniqueMacine = a.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);


    if (list.taskType) {
      this.tasktype = list.taskType
    } else {
      this.tasktype = 1
    }
    let setTypeData
    if (list.taskType == null) {
      setTypeData = 1
    } else {
      setTypeData = list.taskType
    }
    this.showValue = false;
    this.taskId = list.taskId;
    this.commentsData = list.comments;
    for (let item of this.commentsData) {
      this.commentId = item.commentId;
    }
    console.log('this.commentId', this.commentId)
    let taskDetail = {
      taskId: this.taskId,
      userId: this.userId,
    }
    this.authService.particularTaskComments(taskDetail).subscribe(
      (res: any) => {
        console.log("response ", res);
        if (res.status == "success") {
          this.particularTaskDetail = res.data[0];
          // console.log('parts',this.particularTaskDetail.accountName);

          let wholeDate = this.particularTaskDetail.dueDate;
          let a = wholeDate.split("T");
          let date = a[0];
          // console.log("date is ",date); 
          this.addCommentForm.setValue({
            taskName: this.particularTaskDetail.taskName,
            dueDate: this.formatDate(date),
            taskType:setTypeData,
            assignedTo: this.particularTaskDetail.assignName,
            description: this.particularTaskDetail.description
          });
          this.assetCount = 1;
          this.countOfWatchers = this.particularTaskDetail.watchers.length;
          this.previousComments = this.particularTaskDetail.comments;
          console.log('previous comment', this.previousComments)
          this.previousCommentsLength = this.previousComments.length;
          this.taskResult = this.particularTaskDetail.taskResult;
          console.log("task ", this.taskResult);
        }
        else {
          console.log(res.message);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        // console.log('error is ',error);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

  /** function to save the comment */
  submitComment() {
    let commentDetail = {
      userId: this.userId,
      taskId: this.taskId,
      comment: this.commentForm.value.comment
    }
    this.authService.addComment(commentDetail).subscribe(
      (res: any) => {
        console.log("res is ", res);
        if (res.status == "success") {
          this.commentDetail = res.data;
          console.log("comment is ", this.commentDetail);
          this.previousCommentsLength = this.previousCommentsLength + 1;
          this.previousComments.push({ "userId": this.userId, "comment": this.commentDetail.comment, "createdAt": this.commentDetail.createdAt });
          this.commentForm.reset();
        }
        else {
          console.log("error", res.message);
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });

        }
      },
      (error: any) => {
        console.log("error is", error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

      }
    )
  }

  /** function to upload the csv file */
  onSubmit() {
    if (!this.taskData.nativeElement.files[0]) {
      this.toaster.showToast('Failed!', "Please choose the excel file first!", 'danger');
      return;
    }
    const formData = new FormData();
    let userId = this.userId;

    console.log("userid is ", userId);
    formData.append('uploadfile', this.taskData.nativeElement.files[0], this.taskData.nativeElement.files[0].name);
    console.log("form data is ", this.taskData.nativeElement.files[0]);
    this.authService.uploadTaskList(formData, userId).subscribe(
      (response: any) => {
        console.log('UPLOADING response ', response);
        if (response.status == "success") {
          this.taskData.nativeElement.value = "";   //to reset the file name
          this.taskUploadData = response.data;
          console.log('this.taskUploadData', this.taskUploadData);

          this.taskUploadDataLength = this.taskUploadData.length;
          console.log('length', this.taskUploadDataLength);

          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.showTaskList();
          if (this.taskUploadDataLength > 0) {
            jQuery.noConflict();
            $("#taskLogModal").modal('show');
          }

        }
        else {
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }

  /** function to know new task is selected */
  newTaskOpen() {
    this.editTask = false;
    console.log("here");
    //this.router.navigate['/createTask'];
  }

  /** function to edit task in modal */
  editParticularTask(list: any) {
    console.log(list);
    this.editSelectedWatchers = list.watchers.filter(data => data.type == "staff");
    this.editTask = true;
    // let wholeDate = list.dueDate;
    // console.log('&',wholeDate);

    // let a = wholeDate.split(".000Z");
    // console.log('aaaaaaaaaa', a);

    // let date = a[0];
    // console.log("dt is ", wholeDate, date);
    let setTypeData
    if (list.taskType == null) {
      setTypeData = 1
    } else {
      setTypeData = list.taskType
    }
    this.editTaskForm.setValue({
      taskName: list.taskName,
      dueDate: list.dueDate,
      description: list.description,
      assignName: list.assignName,
      taskType: setTypeData
    });

    this.assignName = list.assignName;
    this.assigneePhone = list.assignee;
    this.editTaskId = list.taskId;
    this.editSelectedAsset = list.serialNumber;
    this.accountId = list.assetId;
    console.log('select', this.ediAssetIdvalue);

    this.editassetId = this.ediAssetIdvalue ? this.ediAssetIdvalue : list.assetId;
    this.editSelectedPart = list.parts.length;
    this.editSelectedWatchers = list.watchers.filter(data => data.type == "staff");
    this.editPartNameLength = 1;
    this.editWatchersCount = this.editSelectedWatchers.length;
    this.editSelectedAssetName = list.assets;
    this.partsName = list.parts;
    console.log('Watchers', this.editSelectedWatchers, this.selectedAssetSerialNumber);

    console.log('@@@@', this.editSelectedPart);

    for (let i = 0; i < list.watchers.length; i++) {
      let staffId = list.watchers[i].staffId;
      this.editSelectedWatchersStaffId.push(staffId);
      console.log('this edit watchers', this.editSelectedWatchersStaffId)
    }

  }
  // on save edit task
  saveEditTask() {

    console.log('form', this.editTaskForm, this.editTaskForm.invalid)
    if (this.editTaskForm.invalid) {
      this.snackBar.open("'Failed!', Mandatory fields (*) should not be empty or due date should be greater than due date!", '', {
        duration: 4000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      return;
    }

    if (this.editSelectedPart == 0) {
      this.snackBar.open("'Failed!', Please assign accounts", '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      return;
    }
    console.log('edit', this.editassetId);

    this.editTaskFormDetails = {
      userId: this.userId,
      taskName: this.editTaskForm.value.taskName,
      description: this.editTaskForm.value.description,
      dueDate: moment(this.editTaskForm.value.dueDate).format('YYYY-MM-DD'),
      assignee: this.empPhone == undefined ? this.assigneePhone : this.empPhone,
      assignName: this.empName == undefined ? this.assignName : this.empName,
      watchers: this.selectedWatchers,
      assetId: this.ediAssetIdvalue ? this.ediAssetIdvalue : this.accountId,
      parts: this.partsName ? this.partsName.filter(tick => tick.isSelected == true) : this.partsName,
      taskId: this.editTaskId,

    }
    if (this.selectScheduleType == 'none') {
      this.editTaskFormDetails['repeatedTask'] = 0,
        this.editTaskFormDetails['scheduleType'] = "none"
    }
    if (this.selectScheduleType == 'weekly') {
      this.editTaskFormDetails['repeatedTask'] = 1,
        this.editTaskFormDetails['weekDay'] = this.weekDay,
        this.editTaskFormDetails['repeatedOn'] = this.repeatedOn,
        this.editTaskFormDetails['scheduleType'] = "weekly"
    }
    if (this.selectScheduleType == 'daily') {
      this.editTaskFormDetails['repeatedTask'] = 1,
        this.editTaskFormDetails['scheduleType'] = "daily"
    }
    if (this.selectScheduleType == 'monthly') {
      this.editTaskFormDetails['repeatedTask'] = 1,
        this.editTaskFormDetails['monthlyDate'] = this.monthlyDate,
        this.editTaskFormDetails['repeatedOn'] = this.repeatedOn,
        this.editTaskFormDetails['scheduleType'] = "monthly"
    }
    console.log("edited ", this.editTaskFormDetails);
    this.authService.editTask(this.editTaskFormDetails).subscribe(
      (res: any) => {
        console.log("editresponse ", res);

        if (res.status == 'success') {
          jQuery.noConflict();
          $("#editTaskModal").modal("hide");
          this.editTaskForm.reset();
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          if (this.detectDropdown == 'serviceEngineer') {
            this.applyFilterByStaff(this.eventtype)
          }
          if (this.detectDropdown == 'dateDropdown') {
            this.handleDateChange(this.eventtype)
          }
          if (this.detectDropdown == 'accountFilter') {
            this.applyFilterByAccount(this.eventtype)
          }
          if (this.detectDropdown == 'taskStatus_select') {
            this.changeFilterStatus(this.eventtype)
          }
          if (this.detectDropdown == '') {
            this.showTaskList();
          }
        }
        else {
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }
  resetFilter() {
    this.staffname = '';
    this.accountName = '';
    this.taskName = '';
    this.dateFilter = '';
    this.title = '';
    this.detectDropdown = '',
      this.eventtype = '',
      this.showTaskList();
  };
  /** function to bring scrollbar at bottom in comment modal */
  scrollToBottom() {
    this.myList.nativeElement.scrollTop = this.myList.nativeElement.scrollHeight;
  }

  /**this function is called when rejected button is clicked on image send by staff on comment section */
  taskRejected() {
    console.log("task rejected clicked")
    this.detail = {
      userId: this.userId,
      taskId: this.taskId,
      taskResult: "reject",
      commentId: this.commentId
    }
    console.log('this.detail', this.detail);

    this.authService.approvedOrRejectTask(this.detail).subscribe(
      (res: any) => {
        console.log("rej res is", res);
        if (res.status == 'success') {
          this.taskupdated = res;
          this.previousComments = res.data.comments;
          console.log('this.previous', this.previousComments)
          console.log('this.taskupdated', this.taskupdated)
          this.taskResult = res.data.taskResult;
          console.log('taskresult', this.taskResult)
          if (this.detectDropdown == 'serviceEngineer') {
            this.applyFilterByStaff(this.eventtype)
          }
          if (this.detectDropdown == 'dateDropdown') {
            this.handleDateChange(this.eventtype)
          }
          if (this.detectDropdown == 'accountFilter') {
            this.applyFilterByAccount(this.eventtype)
          }
          if (this.detectDropdown == 'taskStatus_select') {
            this.changeFilterStatus(this.eventtype)
          }
          if (this.detectDropdown == '') {
            this.showTaskList();
          }
        }
      },
      (error: any) => {
        console.log("error is ", error);
      }
    )
  }
  /**this function is called when approved button is clicked on image send by staff on comment section */
  taskApproved() {
    console.log("task approved clicked");
    this.detail = {
      userId: this.userId,
      taskId: this.taskId,
      taskResult: "approve",
      commentId: this.commentId
    }
    this.authService.approvedOrRejectTask(this.detail).subscribe(
      (res: any) => {
        console.log("approve res is", res);
        if (res.status == 'success') {
          this.taskResult = res.data.taskResult;
          this.previousComments = res.data.comments;
          if (this.detectDropdown == 'serviceEngineer') {
            this.applyFilterByStaff(this.eventtype)
          }
          if (this.detectDropdown == 'dateDropdown') {
            this.handleDateChange(this.eventtype)
          }
          if (this.detectDropdown == 'accountFilter') {
            this.applyFilterByAccount(this.eventtype)
          }
          if (this.detectDropdown == 'taskStatus_select') {
            this.changeFilterStatus(this.eventtype)
          }
          if (this.detectDropdown == '') {
            this.showTaskList();
          }
        }
      },
      (error: any) => {
        console.log("error is ", error);
      }
    )
  }
  uncheckMachineMode(item) {
    item.isSelected = !item.isSelected;
  }
  // count of assets
  countCustomers() {

    console.log('parts name', this.partsName)
    this.count = this.partsName.filter(tick => tick.isSelected == true);

    this.customerCount = this.count.length;
    this.editSelectedPart = this.customerCount;
    console.log(this.customerCount);

    jQuery.noConflict();
    let config = new MatSnackBarConfig();
    // this.snackBar.open('Customers Added Successfully','', {
    //   duration: 1000,
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    // });
    $('#assetModal').modal('hide');

    console.log('count', this.count.length);



  }
  fetchStaffMemberList() {
    this.authService.listStaff(this.userId).subscribe((res: any) => {
      if (res.status == "success") {
        this.staffMemberList = res.data;
        console.log(this.staffMemberList);
      } else {
        console.log(res.message);
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {

    })
  }
  staffImg() {
    this.fetchStaffMemberList();
  }
  filterStaff(e) {
    console.log(e.target.value);
    this.authService.autoSearchEmployee(this.userId, e.target.value).subscribe((res: any) => {
      if (res.status == "success") {
        this.staffMemberList = res.data;
      } else {
        console.log(res.message);
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.errorMessage, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }
  assetList() {
    jQuery.noConflict();
    $('#customerModal').modal('show');
    this.showDiv = true;

    console.log(' this.particularTaskDetail', this.particularTaskDetail)
    this.value = this.particularTaskDetail;

  }
  displayWatchersList() {
    jQuery.noConflict();
    $('#WatcherListModal').modal('show');
    this.showDiv = true;
    this.value = this.particularTaskDetail;
  }
  solcareObservation() {

    jQuery.noConflict();
    $('#SolcareModal').modal('show');
    this.showDiv = true;

    this.value = this.particularTaskDetail;
    if (this.value.solcare.length == 0) {
      this.showValue = false;
    }
    console.log('this.value', this.value)
  }
  selectedValue(event) {
    console.log('event', event.target.value)
    if (this.particularTaskDetail && this.particularTaskDetail.solcare.length > 0) {
      this.showValue = true;
      this.particularTaskDetail.solcare.filter((data) => {
        console.log('data', data)
        if (data.partId == event.target.value) {
          this.filteredData = data;
          console.log(' this.filtered data', this.filteredData)
        }


      })
    }


  }
  newImageCollection = []
  getmachineImage(e) {
    console.log(this.imageArray);
    this.newImageCollection = this.imageArray.filter(element => element.machine_name == e.target.value);

    console.log('imagecollection', this.newImageCollection);

  }
  // filter by service engineer
  applyFilterByStaff(event) {
    this.detectDropdown = 'serviceEngineer'
    this.eventtype = event;
    this.accountName = '';
    this.title = '';
    this.taskName = '';
    console.log('event', event);
    this.resetvalue = event.value;
    let id = event.value;
    let type = 'staff';
    let taskType = 'general'

    this.authService.filterbyStaff(id, type, taskType).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          console.log('response', res)
          this.createdTaskList = res.data;
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;   //for pagination
          this.dataSource.sort = this.sort;
          console.log('this.createdList', this.createdTaskList);

        }
        else {
          this.createdTaskList = res.data;
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;   //for pagination
          this.dataSource.sort = this.sort;
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }
  // function to remind to staff by tap to remind
  remindToStaff(taskId) {
    console.log('Task ID is ', taskId);
    this.authService.remindStaff(taskId).subscribe((res: any) => {
      console.log(res);
      if (res.status == "success") {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.errorMessage, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })
  }

  //  downloadPdf(taskId) {
  //   console.log('taskid' , taskId);
  //   let pdfName='Task Report'
  //  let url = 'https://mobil-admin.yugasa.org/uploads/Solcare_Visit_Report540.pdf'
  //   FileSaver.saveAs(url, pdfName);
  // }
  downloadPdf(taskId) {
    console.log('task', taskId)

    this.pdfUrl = this.reportPdfUrl + taskId + '.pdf';
    console.log('this.pdfurl', this.pdfUrl);
    this.pdfname = 'pdf_name';
    console.log('this.pdfname', this.pdfname);

    // FileSaver.saveAs(this.pdfUrl, this.pdfname);
    window.open(this.pdfUrl, "_blank");

  }

  clickToDownload() {
    jQuery.noConflict();
    $('#csvInstructions').modal('show');



  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: ["Service_engineer_number", "Task_name", "Description", "Account_name", "Machine_unique_code", "Watchers", "Due_date"]
  };
  // download excel file
  downloadCSV() {
    this.fileService.exportAsExcelFile(this.data, 'Task');
    // this.csvValue = [{}]
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    // console.log('csvvvv' ,this.createdTaskList)
    // new AngularCsv(this.csvValue, 'Task', this.csvOptions);

  }
  // watchers(e, i) {

  //   this.selectedWatchers.push(e.staffId);
  //   console.log('a ' ,this.selectedWatchers);

  //   this.watchersCount = this.selectedWatchers.length;  //count the watchers
  //   this.editWatchersCount = this.watchersCount;


  // }
  uncheckwatchers(item) {
    // console.log( item.isSelected);

    item.isSelected = !item.isSelected;
    console.log(item.isSelected);
    if (item.isSelected == true) {
      this.selectedWatchers.push(item.staffId);
      console.log(this.selectedWatchers);

    }
    if (item.isSelected == false) {
      if (this.selectedWatchers.includes(item.staffId)) {
        const index = this.selectedWatchers.indexOf(item.staffId);
        console.log(index);
        if (index > -1) {
          this.selectedWatchers.splice(index, 1)
          console.log(this.selectedWatchers);
        }
      }

    }
    this.watchersCount = this.selectedWatchers.length;  //count the watchers
    this.editWatchersCount = this.watchersCount;


  }
  // filter by date
  handleDateChange(event) {
    this.eventtype = event
    this.detectDropdown = 'dateDropdown'
    const m = moment(event.value).format('YYYY-MM-DD');
    console.log('m', m);

    // console.log('m' , m.toISOString());

    let filterDateDetails = {
      userId: this.userId,
      date: m
    }
    console.log(filterDateDetails);

    this.authService.dateFilterForTask(filterDateDetails).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.createdTaskList = res.data;

          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }


        else {
          res.data = [];
          this.createdTaskList = res.data;
          console.log('thissssssssssssssss', this.createdTaskList)
          this.dataSource = new MatTableDataSource(this.createdTaskList);
          console.log('thisssss', this.dataSource)
          this.dataSource.paginator = this.paginator;
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      }
    )
  }

  // onFileSelect(event) {
  //   let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     // console.log(file);

  //     if (!_.includes(af, file.type)) {
  //       alert('Only EXCEL Docs Allowed!');
  //     } else {
  //       this.fileInputLabel = file.name;
  //      let a= this.fileUploadForm.get('myfile').setValue(file);
  //      console.log(a)
  //     }
  //   }
  // }


  // onFormSubmit() {

  //   if (!this.fileUploadForm.get('myfile').value) {
  //     alert('Please fill valid details!');
  //     return false;
  //   }

  //   const formData = new FormData();
  //   let userId = this.userId;
  //   formData.append('formFile', this.fileUploadForm.get('myfile').value);
  //   // formData.append('agentId', '007');


  //   this.http
  //     .post<any>('http://www.example.com/api/upload', formData).subscribe(response => {
  //       console.log(response);
  //       if (response.statusCode === 200) {
  //         // Reset the file input
  //         this.uploadFileInput.nativeElement.value = "";
  //         this.fileInputLabel = undefined;
  //       }
  //     }, error => {
  //       console.log(error);
  //     });
  // }
  checkUncheckAll() {
    for (var i = 0; i < this.partsName.length; i++) {
      this.partsName[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
    console.log('checked list1', this.checkedCategoryList);
  }

  isAllSelected() {
    this.isMasterSel = this.partsName.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
    console.log('checked list1', this.checkedCategoryList);
  }

  getCheckedItemList() {
    this.checkedCategoryList = [];
    for (var i = 0; i < this.partsName.length; i++) {
      if (this.partsName[i].isSelected)
        this.checkedCategoryList.push(this.partsName[i]);
    }
    this.checkedCategoryList = JSON.stringify(this.checkedCategoryList);
    console.log('checked list', this.checkedCategoryList);
  }
  // to show messages in the repeat task modal
  repeatMessages = {
    None: "Select the tab to set a recurrence interval for this task",
    Daily: "Task set to repeat Every Day",
    Weekly: "",
    Monthly: ""
  }
  weeksorMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  daysOfMonth = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th',
    '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th',
    '25th', '26th', '27th', '28th', '29th', '30th', '31st'];

  //  Methods for repeat messages
  dailyRepeat() {
    this.repeatMsg = this.repeatMessages['Daily'];
    this.weeknos = false;
    this.monthnos = false;
    this.selectScheduleType = "daily";
  }
  noRepeat() {
    this.repeatMsg = this.repeatMessages['None'];
    this.weeknos = false;
    this.monthnos = false;
    this.selectScheduleType = "none";
  }
  weeklyRepeat() {
    this.repeatMsg = this.repeatMessages['Weekly'];
    this.weeknos = true;
    this.monthnos = false;
    this.selectScheduleType = "weekly";
  }
  monthlyRepeat() {
    this.repeatMsg = this.repeatMessages['Monthly'];
    this.weeknos = false;
    this.monthnos = true;
    this.selectScheduleType = "monthly";
  }
  setRepeatedData(type: String) {
    console.log(type, "type of Schedule is");
    if (type == "daily") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      this.repeatedOn = null;
      this.weekDay = null;
      this.monthlyDate = null
    }
    if (type == "weekly") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      console.log('scg', this.scheduleType);
      this.repeatedOn = this.weeksnoSelected;
      console.log('repeatedOn', this.repeatedOn);
      this.weekDay = this.WeekDaysString;
      console.log('weekDay', this.weekDay);
      this.monthlyDate = null;
    }
    if (type == "monthly") {
      this.repeatedTask = 1;
      this.scheduleType = type;
      this.repeatedOn = this.monthNoSelected;
      console.log('m', this.repeatedOn);
      this.monthlyDate = this.monthDaySelected;
      console.log('monthDay', this.monthlyDate);
      this.weekDay = null
    }

  }

  selectWeekNo(e) {
    this.weeksnoSelected = Number(e.target.value);
    console.log('week', this.weeksnoSelected);
  }

  selectMonthDay(e) {
    this.monthDaySelected = Number(e.target.value);
    console.log(this.monthDaySelected);
  }
  selectMonthNo(e) {
    this.monthNoSelected = Number(e.target.value);
  }
  selectWeekDays(e) {
    if (e.target.checked) {
      console.log('aaa', e.target.value, this.weekDaySelected)
      let search = this.weekDaySelected.find((elem) => {
        return elem === e.target.value;
      })
      console.log(search, "from search");
      if (typeof (search) !== 'string') {
        this.weekDaySelected.push(e.target.value);
      }
      console.log('weekend', this.weekDaySelected);
    }
    // for unchecking
    if (!e.target.checked) {
      console.log(e);
      let pos = this.weekDaySelected.indexOf(e.target.value);
      console.log(pos, "ind value");
      this.weekDaySelected.splice(pos, 1);
      console.log(this.weekDaySelected);
    }
    this.WeekDaysString = this.weekDaySelected.toString();
    console.log(this.WeekDaysString);
  }

  showWeekDaysDiv() {
    this.weekdaydiv = true;
  }
  // cancel task
  cancel(taskId) {
    this.accountNameR = taskId.accountName;
    this.FrequencyTypeR = taskId.scheduleType;
    this.datesR = taskId.repeatedDates;
    console.log('taskId', taskId)
    // if (confirm("Are you sure, you want to stop the recurring task?")) {
    //   this.cancelTask(taskId);
    // }
  }
  deleteTaskCom(taskId, userid) {
    var result = confirm("Want to delete?");
    if (result) {
      this.authService.deletetaskCom(taskId, userid).subscribe(
        res => {
          this.showTaskList()
          console.log(res)
        },
        err => console.log(err)
      )//Logic to delete the item
    }

  }
  cancelTask(taskId) {
    this.authService.cancelTask(taskId).subscribe((res: any) => {
      console.log('res', res);
      if (res.status == 'success') {
        this.showTaskList();
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error: any) => {
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
        // this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
      }
    )

  }
  // showImageDiv:boolean = false
  // imageUrl:string
  // shoImage(url){
  //   this.showImageDiv = true
  //   this.imageUrl = url
  //   jQuery.noConflict();
  //   $('#SolcareModal').modal('show');
  //   console.log('this function is called');

  // }


}




