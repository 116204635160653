import { Component, OnInit ,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { MustMatch } from '../helpers/matchPassword.validator';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
export interface DialogData {
}

declare var $:any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  submitted: boolean =false;
  changePassForm: FormGroup;
  changePassFormData: { userId: any; password: any; newPassword: any; };
  userId: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private fb:FormBuilder, private authService: AuthService,  public router:Router, private flashMessage: FlashMessagesService,public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ResetpasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.userId= localStorage.getItem("userId");
    this.changePassForm= this.fb.group({
      oldPass: ['', Validators.required],
      newPass: ['',[Validators.required, Validators.minLength(6),Validators.pattern("^[a-zA-Z0-9_]*$")]],
      confirmPass: ['', Validators.required]
      }, {
      validator: MustMatch('newPass', 'confirmPass')
    });
  }
  get f() { return this.changePassForm.controls; }
  /** function to change/reset password */
  changePassword(){
    this.submitted = true;
      // stop here if form is invalid
    if (this.changePassForm.invalid) {
        return;
    }
   
     // if form is valid
     console.log('password' , this.changePassForm.value);
     
    this.changePassFormData= {
      userId: this.userId,
      password: this.changePassForm.value.oldPass,
      newPassword: this.changePassForm.value.newPass 
    }
    this.authService.resetPassword(this.changePassFormData).subscribe((res:any) => {
      console.log(res);
      if (res.status=='success'){
        this.changePassForm.reset();
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        }); 
        this.onNoClick();
        this.router.navigate(['dashboard']);
      }
      else{
    
        this.snackBar.open(res.message,'', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        }); 
      }
    })
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
