import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { VendorGuard } from './guard/vendor.guard';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TasksComponent } from './tasks/tasks.component';
import { AssetsComponent } from './assets/assets.component';
import { StaffComponent } from './staff/staff.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CreateTaskComponent } from './create-task/create-task.component';
//import { EditTaskComponent } from './edit-task/edit-task.component';
import { NotificationComponent } from './notification/notification.component';
import { SignupComponent } from './signup/signup.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { QuickTaskComponent } from './quick-task/quick-task.component';
import { QuickTaskListComponent } from './quick-task-list/quick-task-list.component';
// angular-material-modules
import { DataTablesModule } from 'angular-datatables';
import {MatTableModule, MatFormFieldModule, MatInputModule,MatPaginatorModule, MatIconModule,
  MatSortModule, MatSelectModule, MatMenuModule,MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import { PartsComponent } from './parts/parts.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PushNotificationService } from 'ngx-push-notifications';
import { MatSnackBarModule } from '@angular/material';
import {RatingModule} from "ngx-rating";
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import {NgxPrintModule} from 'ngx-print'; 
import {MatTooltipModule} from '@angular/material/tooltip';
// import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { SearchByNamePipe } from './search-by-name.pipe';
import { DistributorDashboardComponent } from './distributor-dashboard/distributor-dashboard.component';
// import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';

import {MatListModule} from '@angular/material/list';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { FooterComponent } from './footer/footer.component';
import { PrintgraphComponent } from './printgraph/printgraph.component';
// import { DoughnutChartComponent, PieChartComponent, BarChartComponent } from 'angular-d3-charts'; // this is needed!
import { UserIdleModule } from 'angular-user-idle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatepipePipe } from './pipes/datepipe.pipe';
import { SortPipePipe } from './pipes/sort-pipe.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { CalendarComponent } from './calendar/calendar.component';
// calender event
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HalfdonoughtComponent } from './halfdonought/halfdonought.component';
import { MAT_DATE_LOCALE } from '@angular/material/core'

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};
  
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    TasksComponent,
    AssetsComponent,
    StaffComponent,
    VendorProfileComponent,
    NavbarComponent,
    DashboardComponent,
    PageNotFoundComponent,
    CreateTaskComponent,
   // EditTaskComponent,
    NotificationComponent,
    SignupComponent,
    QuickTaskComponent,
    QuickTaskListComponent,
    PartsComponent,
    ResetpasswordComponent,
    SearchByNamePipe,
    DistributorDashboardComponent,
    FooterComponent,
    PrintgraphComponent,
    DatepipePipe,
    SortPipePipe,
    SortPipe,
    CalendarComponent,
    HalfdonoughtComponent,
    // DoughnutChartComponent,
    // BarChartComponent
  ],
  imports: [
    NgbModalModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    FlashMessagesModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    // UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 120}),
    //DataTableModule.forRoot()
    DataTablesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatNativeDateModule,
    DateRangePickerModule,
    DatePickerModule,
    MatSnackBarModule ,
     RatingModule,
     NgxPrintModule,
     MatTooltipModule,
     MatListModule,
     NgcCookieConsentModule.forRoot(cookieConfig),
    //  MomentDateAdapter,
     
    // NgbModule    
     // <----- import for date formating(optional)
     CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports:[
    MatDatepickerModule,
    MatNativeDateModule
  ],
  entryComponents: [
    ResetpasswordComponent,
    PrintgraphComponent,
    CalendarComponent
  ],
  providers: [
    VendorGuard,
    PushNotificationService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
