import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages'; 
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  userId: string;
  notifyList: any;
  notifyListLength: number= 0;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private authService:AuthService, private flashMessage : FlashMessagesService,public snackBar: MatSnackBar) {
    this.userId= localStorage.getItem("userId");
   }

  ngOnInit() {
    // view notifications
    this.authService.viewNotifications(this.userId).subscribe(
      (res:any) => {
        console.log("notif response ", res);
        if(res.status=='success'){
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
          this.notifyList = res.data;
          this.notifyListLength= this.notifyList.length;
        }
        else{
          // this.snackBar.open(res.message,'', {
          //   duration: 3000,
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          // });
        }
      },
      (error:any) => {
        this.snackBar.open('Server Error !','', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    )
  }

}
