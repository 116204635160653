import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MytoasterService } from '../service/mytoaster.service';
declare var $: any;
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { FileserviceService } from '../service/fileservice.service';
@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],

})
export class PartsComponent implements OnInit {
  displayedColumns: string[] = ['accountName', 'machine', 'Code', 'lubricant', 'sump', 'loadingDate', 'nextLoadingDate', 'm-vectraOil', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  addMachineForm: FormGroup;
  uploadCsvMachine: FormGroup;
  @ViewChild('machineData') machineData: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('form') myForm;
  userId: any;
  partsDetail: {};
  partsList: any;
  partId: any;
  errorMessage: string = "Some error occured due to server or internet connection!";
  submitted: boolean = false;
  partDetail: {};
  customerData: any = [];
  obs: Observable<any>;
  assertId: any;
  customerMachineParts: any;
  machineParts: any;
  customertId: any;
  searchDetail: { userId: any; accountName: any; };
  customerloadingDate: any;
  editMachineForm: FormGroup;
  editPartDetail: {};
  editPartId: any;
  assetPartId: any;
  now: any = moment();
  machineUploadData: any;
  machineUploadDataLength: any;
  displayedRows$: Observable<any>;
  totalRows$: Observable<number>;
  customerTotalData: any;
  csvValue: {}[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  customer: any;
  vactorOil: any;
  machineDate: any;
  data: any = [{
    "Account_name": '', "Machine_model": '', "Machine_unique_code": '', "Lubricant_used": '', "Sump_size_ltr": '', "Machine_loading_date": '', "Next_loading_date": '', "M_vactra_oil_used": ''
  }];
  machineOildate: string;
  oildate: string;
  machinedate: string;
  nextOildate: string;
  date1: boolean;
  date2: boolean;

  constructor(private fb: FormBuilder, private authService: AuthService, private fileService: FileserviceService, private toaster: MytoasterService, private flashMessage: FlashMessagesService, public snackBar: MatSnackBar) {
    this.userId = localStorage.getItem("userId");
    console.log(this.userId);

  }


  ngOnInit() {
    // const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    // const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);

    // const rows$ = of(this.customerData);

    // this.totalRows$ = rows$.pipe(map(rows => rows.length));
    // this.displayedRows$ = rows$.pipe( paginateRows(pageEvents$));

    this.addMachineForm = this.fb.group({
      customers: ['', Validators.required],
      machineModel: ['', Validators.required],
      machineUniqueCode: ['', Validators.required],
      lubricant: [''],
      sumpSize: [''],
      machineLoadingDate: ['', Validators.required],
      nextLoadingDate: [''],
      mVactraOil: ['']
    });

    this.editMachineForm = this.fb.group({
      machineModel: ['', Validators.required],
      uniqueCode: ['', Validators.required],
      lubricantUsed: [''],
      sumpSize: [''],
      machineOilDate: ['', Validators.required],
      nextOilDate: [''],
      vectraOil: ['']

    });
    this.uploadCsvMachine = this.fb.group({
      machineCsvData: ['']
    });
    // this.dataSource = new MatTableDataSource();
    // this.showPartsList();
    this.assetList();


  }
  get f() { return this.addMachineForm.controls; }


// upload excel file
  onSubmit() {
    console.log(this.machineData.nativeElement.files[0]);
    if (!this.machineData.nativeElement.files[0]) {
      this.toaster.showToast('Failed!', "Please choose the excel file first!", 'danger');
      return;
    }
    const formData = new FormData();
    let userId = this.userId;

    console.log("userid is ", userId);
    formData.append('uploadfile', this.machineData.nativeElement.files[0], this.machineData.nativeElement.files[0].name);
    console.log("form data is ", this.machineData.nativeElement.files[0]);
    this.authService.uploadMachineList(formData, userId).subscribe(
      (response: any) => {
        console.log('UPLOADING assets response ', response);
        if (response.status == "success") {
          this.machineData.nativeElement.value = "";   //to reset the file name
          this.machineUploadData = response.data;
          this.machineUploadDataLength = this.machineUploadData.length;
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.assetList();
          if (this.machineUploadDataLength > 0) {
            jQuery.noConflict();
            $("#machineLogModal").modal('show');
          }

        }
        else {
          this.snackBar.open(response.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }

  // onSubmit(){
  //   alert('hi');
  //   console.log(this.addMachineForm.value)
  // }



  //  showParts(list){
  //   this.machineParts.setValue({
  //     machineName: list.assetName
  //   });
  //   this.partId= list.assetId;  //asset assetId
  //   this.showPartsList();
  // }
  /** function to show asset parts list */
  // showPartsList(){
  //   this.partsDetail={
  //     userId: this.userId,
  //     assetId: this.partId
  //   }
  //   console.log('partsDetail' ,  this.partsDetail)
  //   this.authService.viewParts(this.partsDetail).subscribe((res:any) => {
  //     console.log('response',res);
  //     if(res.status=='success'){
  //         //this.emptyList = false;
  //         this.partsList = res.data;
  //     }
  //     else{
  //       this.flashMessage.show(res.message, { cssClass: 'alert-danger', timeout: 2000 });
  //     }
  //   },
  //   (error) => {
  //     console.log('error is ',error.message);
  //     this.flashMessage.show(this.errorMessage, { cssClass: 'alert-danger', timeout: 3000 });
  //   })
  // }

  /** function to add part of machine */
  AddMachinePart() {
    console.log(this.addMachineForm.value)
    this.submitted = true;
    if (this.addMachineForm.invalid) {
      return;
    }
    this.partDetail = {
      // userId: this.userId,
      // assetId: this.partId,
      // name: this.addPartsForm.value.partName,
      // description: this.addPartsForm.value.partDescription
      userId: this.userId,
      assetId: this.addMachineForm.value.customers,
      machineModel: this.addMachineForm.value.machineModel,
      uniqueCode: this.addMachineForm.value.machineUniqueCode,
      lubricantUsed: this.addMachineForm.value.lubricant,
      sumpSize: this.addMachineForm.value.sumpSize,
      machineOilDate: moment(this.addMachineForm.value.machineLoadingDate).format('DD-MM-YYYY'),
      nextOilDate: moment(this.addMachineForm.value.nextLoadingDate).format('DD-MM-YYYY'),
      vectraOil: this.addMachineForm.value.mVactraOil
    }
    console.log(this.partDetail);
    this.authService.addMachPart(this.partDetail).subscribe((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.assetList();
        //   MaterialTextfield.prototype.checkValidity = function () {
        //     if (this.input_.validity.valid) {
        //         this.element_.classList.remove(this.CssClasses_.IS_INVALID);
        //     } else {
        //         if (this.element_.getElementsByTagName('input')[0].value.length > 0) {
        //               this.element_.classList.add(this.CssClasses_.IS_INVALID);
        //         }
        //     }
        // };
        // this.addMachineForm.reset();

        this.myForm.resetForm();


        this.submitted = false;
        //this.flashMessage.show(res.message, { cssClass: 'alert-success', timeout: 2000 });
        //jQuery.noConflict();
        // this.toaster.showToast('Success!', 'New Asset part added!!', 'success');
        this.snackBar.open('Machine Added Successfully !', '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

      }
      else {
        this.submitted = false
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        this.submitted = false
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }
// get asset list
  assetList() {
    console.log("asset list");
    this.authService.assetList(this.userId).subscribe((res: any) => {
      if (res && res.status == "success") {
        console.log("assets list response is ", res.data);
        this.customerData = res.data;
        this.customerTotalData = this.customerData
        console.log('customerDataaaa', this.customerData)
        for (let data of this.customerData) {
          for (let item of data.parts) {
            let oilDate = item.machineOilDate.toString();
            let nextLoadingdate = item.nextOilDate.toString();

            if (oilDate.indexOf("T") > -1) {
              let newOilDate = oilDate.split("T");
              item.machineOilDate = this.reformatDate(newOilDate[0]);
            }
            if (nextLoadingdate.indexOf("T") > -1) {
              let newNextOilDate = nextLoadingdate.split("T");
              item.nextOilDate = this.reformatDate(newNextOilDate[0]);
            }
          }
        }
      }
      else {
        // this.snackBar.open("Machine list is empty",'', {
        //   duration: 3000,
        //   horizontalPosition: this.horizontalPosition,
        //   verticalPosition: this.verticalPosition,
        // });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }
  // date format code
  reformatDate(dateStr) {
    let dArr = dateStr.split("-");
    // ex input "2010-01-18" 
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex out: "18/01/10"
  }
// delete machines
  deleteMachinePart(list: any, assetId) {
    console.log('ii', list, assetId);
    console.log(this.customerData);
    this.customertId = assetId;

    if (confirm("Are you sure you want to delete this part ? ")) {
      // for(let data of  this.customerData){
      //   console.log('data' , data.assetId)
      //   this.customertId= data.assetId;
      //   console.log('this...' , this.customertId)
      // }
      console.log("part list ", list);
      let partDetail = {
        userId: this.userId,
        assetId: this.customertId,
        partId: list.partId
      }

      console.log(partDetail);
      this.authService.deleteAssetsPart(partDetail).subscribe((res: any) => {
        console.log(res);
        if (res.status == 'success') {
          // this.toaster.showToast('Success!',res.message, 'success');
          this.assetList();
          this.snackBar.open(res.message, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
        else {
          // this.toaster.showToast('Failed!',res.message, 'danger');
          this.snackBar.open('res.message', '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
        (error) => {
          // console.log('error is ',error.message);
          // this.toaster.showToast('Failed!',this.errorMessage, 'danger');
          this.snackBar.open(this.errorMessage, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        })

    }
  }

  /** function to show asset parts list */
  showPartsList() {
    console.log('inside if', this.customerData)

    for (let data of this.customerData) {
      console.log('this.customer', data.assetId)
      this.assertId = data.assetId;
      this.partsDetail = {
        userId: parseInt(this.userId),
        assetId: this.assertId
      }
    }

    console.log('partsDetail', this.partsDetail)
    this.authService.viewParts(this.partsDetail).subscribe((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        //this.emptyList = false;
        this.partsList = res.data;
        console.log('response', this.partsList)
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
  }
  /** function to show name of machine in modal */
  showParts(list) {
    this.machineParts.setValue({
      machineName: list.assetName
    });
    this.partId = list.assetId;  //asset assetId
    this.showPartsList();
  }
  // applyFilter(filterValue: string) {
  //   // this.customerData.filter = filterValue.trim().toLowerCase();
  //   // if (this.customerData.paginator) {
  //   //   this.customerData.paginator.firstPage();
  //   // }
  //   if(this.customerData){
  //     let filteredData = this.customerData.filter((data => data.accountName == filterValue))[0];
  //     console.log(' filteredData ' ,filteredData   )
  //     if(filteredData) {
  //       this.customerTotalData = filteredData;
  //       console.log(' this.customerData ' ,this.customerTotalData) 
  //     }
  //   }
  //   if(filterValue == null){
  //     this.customerTotalData = this.customerData;
  //   }
  // }
  // search by account name
  search(term: any) {
    console.log(term);

    this.searchDetail = {
      userId: this.userId,
      accountName: term
    }
    console.log('search', this.searchDetail)
    this.authService.searchAsset(this.searchDetail).subscribe((res: any) => {
      if (res.status == "success") {
        this.customerData = res.data;
      }
    })
  }
// function to convert date
  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }
  // on edit set value to modal
  editMachinePart(list, assetId) {
    console.log('list1', list.machineOilDate);
    console.log('list2', list.nextOilDate);
    if (list.machineOilDate == '') {
      list.machineOilDate = '';
    } else {
      this.date1 = this.isIsoDate(list.machineOilDate);
      console.log(this.date1)
    }
    if (list.nextOilDate == '') {
      list.nextOilDate = '';
    } else {
      this.date2 = this.isIsoDate(list.nextOilDate);
      console.log(this.date2)
    }
    let str = list.machineOilDate;
    if (str == '') {
      this.machinedate = '';
    }
    else if(str == 'Invalid date'){
      this.machinedate = '';
    }
    else if (typeof (str) == 'number') {
      this.machinedate = '1-01-2021';
    }
    else {
      let date = str.split("-");
      let macDate = new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
      console.log('!!!', macDate)
      this.machinedate = macDate.toISOString();
    }

    let nextstr = list.nextOilDate;
    if(nextstr == ''){
      this.nextOildate = '';
    }
    else if(nextstr == 'Invalid date'){
      this.nextOildate = '';
    }
   else if (typeof (nextstr) == 'number') {
      this.nextOildate = '1-01-2021';
    }
    else {
      let nxtDate = nextstr.split("-");
      let nextDate = new Date(parseInt(nxtDate[2]), parseInt(nxtDate[1]) - 1, parseInt(nxtDate[0]));
      this.nextOildate = nextDate.toISOString();

      console.log('list', list, assetId)
    }




    this.editMachineForm.setValue({
      machineModel: list.machineModel,
      uniqueCode: list.uniqueCode,
      lubricantUsed: list.lubricantUsed,
      sumpSize: list.sumpSize,
      machineOilDate: this.date1 == true ? list.machineOilDate : this.machinedate,
      nextOilDate: this.date2 == true ? list.nextOilDate : this.nextOildate,
      vectraOil: list.vectraOil
    })
    console.log(this.editMachineForm);

    this.editPartId = list.partId;
    this.assetPartId = assetId;

  }
  // on edit this function is called
  editMachine() {
    console.log('list', this.editPartId, this.assetPartId)
    this.editPartDetail = {
      partId: this.editPartId,
      assetId: this.assetPartId,
      userId: this.userId,
      machineModel: this.editMachineForm.value.machineModel,
      uniqueCode: this.editMachineForm.value.uniqueCode,
      lubricantUsed: this.editMachineForm.value.lubricantUsed,
      sumpSize: this.editMachineForm.value.sumpSize,
      machineOilDate: moment(this.editMachineForm.value.machineOilDate).format('DD-MM-YYYY'),
      nextOilDate: moment(this.editMachineForm.value.nextOilDate).format('DD-MM-YYYY'),
      vectraOil: this.editMachineForm.value.vectraOil
    }
    this.authService.editMachine(this.editPartDetail).subscribe((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        console.log('response', res);
        this.assetList();
        // this.toaster.showToast('Success!', 'Machine edited successfully!!', 'success');
        this.snackBar.open('Machine edited successfully!!', '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        jQuery.noConflict();
        $("#editMachineModal").modal('hide');
      }
      else {
        this.snackBar.open(res.message, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    },
      (error) => {
        this.submitted = false
        console.log('error is ', error.message);
        this.snackBar.open(this.errorMessage, '', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })

  }

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: ["Account_name", "Machine_model", "Machine_unique_code", "Lubricant_used", "Sump_size_ltr", "Machine_loading_date", "Next_loading_date", "M_vactra_oil_used"]
  };

// download excel file
  downloadCSV() {
    this.fileService.exportAsExcelFile(this.data, 'Machines');
    // this.csvValue=[{ }]
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    // console.log('csvvvv' ,this.createdTaskList)
    // new AngularCsv(  this.csvValue, 'Machine Parts', this.csvOptions);

  }
  clickToDownload() {
    jQuery.noConflict();
    $("#machineDownloadModal").modal('show');

  }
}
