import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService{
  authToken:string=  localStorage.getItem("authToken");
  // base_url:string = 'https://mobil-admin.yugasa.org/mobil/';
  base_url: string = environment.api_base_url;
 
  // base_url:string = 'https://admin.mobil-solcare.com/mobil/';
 
 
  constructor(private httpClient: HttpClient) {}
   
  login(data:any): Observable<boolean>{
    return this.httpClient.post<any>(this.base_url+'userLogin', data)
    .pipe(
          tap(res => this.doLoginUser(res.authToken))
    );
  }
  /** to set token and store in local variable for first use */
  private doLoginUser(token:any){
    localStorage.setItem("authToken",token);
    this.authToken=token;
  }

  register(data:any){
    return this.httpClient.post(this.base_url+'userSignUp', data);
  }

  getDashboardData(userId:any, type:any,data:any){
    if(type == 'byDate'){
      return this.httpClient.post(this.base_url+'vendorDashboardData', data, {
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    else{
      return this.httpClient.post(this.base_url+'vendorDashboardData', {userId:userId}, {
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    
   
  }
  getDashboardCustomerData(userId:any, type:any,data:any ){
    if(type == 'byDate'){
      return this.httpClient.post(this.base_url+'customerDashboardData', data, {
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    else{
      return this.httpClient.post(this.base_url+'customerDashboardData', {userId:userId}, {
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    
  }
  getDashboardServiceData(userId:any){
    return this.httpClient.post(this.base_url+'engineerDashboardData', {userId:userId}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  dashboardServiceFilterDate(data){
    return this.httpClient.post(this.base_url+'engineerDashboardData', data, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  dashboardDropDownData(){
    return this.httpClient.get(this.base_url+'customerAllAccounts', {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  dashboardSplineChartData(assetId:any,uniqueCode:any, data :any ,type:any ,userId:any){
    
    if(type ==="byAccount"){
      return this.httpClient.post(this.base_url+'customerTrends',{assetId:assetId ,type:type,from:data.from,to:data.to} ,{
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    if(type === 'byMachine'){
      return this.httpClient.post(this.base_url+'customerTrends',{
        assetId:assetId ,
        from:data.from,
        to:data.to, 
        uniqueCode:uniqueCode,
        type:type} ,{
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
     
    }
    if(type === 'accountDate'){
      return this.httpClient.post(this.base_url+'customerTrends',{assetId:assetId ,type:type,from:data.from,to:data.to} ,{
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    if(type === 'machineDate'){
      return this.httpClient.post(this.base_url+'customerTrends',data ,{
        headers: {
          'Authorization': 'Bearer '+ this.authToken 
        }
      });
    }
    if(type === 'csvNoDate')
    return this.httpClient.post(this.base_url+'customerTrends',{assetId:assetId , uniqueCode:uniqueCode, userId:userId,type:type} ,{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
      }
    });
    if(type === 'csvByDate')
    return this.httpClient.post(this.base_url+'customerTrends',{
      assetId:assetId ,
      from:data.from,
      to:data.to, 
      uniqueCode:uniqueCode,
      type:type,
      userId:userId
    } ,{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
      }
    });
  }
  // dashboardSelectedByMachineData(assetId:any,uniqueCode:any, type:any){
  //   return this.httpClient.post(this.base_url+'customerTrends',{assetId:assetId , uniqueCode:uniqueCode,type:type} ,{
  //     headers: {
  //       'Authorization': 'Bearer '+ this.authToken 
	// 		}
  //   });
  // }
  // dashboardAccountDataByDate(data){
  //   return this.httpClient.post(this.base_url+'customerTrends',data ,{
  //     headers: {
  //       'Authorization': 'Bearer '+ this.authToken 
	// 		}
  //   });
  // }
  // dashboardMachineDataByDate(data){
  //   return this.httpClient.post(this.base_url+'customerTrends',data ,{
  //     headers: {
  //       'Authorization': 'Bearer '+ this.authToken 
	// 		}
  //   });
  // }
  filterbyAccount(assetId:any ,type:any,taskType:any){
    return this.httpClient.post(this.base_url+'taskByAccountAndStaff',{assetId:assetId,type:type,taskType:taskType} ,{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  filterbyStaff(staffId:any, type:any,taskType:any){
    return this.httpClient.post(this.base_url+'taskByAccountAndStaff',{staffId:staffId,type:type,taskType:taskType} ,{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  
  


  addAsset(assetData){
    return this.httpClient.post(this.base_url+'/vendorAddAssets', assetData, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  
  assetList(userId){
    return this.httpClient.post(this.base_url+'/vendorAssetList', {userId:userId}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  addEmployee(employeeInfo){
    return this.httpClient.post(this.base_url+'/addStaffMember', employeeInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  listStaff(userId){
    return this.httpClient.post(this.base_url+'/staffMemberList', {userId:userId}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  resetPassword(passInfo){
    return this.httpClient.post(this.base_url+'userResetPassword', passInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
 

  forgetPassword(email){
    return this.httpClient.post(this.base_url+'/forgotPassword',{email:email});
  }

  assetEdit(editData){
    return this.httpClient.post(this.base_url+'/editAssestofVendor', editData, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  assetDelete(assetInfo){
    return this.httpClient.post(this.base_url+'/deleteAssetofVendor', assetInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  employeeDetailsEdit(editData:any){
    return this.httpClient.post(this.base_url+'/updateEmployeeDetails', editData, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  employeeDelete(employeeInfo:any){
    return this.httpClient.post(this.base_url+'/deleteStaffofVendor', employeeInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  autoSearchEmployee(userId:any,name:any){
    return this.httpClient.post(this.base_url+'/vendorSearchStaffMember', {userId:userId, name:name}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  createTask(taskDetail:any){
    return this.httpClient.post(this.base_url+'/assignTaskToStaff', taskDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  createQuickTask(taskDetail:any){
    return this.httpClient.post(this.base_url+'/VendorAssignQuickTask',taskDetail,{
      headers : {
        'Authorization' : 'Bearer '+ this.authToken
      }
    });
  }

  updateVendorProfile(profileData:any){
    return this.httpClient.post(this.base_url+'/userUpdateProfile', profileData, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  taskList(userId:any){
    return this.httpClient.post(this.base_url+'/vendorTaskList', {userId:userId}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
     }
    );
  }

  quickTaskList(data:any){
    return this.httpClient.post(this.base_url + '/quickTaskList', data, {
      headers : {
        'Authorization' : 'Bearer '+ this.authToken
      }
    });
  }
  dateFilterForTask(filterDetails:any){
    return this.httpClient.post(this.base_url+'/dateBaseTask', filterDetails, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  dateFilterForQuickTask(filterDetails :any){
    return this.httpClient.post(this.base_url + '/dateBaseTask', filterDetails, {
      headers : {
        'Authorization' : 'Bearer '+ this.authToken
      }
    });
  }

  filterTaskStatus(filterDetails:any){
    return this.httpClient.post(this.base_url+'/vendorTaskFilter', filterDetails, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  searchTask(taskInfo:any){
    return this.httpClient.post(this.base_url+'/taskSearchOfVendor', taskInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  // search quick task list 
  searchQuickTask(taskInfo:any){
    return this.httpClient.post(this.base_url+'/quickTaskSearch', taskInfo, {
      headers : {
        'Authorization' : 'Bearer '+ this.authToken
      }
    })
  }

  addMachPart(partDetail:any){
    return this.httpClient.post(this.base_url+'/addAssetParts', partDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  viewParts(machineDetail:any){
    return this.httpClient.post(this.base_url+'/showPartsOfVendor', machineDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  deleteAssetsPart(partDetail:any){
    return this.httpClient.post(this.base_url+'/deleteAssetParts', partDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  taskListOfEmployee(empDetail:any){
    return this.httpClient.post(this.base_url+'/taskOfPerticularEmployee', empDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  uploadTaskList(uploadfile:any,userId:any) {
    let user= parseInt(userId);
    return this.httpClient.post(this.base_url + '/readCsvFileTask/'+user,uploadfile, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  remindStaff(Id){
    const taskId = { taskId : Id}
    return this.httpClient.post(this.base_url + '/sendNotificationToStaff', taskId, {
      headers :{
        'Authorization': 'Bearer '+ this.authToken 
      }
    })
  }
  uploadQuickTaskCsv(uploadfile:any, userId:any){
    let user = parseInt(userId);
    return this.httpClient.post(this.base_url + '/readCsvFileQuickTasks/' + user,uploadfile,{
      headers : {
        'Authorization' : 'Bearer '+this.authToken
      }
    })
  }


  particularTaskComments(taskDetails:any){
    return this.httpClient.post(this.base_url + '/perticularTaskDetail',taskDetails, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  addComment(taskDetail:any){
    return this.httpClient.post(this.base_url + '/vendorAddComment',taskDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  uploadAssetList(uploadfile:any,userId:any) {
    let user= parseInt(userId);
    return this.httpClient.post(this.base_url + '/readCsvFileAddAssets/'+user,uploadfile, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  uploadMachineList(uploadfile:any,userId:any){
    let user= parseInt(userId);
    return this.httpClient.post(this.base_url + '/readCsvFileAddCustomers/'+user,uploadfile, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  uploadStaffList(uploadfile:any,userId:any) {
    let user= parseInt(userId);
    return this.httpClient.post(this.base_url + '/readCsvFileAddStaff/'+user,uploadfile, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  editTask(taskDetail:any){
    return this.httpClient.post(this.base_url + '/taskEditOfEmployee',taskDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  viewNotifications(userId:any) {
    return this.httpClient.post(this.base_url + '/vendorNotifyList',{userId:userId}, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  approvedOrRejectTask(detail:any){
    return this.httpClient.post(this.base_url + '/taskRejectOrApprove',detail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  searchAsset(taskInfo:any){
    return this.httpClient.post(this.base_url+'/vendorSearchAssets', taskInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  searchStaff(taskInfo:any){
    return this.httpClient.post(this.base_url+'/vendorSearchStaffMember', taskInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  setReminder(reminderInfo:any){
    return this.httpClient.post(this.base_url+'/setReminderTime', reminderInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }

  setPassword(passwordInfo:any){
    return this.httpClient.post(this.base_url+'/setStaffMemberPassword', passwordInfo, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  feedback(data){
    return this.httpClient.post(this.base_url+'/userFeedback', data, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  editMachine(editDetail:any){
    return this.httpClient.post(this.base_url+'/editAssetParts', editDetail, {
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  cancelTask(taskId:any){
    return this.httpClient.post(this.base_url+'staffCancelled',{taskId:taskId},{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  deletetaskCom(taskId:any,userid:any){
    return this.httpClient.post(this.base_url+'deleteTask',{taskId:taskId,userId:userid},{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  getCount(userId:any){
    return this.httpClient.post(this.base_url+'userGraph',{userId:userId} ,{
      headers: {
        'Authorization': 'Bearer '+ this.authToken 
			}
    });
  }
  // generatepdf(taskId:any){
  //   let url='https://mobil-admin.yugasa.org/'
  //   return this.httpClient.post(url+'uploads/report'+taskId+'.pdf',  {
  //     headers: {
  //       'Authorization': 'Bearer '+ this.authToken 
	// 		}
  //   });
  // }
  getDistributordashboard(){
    // return this.httpClient.post(this.base_url+'/editAssetParts', editDetail, {
    //   headers: {
    //     'Authorization': 'Bearer '+ this.authToken 
		// 	}
    // });
  }
}

