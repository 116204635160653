import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "arrayFilter"
})
export class SearchByNamePipe implements PipeTransform {

  transform(value:any[],searchString:string): any {
   
    if(!searchString){
      console.log('no search')
      return value; 
    }

    return value.filter(it=>{   
        const accountName = it.accountName.toLowerCase().includes(searchString.toLowerCase());
        console.log(accountName);
        
        return (accountName);      
    }) 
 }
  }


